import { MoonLoader, PulseLoader } from "react-spinners";

export const Loading01 = () => {
  return (
    <div>
      <MoonLoader color="#36d7b7" size={100} />
    </div>
  );
};

export const Loading02 = () => {
  return (
    <div>
      <PulseLoader color="#36d7b7" />
    </div>
  );
};

export const Loading03 = () => {
  return (
    <div>
      <MoonLoader color="#36d7b7" size={200} />
    </div>
  );
};
