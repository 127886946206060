const formatPhoneNumber = (number) => {
  // 010으로 시작하는 경우 10으로 변경
  if (number.startsWith("010")) {
    number = "10" + number.slice(3);
  }

  // 정규식을 사용하여 문자열을 파싱하고 원하는 형식으로 변환
  const formattedNumber = number.replace(
    /(\d{2})(\d{4})(\d{4})/,
    "+82 $1-$2-$3"
  );

  return formattedNumber;
};

export default formatPhoneNumber;
