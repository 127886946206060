import { SET_SIDEBAR_INDEX, STORE_RESET } from "../action/SidbarIndexAction";

const initialState = {
  sidebarIndex: 0,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_INDEX:
      return {
        ...state,
        sidebarIndex: action.payload,
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default sidebarReducer;
