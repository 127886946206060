import React, { useEffect, useRef, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { API_KEY } from "../../util/const";
import { PayPalCreateRepo } from "./repo/PayPalRepo";
import { API_URL } from "../../Repository/URL";

import Swal from "sweetalert2";

export const PayPalPage = ({ money, memo, name, token }) => {
  const moneyRef = useRef(money);
  const memoRef = useRef(memo);
  const nameRef = useRef(name);
  const tokenRef = useRef(token);
  const paykey = process.env.REACT_APP_PAYPAL_CLIENT_ID;

  return (
    <PayPalScriptProvider options={{ "client-id": paykey, locale: "en_US" }}>
      <PayPalButtons
        style={{
          layout: "horizontal",
          size: "large",
          color: "blue",
          shape: "rect",
          label: "checkout",
          tagline: false,
        }}
        createOrder={async (data, actions) => {
          const resp = await PayPalCreateRepo({
            money: moneyRef.current,
            memo: memoRef.current,
            name: nameRef.current,
            token: tokenRef.current,
          });

          if (resp.msg === "Success") {
            return resp.data.data.id;
          }
        }}
        onApprove={async (data, actions) => {
          try {
            const response = await fetch(
              `${API_URL}/paypal/orders/${data.orderID}/capture`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${tokenRef.current}`,
                  "API-Key": API_KEY,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({}),
              }
            );
            const orderData = await response.json();
            const errorDetail = orderData?.details?.[0];
            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
              /// 여기는 회복가능한 처리 다시 한번도 리스타트 시도를 한다.
              return actions.restart();
            } else if (errorDetail) {
              // 네트워크 에러 등등 회복 불가능한 처리
              Swal.fire({
                title: "An unknown error has occurred. Please try again later",
                icon: `error`,
              });
              return;
            } else if (!orderData.purchase_units) {
              /// 유효하지 않은 orderData가 들어왔을때 처리
              Swal.fire({
                title: "Invalid or incorrect access.",
                icon: `error`,
              });
              return;
            } else {
              /// 성공적으로 통신

              Swal.fire({
                title: "Thank you. Your donation was successfully completed.",
                icon: `success`,
              });
            }
          } catch (error) {
            Swal.fire({
              title: "An unknown error has occurred. Please try again later",
              icon: `error`,
            });
          }
        }}
        onError={(err) => {
          //    페이팔 SDK에서 자체에서 발생할 수 있는 오류 처리
          // 오류 처리 로직

          if (moneyRef.current < 1) {
            Swal.fire({
              title: "Please enter an amount greater than 1 dollar.",
              icon: "error",
            });
            return;
          } else {
            Swal.fire({
              title: "An unknown error has occurred. Please try again later",
              icon: `error`,
            });
          }

          return;
        }}
      />
    </PayPalScriptProvider>
  );
};

const YourComponent = () => {
  const [showPayment, setShowPayment] = useState(false);

  const handleClick = () => {
    setShowPayment(true);
  };

  return <PayPalPage />;
};

export default YourComponent;
