import styled from "styled-components";

export const ModifyTableDivStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ModifyTableKaKaoDivStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ModifyTableImgDivStyled = styled.div`
  height: 48px;
  padding: 0 14px;
  min-width: 84px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9e000;
  width: 60%;
`;
