import styled from "styled-components";

export const FlexDivText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  margin-top: 10px;
`;

export const TextTitleStyled = styled.div`
  font-size: 15px;
  font-weight: 800;
`;
