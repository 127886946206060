import React, { useEffect, useState } from "react";
import "../../../../css/common.css";
import "../../../../css/content.css";
import "../../../../css/layout.css";
import "../../../../css/style.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { MyPageMoneyTable, MyPageTable } from "../components/MyPageTable";
import { ReMyPagination } from "../components/Pagination";
import { AiFillAlert, AiFillNotification } from "react-icons/ai";
import ReFooter from "../../../../common/Footer/screen/ReFooter";
import { MdOutlineLiveHelp } from "react-icons/md";
import { TodayHitStyled, TodayReportStyled } from "../css/ReMyPageStyld";
import { useDispatch, useSelector } from "react-redux";
import { ReSidBar } from "../../../ReSidBar/ReSidBar";
import {
  resetStore,
  setSideBar,
} from "../../../../common/redux/action/SidbarIndexAction";
import { BsPersonAdd } from "react-icons/bs";
import SelectMonth from "../components/SelectMonth";
import SearchCard from "../components/Search";
import {
  getCurrentDate,
  getFormattedCurrentDate,
  getLastMonthDate,
  parseDateString,
} from "../../../../util/DateCalculation";
import { getPriceStatistics } from "../repo/PriceStatistics";
import { MonthsTableLoading } from "../components/MonthsTableLoading";
import {
  getEstimatedAmount,
  getEstimatedAmount2,
} from "../repo/EstimatedAmount";

import { formatNumber, tokenName } from "../../../../util/const";
import { getStateDonator } from "../repo/StateDonator";
import { getDashBoard } from "../repo/DashBoardGet";
import { Loading01, Loading02 } from "../../../../util/spinner";
import { setDashBoard } from "../../../../common/redux/action/DashBoardAction";

import { setPagination } from "../../../../common/redux/action/PaginationAction";
import { API_URL } from "../../../../Repository/URL";
import ReKaKaoButton from "../../../../common/Header/components/reKakaoButton";
import { logOutText } from "../text/ReMyPageText";

import { useTranslation } from "react-i18next";
import { removeCookie } from "../../../../common/Cookies/user_cookies";
import { EmailModal } from "../../../DonationPage/components/EmailModal";
import EmailButton from "../../../../common/Header/components/EmailButton";
import ParentComponent from "../../../../common/Header/components/Parent";

const ReMyPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const languageData = useSelector((state) => state.languageType.type);
  const url = API_URL;

  /// 시작날짜
  const startDate = useSelector((state) => state.lastDay.data[1]);
  /// 종료날짜
  const endDate = useSelector((state) => state.today.data[1]);
  /// 검색어
  const textSearch = useSelector((state) => state.searchText.text);
  /// 서치타입
  const searchType = useSelector((state) => state.searchSelectedValue.index);
  /// 페이지정보
  const pageData = useSelector((state) => state.paginationState.current);
  /// 유저정보
  const useData = useSelector((state) => state.profileState.profile);
  const profileImg = useSelector((state) => state.userprofileImg?.profileImage);
  const [showModal, setShowModal] = useState(false);
  const [searchSignal, setSearchSignal] = useState(false);

  const handleSearchSignal = () => {
    setSearchSignal((prevSignal) => !prevSignal);
  };
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const handleSelectChange = async (value) => {
    let parmasMonth;
    if (selectedYear === "") {
      const year = new Date().getFullYear();

      parmasMonth = `${year}${t("yearText")} ${value}`;
    } else {
      parmasMonth = `${selectedYear} ${value}`;
    }

    const estimatedResult = await getEstimatedAmount2(parmasMonth);
    setSettlementAmount(estimatedResult.settlementMoney);
    setSelectedMonth(value);
  };

  const handleYearSelectChange = async (value) => {
    let parmasYear;
    if (selectedMonth === "") {
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");

      parmasYear = `${value} ${currentMonth}${t("monthText")}`;
    } else {
      parmasYear = `${value} ${selectedMonth}`;
    }

    const estimatedResult = await getEstimatedAmount2(parmasYear);
    setSettlementAmount(estimatedResult.settlementMoney);
    setSelectedYear(value);
  };

  const [isStartDate, setStartDate] = useState(null);
  const [isEndDate, setEndDate] = useState(null);

  const [isLoadingPrices, setLoadingPrices] = useState(false);

  const [imgError, setImgError] = useState(false);

  const handleImgError = () => {
    setImgError(true);
  };

  /// 최근 3개월 내역
  const [isPriceData, setPriceData] = useState(null);

  /// 정산 예정금액
  const [isEstimatedAmount, setEstimatedAmount] = useState(null);
  const [isSelectYear, setSelectYear] = useState(null);
  const [isSelectMonty, setSelectMonty] = useState(null);
  const [isSettlementAmount, setSettlementAmount] = useState("");
  const [isSponsor, setSponsor] = useState([]);
  const [isTotalMoney, setTotalMoney] = useState("");
  const [isTotalSettlementAmount, setTotalSettlementAmount] = useState("");
  const [isSearchTotalMoney, setSearchTotalMoney] = useState("");

  /// 후원리스트 관련 상태
  const [isSponList, setSponList] = useState([]);

  const toggleModal = () => setShowModal(!showModal);
  const handleLinkPage = async (index) => {
    dispatch(setSideBar(index));
  };

  const goKakao = () => {
    const url = " http://pf.kakao.com/_QeXXG/chat";

    window.open(url, "팝업 이름", "width=530,height=718,left=00,top=100");
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setSideBar(0));

      const formattedOneMontyDay = getFormattedCurrentDate(startDate);
      const formattedDay = getFormattedCurrentDate(endDate);
      const dashBoardData = await getDashBoard(
        pageData,
        null,
        searchType,
        textSearch,
        formattedOneMontyDay,
        formattedDay
      );

      dispatch(
        setPagination(
          dashBoardData?.data?.maxPage,
          dashBoardData?.data?.currentPage
        )
      );

      const modifiedSponList = dashBoardData?.data?.spon.map((item) => {
        const [date, time] = item.date.split("T");
        const [hours, minutes] = time.split(":");
        return {
          ...item,
          date: `${date} ${hours}:${minutes}`,
        };
      });

      setSearchTotalMoney(dashBoardData?.data?.money);
      setSponList(modifiedSponList);
      const tableStartDate = parseDateString(formattedOneMontyDay);
      const tableEndDate = parseDateString(formattedDay);

      setEndDate(tableEndDate);
      setStartDate(tableStartDate);
      dispatch(
        setDashBoard({
          start_date: formattedOneMontyDay,
          end_date: formattedDay,
        })
      );
    };

    fetchData();
  }, [searchSignal, startDate, endDate, searchType, textSearch, pageData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const priceResult = await getPriceStatistics();

        const estimatedResult = await getEstimatedAmount("");
        const sponsorship = await getStateDonator();

        const yearMonth = estimatedResult.month;
        const settlementAmount = estimatedResult.settlementMoney;
        setSponsor(sponsorship.data);
        setSettlementAmount(settlementAmount);

        const years = [...new Set(yearMonth.map((item) => item.split(" ")[0]))];
        const months = yearMonth.map((item) => item.split(" ")[1]);

        const currentDay = getCurrentDate();
        setEndDate(currentDay);
        const formattedDay = getFormattedCurrentDate(currentDay);
        const oneMontyDay = getLastMonthDate();
        setStartDate(oneMontyDay);
        const formattedOneMontyDay = getFormattedCurrentDate(oneMontyDay);

        const dashBoardData = await getDashBoard(
          1,
          null,
          null,
          null,
          formattedOneMontyDay,
          formattedDay
        );

        dispatch(
          setPagination(
            dashBoardData?.data?.maxPage,
            dashBoardData?.data?.currentPage
          )
        );

        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        // setSelectedYear(`${currentYear}${t("yearText")}`);
        // setSelectedMonth(`${currentMonth}${t("monthText")}`);

        setSelectedYear(`${currentYear}${t(".")}`);
        setSelectedMonth(`${currentMonth}${t(".")}`);

        setTotalSettlementAmount(priceResult?.data?.monthMoney);
        setTotalMoney(priceResult?.data?.todayMoney);
        setSearchTotalMoney(dashBoardData.data.money);

        const modifiedSponList = dashBoardData?.data?.spon.map((item) => {
          const [date, time] = item.date.split("T");
          const [hours, minutes] = time.split(":");
          return {
            ...item,
            date: `${date} ${hours}:${minutes}`,
          };
        });

        setSponList(modifiedSponList);
        dispatch(
          setDashBoard({
            start_date: formattedOneMontyDay,
            end_date: formattedDay,
          })
        );

        const cleanedYears = years.map((year) => year.replace("년", " Year"));
        const cleanedMonths = months.map((year) =>
          year.replace("월", " Month")
        );
        if (languageData !== "ko") {
          setSelectYear(cleanedYears);
          setSelectMonty(cleanedMonths);
        } else {
          setSelectYear(years);
          setSelectMonty(months);
        }

        setEstimatedAmount(estimatedResult);
        setPriceData(priceResult.data.stats);
        setLoadingPrices(true);
      } catch (error) {
        console.log("데시보드 에러?", error);
        // navigate("/");
      }
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    dispatch(resetStore());
    removeCookie(tokenName);
    navigate("/renewalLogin");
  };

  const [isToOpen, setToOpen] = useState(false);

  const handleEmailInfo = () => {
    setToOpen(true);
  };

  const toggleToModal = () => {
    setToOpen(!isToOpen);
  };

  return (
    <div className="layout-type-main">
      <div className="wrapper nav-mode-1">
        <div id="container">
          <ReSidBar />
          <article id="content">
            <div
              className="content-header"
              data-inc="_inc-content-header.html"
              data-page-title="대시보드"
            >
              <a
                href=""
                className="btn-tile btn-back"
                onClick="history.back(); return false;"
              >
                <i className="ico ico-back"></i>
                <span className="sr-only">{t("backWard")}</span>
              </a>
              <div className="tile">
                <div className="location">
                  <button type="button" className="btn btn-icon btn-copy">
                    <i className="ico ico-copy-gray-sm"></i>
                    <span className="sr-only">{t("copyText")}</span>
                  </button>
                  <ol className="breadcrumb-list">
                    <li>{t("dashBoardText")}</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="content-body">
              <div className="dashboard">
                <section className="section section-guide">
                  <ul className="quick-link">
                    <li>
                      <Link
                        to="/ReModifyPage"
                        onClick={() => handleLinkPage(1)}
                      >
                        <div
                          className="icon"
                          style={{ backgroundColor: "#F5A207" }}
                        >
                          <img src="/IconIY.svg" alt="" />
                        </div>
                        <div className="cont">
                          <strong className="title">
                            {t("profileSettingText")}
                          </strong>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {t("profileSettingDescriptionText")}
                          </p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/reCreateButton"
                        onClick={() => handleLinkPage(2)}
                      >
                        <div
                          className="icon"
                          style={{ backgroundColor: "#ECE0D0" }}
                        >
                          <img src="/IconR.svg" alt="" />
                        </div>
                        <div className="cont">
                          <strong className="title">
                            {t("buttonCreateText")}
                          </strong>
                          <p>{t("buttonCreateDescriptionText")}</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/CalculatePage"
                        onClick={() => handleLinkPage(3)}
                      >
                        <div
                          className="icon"
                          style={{ backgroundColor: "#F5A207" }}
                        >
                          <img src="/IconY.svg" alt="" />
                        </div>
                        <div className="cont">
                          <strong
                            className="title"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {t("settlementRegistrationText")}
                          </strong>
                          <p>{t("settlementRegistrationDescriptionText")}</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      {languageData === "en" ? (
                        <Link to="https://splendid-tuberose-a37.notion.site/HANZAN-Guideline-989ae6831bf7416688d4375c78e7bf1d">
                          <div
                            className="icon"
                            style={{ backgroundColor: "#ECE0D0" }}
                          >
                            <img src="/Icon.svg" alt="" />
                          </div>
                          <div className="cont">
                            <strong className="title">{t("guideText")}</strong>
                            <p>{t("guideTextDescriptionText")}</p>
                          </div>
                        </Link>
                      ) : (
                        <Link to="https://onemorelatte.oopy.io/">
                          <div
                            className="icon"
                            style={{ backgroundColor: "#ECE0D0" }}
                          >
                            <img src="/Icon.svg" alt="" />
                          </div>
                          <div className="cont">
                            <strong className="title">{t("guideText")}</strong>
                            <p>{t("guideTextDescriptionText")}</p>
                          </div>
                        </Link>
                      )}
                    </li>
                  </ul>
                </section>

                <div className="section section-myinfo">
                  <h3 className="sr-only">{t("myInfoText")}</h3>
                  <div className="info-item myinfo">
                    <div
                      className="profile"
                      style={{
                        height: "8rem",
                        width: "8rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    >
                      {useData?.profileImgPath && !imgError ? (
                        <img
                          src={useData?.profileImgPath}
                          alt="프로필이미지"
                          onError={handleImgError}
                        />
                      ) : (
                        <BsPersonAdd
                          style={{ height: `6rem`, width: `6rem` }}
                        />
                      )}
                    </div>

                    <dl className="info">
                      <dt className="name">{useData.name}</dt>
                      <dd className="role">
                        <span className="">{useData.email}</span>
                      </dd>
                    </dl>
                    <div className="btn-group">
                      <Link
                        to={"/ReModifyPage"}
                        data-modal="open"
                        className="btn btn-sm btn-primary-soft"
                        onClick={() => handleLinkPage(1)}
                      >
                        {t("correctionOfInfoText")}
                      </Link>
                      <button
                        onClick={() => handleLogout()}
                        className="btn btn-sm btn-gray"
                      >
                        {t("logOutText")}
                      </button>
                    </div>
                  </div>
                  <div className="info-item lastest-visit">
                    <h4 className="item-title">{t("recentSponsorText")}</h4>
                    <div className="item-body">
                      <ul className="visit-list">
                        {isSponsor.map((sponsor, index) => (
                          <li key={index}>
                            <span className="visit-menu">
                              {sponsor.name || `${t("sponsorNameText")}`}
                            </span>
                            <p>
                              {formatNumber(sponsor.money)} {t("oneSponText")}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className=" privacy-history"></div>

                  <div className="info-item connect-info">
                    <h4 className="item-title">
                      <div
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        <MdOutlineLiveHelp />
                      </div>
                      {t("toInquireText")}
                    </h4>
                    <div className="item-body">
                      {languageData === "en" ? (
                        <div
                          style={{ width: `100%` }}
                          onClick={handleEmailInfo}
                        >
                          <EmailButton />
                        </div>
                      ) : (
                        <ReKaKaoButton />
                      )}
                    </div>
                  </div>
                </div>
                <ParentComponent
                  isToOpen={isToOpen}
                  toggleToModal={toggleToModal}
                />
                <div>
                  <div
                    style={{
                      display: `flex`,
                      alignItems: `center`,
                      minHeight: `5rem`,
                      marginBottom: `20px`,
                    }}
                  >
                    <div className="card" style={{ width: `100%` }}>
                      <h4 className="card-title">
                        <strong>{t("sponsorAmountStatisticeText")}</strong>
                      </h4>
                      <div className="card-body">
                        {isLoadingPrices ? (
                          <MyPageMoneyTable data={isPriceData} />
                        ) : (
                          <MonthsTableLoading />
                        )}
                      </div>
                    </div>
                    <div
                      style={{ marginRight: `10px`, marginLeft: `10px` }}
                    ></div>
                    <div
                      className="card"
                      style={{ width: `100%`, height: `100%` }}
                    >
                      <div
                        style={{
                          display: `flex`,
                          justifyContent: `space-between`,
                          width: `102%`,
                          alignItems: `center`,
                          marginBottom: `16px`,
                        }}
                      >
                        <div>
                          <h4 className="card-title">
                            <strong>{t("amountOfSettlementText")}</strong>
                          </h4>
                        </div>

                        <div>
                          <div
                            style={{
                              display: `flex`,
                              width: `100%`,
                              alignItems: `center`,
                            }}
                          >
                            {isSelectYear === null ? (
                              // <Loading02 />
                              <></>
                            ) : (
                              <SelectMonth
                                data={isSelectYear}
                                onSelectChange={handleYearSelectChange}
                              />
                            )}

                            <div style={{ marginLeft: `10px` }}></div>
                            {isSelectMonty === null ? (
                              <Loading02 />
                            ) : (
                              <SelectMonth
                                data={isSelectMonty}
                                onSelectChange={handleSelectChange}
                              />
                            )}
                            <div style={{ marginLeft: `10px` }}></div>
                          </div>
                        </div>
                      </div>
                      {/* <ScheduleLoading /> */}
                      <div
                        style={{
                          display: `flex`,
                          justifyContent: `space-between`,
                        }}
                      >
                        <strong style={{ fontSize: "30px" }}>
                          {selectedYear} {selectedMonth}
                        </strong>
                        <MyModal />
                      </div>

                      <div className=" today-report" style={{}}>
                        <TodayReportStyled>
                          <div style={{ fontWeight: `800` }}>
                            {t("amountOfSettlementText")}
                          </div>
                          <div>
                            <div className="count">
                              {formatNumber(isSettlementAmount)}
                            </div>
                          </div>
                        </TodayReportStyled>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="card monthly-device">
                    <div
                      style={{
                        display: `flex`,
                        justifyContent: `space-between`,
                        alignItems: `center`,
                      }}
                    >
                      <h4 className="card-title">
                        <strong>{t("sponListText")}</strong>
                      </h4>
                      <div></div>

                      <div
                        style={{
                          display: `flex`,
                          alignItems: `center`,
                        }}
                      >
                        <strong
                          style={{
                            fontSize: `20px`,
                            marginRight: `10px`,
                          }}
                        >
                          {isStartDate === null ? (
                            <Loading02 />
                          ) : (
                            <span>
                              {isStartDate?.year}.{isStartDate?.month}.
                              {isStartDate?.day}
                            </span>
                          )}
                        </strong>
                        <div
                          style={{
                            fontSize: `20px`,
                          }}
                        >
                          -
                        </div>
                        <strong
                          style={{
                            fontSize: `20px`,
                            marginLeft: `10px`,
                          }}
                        >
                          {isEndDate === null ? (
                            <Loading02 />
                          ) : (
                            <span>
                              {isEndDate?.year}.{isEndDate?.month}.
                              {isEndDate?.day}
                            </span>
                          )}
                        </strong>
                      </div>
                    </div>
                    <SearchCard onSearch={handleSearchSignal} />

                    <div
                      className=""
                      style={{
                        display: `flex`,
                        justifyContent: `flex-end`,
                        marginTop: `10px`,
                      }}
                    >
                      <TodayHitStyled>
                        <dt>{t("toDaySponAmountText")}</dt>
                        <dd>
                          <div
                            style={{
                              marginTop: `5px`,
                              fontWeight: `700`,
                              fontSize: `15px`,
                            }}
                          >
                            {t("amountOfSponsorShipText")} :{" "}
                            {formatNumber(isTotalMoney)} {t("wonText")}
                          </div>
                        </dd>
                      </TodayHitStyled>
                      <div
                        style={{
                          marginRight: `10px`,
                        }}
                      ></div>
                      <TodayHitStyled>
                        <dt>{t("sponsorAmountThisMonthText")}</dt>
                        <dd>
                          <div
                            style={{
                              marginTop: `5px`,
                              fontWeight: `700`,
                              fontSize: `15px`,
                            }}
                          >
                            {t("amountOfSponsorShipText")} :{" "}
                            {formatNumber(isTotalSettlementAmount)}{" "}
                            {t("wonText")}
                          </div>
                        </dd>
                      </TodayHitStyled>
                      <div
                        style={{
                          marginRight: `10px`,
                        }}
                      ></div>

                      <TodayHitStyled>
                        <dt>
                          {isStartDate?.year}. {isStartDate?.month}.{" "}
                          {isStartDate?.day}. - {isEndDate?.year}.{" "}
                          {isEndDate?.month}. {isEndDate?.day}.{" "}
                        </dt>
                        <dd>
                          <div
                            style={{
                              marginTop: `5px`,
                              fontWeight: `700`,
                              fontSize: `15px`,
                            }}
                          >
                            {t("amountOfSponsorShipText")} :{" "}
                            {formatNumber(isSearchTotalMoney)} {t("wonText")}
                          </div>
                        </dd>
                      </TodayHitStyled>
                    </div>

                    {/* <div
                      className="card-body"
                      style={{
                        width: `100%`,
                        display: `flex`,
                        justifyContent: `center`,
                      }}
                    >
                      <Loading03 />
                    </div> */}
                    <div>
                      <div className="card-body">
                        {isSponList === null || isSponList === undefined ? (
                          <div
                            style={{
                              display: `flex`,
                              alignContent: `center`,
                              justifyContent: `center`,
                            }}
                          >
                            <Loading01 />
                          </div>
                        ) : (
                          // <></>
                          <MyPageTable data={isSponList} />
                        )}
                        <ReMyPagination />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              {languageData === "en" ? (
                <ReFooter
                  termsLink="/termosfuseCrEn"
                  personalInfoLink="/personalInfoEn"
                />
              ) : (
                <ReFooter
                  termsLink="/termosfuseCr"
                  personalInfoLink="/personalInfo"
                />
              )}
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default ReMyPage;

export const MyModal = () => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <div>
      <div
        href="#"
        className="btn btn-lg btn-primary"
        onClick={toggleModal}
        style={{ height: `40px` }}
      >
        {t("settlementPolicyText")}
      </div>

      {showModal && (
        <div className="modal-popup modal-sm" id="layer-sample">
          <div className="dimed"></div>
          <div className="popup-inner">
            <div className="popup-header">
              <h2 className="popup-title"> {t("settlementPolicyText")}</h2>
            </div>
            <div className="popup-body">
              <div className="scroll-inner">
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <AiFillNotification />
                  </div>
                  <p>
                    <string>{t("settlementPolicyDescriptionText1")}</string>
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <AiFillNotification />
                  </div>
                  <p>
                    <string>{t("settlementPolicyDescriptionText2")}</string>
                    <br></br>
                    <strong>{t("settlementPolicyDescriptionText3")}</strong>
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <AiFillNotification />
                  </div>
                  <p>
                    <string>
                      {t("settlementPolicyDescriptionText4")} <br />
                      {t("settlementPolicyDescriptionText5")}
                    </string>
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <AiFillNotification />
                  </div>
                  <p>
                    <string>{t("settlementPolicyDescriptionText6")}</string>
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <AiFillNotification />
                  </div>
                  <p>
                    <string>{t("settlementPolicyDescriptionText7")}</string>
                  </p>
                </div>
              </div>
            </div>
            <div className="popup-footer">
              <div className="btn-group-center">
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  data-modal="close"
                  onClick={toggleModal}
                >
                  {t("checkText")}
                </button>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-close-popup"
              data-modal="close"
              onClick={toggleModal}
            >
              {t("closeText")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
