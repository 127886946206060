import React from "react";
import { SelectMonthStyle } from "../css/ReMyPageStyld";

const SelectMonth = ({ data, onSelectChange }) => {
  return (
    <SelectMonthStyle
      id="select-month"
      style={{ textAlign: `center`, width: `8rem` }}
      onChange={(e) => onSelectChange(e.target.value)}
    >
      {Array.isArray(data) ? (
        data.map((month, index) => (
          <option key={index} value={month}>
            {month}
          </option>
        ))
      ) : (
        <option></option>
      )}
    </SelectMonthStyle>
  );
};

export default SelectMonth;
