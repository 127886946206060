import { useState } from "react";
import styled from "styled-components";
import { ModifyTableDivStyled } from "../css/ReModifyStyled";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { postPasswordChange } from "../repo/ChangePassword";
import { validatePassword } from "../../../../common/utils/validator";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
export const PasswordChangeModal = ({ onPassword, currentPW }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /// 비밀번호 변경 스타일 영역 -------------------------------------------------------
  const SubTitleTextStyled = styled.div`
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
  `;

  /// 상태영역 -------------------------------------------------------
  /// 모달상태
  const [showModal, setShowModal] = useState(false);
  /// 현재 비밀번호 상태
  const [currentPassword, setCurrentPassword] = useState("");
  /// 비밀번호 상태
  const [isPassword, setIsPassword] = useState("");
  /// 비밀번화 확인 상태
  const [checkPassword, setCheckPassword] = useState("");
  /// 현재 비밀번호 inputType 아이콘 상태
  const [isEyeState3, setEyeState3] = useState(false);
  /// 비밀번호 inputType 아이콘 상태
  const [isEyeState, setEyeState] = useState(false);
  /// 비밀번호 확인 inputType 아이콘 상태
  const [isEyeState2, setEyeState2] = useState(false);
  /// 비밀번호 안맞을경우 텍스트 상태
  const [isFailPassword, setFailPassword] = useState(false);
  /// 현재 비밀번호가 틀렸을경우 상태
  const [isOrPassword, setOrPassword] = useState(false);
  /// 비밀번호 형식에 맞지 않을 경우
  const [isCheckPassword, setChPassword] = useState(false);

  /// 핸들러영역 -------------------------------------------------------

  /// 현재 비밀번호 상태 변경
  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };
  /// 비밀번호 상태 변경
  const handlePassword = (e) => {
    setIsPassword(e.target.value);
  };
  /// 비밀번호 확인 상태 변경
  const handleCheckPassword = (e) => {
    setCheckPassword(e.target.value);
  };
  /// 현재 비밀번호 아이콘 상태 변경
  const handleCurrentEyeClick = () => {
    setEyeState3(!isEyeState3);
  };
  /// 비밀번호 아이콘 상태 변경
  const handleEyeClick = () => {
    setEyeState(!isEyeState);
  };
  /// 비밀빈허 확인 아이콘 상태 변경
  const handleEyeClick2 = () => {
    setEyeState2(!isEyeState2);
  };
  /// 모달의 상태 변경
  const toggleModal = () => setShowModal(!showModal);

  /// 비밀번호 프롭스로 넘기기
  const handleSubmit = async () => {
    if (!validatePassword(isPassword.trim())) {
      setChPassword(true);
      return;
    }
    setChPassword(false);

    if (checkPassword !== isPassword) {
      setFailPassword(true);
      return;
    }

    setFailPassword(false);

    try {
      const resp = await postPasswordChange(checkPassword, currentPassword);

      if (resp.data.response.data.error.status_code === 403) {
        navigate("/");
        toggleModal();

        return;
      }

      if (resp.msg === "Fail") {
        if (resp.data.response.data.message === "잘못된 비밀번호") {
          setOrPassword(true);
        }
        return;
      }

      setOrPassword(false);
      if (resp.msg === "Success") {
      }
    } catch (error) {}

    setCurrentPassword("");
    setIsPassword("");
    setCheckPassword("");
    setEyeState(false);
    setEyeState2(false);
    setEyeState3(false);
    setOrPassword(false);

    Swal.fire({ title: `${t("passwordChangeSuccessText")}`, icon: `success` });

    // currentPW(currentPassword);
    // onPassword(checkPassword);
    toggleModal();
  };

  return (
    <div>
      <div
        className="btn btn-lg btn-primary"
        style={{ height: `2.5rem`, marginLeft: `1rem` }}
        onClick={toggleModal}
      >
        {t("changePasswordText")}
      </div>
      {showModal && (
        <div className="modal-popup modal-sm" id="layer-sample">
          <div className="popup-inner">
            <div className="popup-header">
              <h2 className="popup-title">{t("changePasswordText")}</h2>
            </div>
            <div className="popup-body">
              <SubTitleTextStyled>
                <strong>{t("currentPasswordText")}</strong>
              </SubTitleTextStyled>
              <ModifyTableDivStyled>
                <input
                  className="form-control"
                  type={isEyeState3 ? "text" : "password"}
                  placeholder={t("passwordGuideText")}
                  value={currentPassword}
                  onChange={handleCurrentPassword}
                ></input>
                <div onClick={() => handleCurrentEyeClick()}>
                  {isEyeState3 === true ? (
                    <FaRegEye
                      style={{
                        height: `38px`,
                        width: `38px`,
                        marginLeft: `10px`,
                      }}
                    />
                  ) : (
                    <FaRegEyeSlash
                      style={{
                        height: `38px`,
                        width: `38px`,
                        marginLeft: `10px`,
                      }}
                    />
                  )}
                </div>
              </ModifyTableDivStyled>
              {isOrPassword && (
                <div
                  className="red"
                  style={{
                    marginTop: `5px`,
                    marginLeft: `5px`,
                  }}
                >
                  {t("checkPasswordText")}
                </div>
              )}

              <br />
              <SubTitleTextStyled>
                <strong>{t("passwordText")}</strong>
              </SubTitleTextStyled>
              <ModifyTableDivStyled>
                <input
                  className="form-control"
                  type={isEyeState ? "text" : "password"}
                  placeholder={t("passwordGuideText")}
                  value={isPassword}
                  onChange={handlePassword}
                ></input>
                <div onClick={() => handleEyeClick()}>
                  {isEyeState === true ? (
                    <FaRegEye
                      style={{
                        height: `38px`,
                        width: `38px`,
                        marginLeft: `10px`,
                      }}
                    />
                  ) : (
                    <FaRegEyeSlash
                      style={{
                        height: `38px`,
                        width: `38px`,
                        marginLeft: `10px`,
                      }}
                    />
                  )}
                </div>
              </ModifyTableDivStyled>
              {isCheckPassword && (
                <div
                  className="red"
                  style={{
                    marginTop: `5px`,
                    marginLeft: `5px`,
                  }}
                >
                  {t("passwordGuidingText")}
                </div>
              )}

              <SubTitleTextStyled>
                <strong>{t("finalCheckPasswordText")}</strong>
              </SubTitleTextStyled>
              <div
                style={{
                  display: `flex`,
                  width: `100%`,
                  alignItems: `center`,
                }}
              >
                <input
                  className="form-control"
                  type={isEyeState2 ? "text" : "password"}
                  placeholder={t("reInputPasswordText")}
                  value={checkPassword}
                  onChange={handleCheckPassword}
                ></input>
                <div onClick={() => handleEyeClick2()}>
                  {isEyeState2 === true ? (
                    <FaRegEye
                      style={{
                        height: `38px`,
                        width: `38px`,
                        marginLeft: `10px`,
                      }}
                    />
                  ) : (
                    <FaRegEyeSlash
                      style={{
                        height: `38px`,
                        width: `38px`,
                        marginLeft: `10px`,
                      }}
                    />
                  )}
                </div>
              </div>
              {isFailPassword && (
                <div
                  className="red"
                  style={{
                    marginTop: `5px`,
                    marginLeft: `5px`,
                  }}
                >
                  {t("noSamePasswordText")}{" "}
                </div>
              )}
            </div>

            <div className="popup-footer">
              <div className="btn-group-center">
                <button
                  type="button"
                  className="btn btn-lg btn-gray"
                  data-modal="close"
                  onClick={toggleModal}
                >
                  {t("cancellationText")}
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  data-modal="close"
                  onClick={handleSubmit}
                >
                  {t("modifyingWordText")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
