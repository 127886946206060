import styled, { css } from "styled-components";
import React, { useRef, useEffect } from "react";
import ClipboardJS from "clipboard";
import { htmlCodeCopyText } from "../../text/createButtonText";
import { errorUnknownText } from "../../../../common/publicText/PublicText";
const StyledImage = styled.img`
  margin: 10px;
  ${(props) =>
    props.sizetype === "400" &&
    css`
      width: 17rem;
      height: 10rem;
    `}
  ${(props) =>
    props.sizetype === "170" &&
    css`
      width: 10rem;
      height: 5rem;
    `}
`;

const ButtonImg = ({ src, sizetype, copyText, address }) => {
  const imgRef = useRef(null);

  useEffect(() => {
    const clipboard = new ClipboardJS(imgRef.current, {
      text: () => `<div style="display: flex; justify-content: center;">
      <a href="${address}">
          <img src="${copyText}" alt="">
      </a>
    </div>`,
    });

    clipboard.on("success", (e) => {
      alert(`${htmlCodeCopyText}`);
    });
    clipboard.on("error", (e) => {
      alert(`${errorUnknownText}`);
    });

    return () => {
      clipboard.destroy();
    };
  }, [copyText, address]);
  return <StyledImage src={src} sizetype={sizetype} ref={imgRef}></StyledImage>;
};

export default ButtonImg;
