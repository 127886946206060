export const SET_CONFIRMATION_DATE = "SET_CONFIRMATION_DATE";
export const UPDATE_CONFIRMATION_CHECK = "UPDATE_CONFIRMATION_CHECK";

export const setConfirmationDate = (data) => ({
  type: SET_CONFIRMATION_DATE,
  payload: data,
});

export const updateConfirmationCheck = (id) => ({
  type: UPDATE_CONFIRMATION_CHECK,
  payload: id,
});
