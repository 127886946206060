import { STORE_RESET } from "../action/SidbarIndexAction";
import { SET_BUTTON_URL } from "../action/UrlButtonAction";

const initialState = {
  buttonUrl: "",
};

const buttonUrlReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUTTON_URL:
      return {
        ...state,
        buttonUrl: action.payload,
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default buttonUrlReducer;
