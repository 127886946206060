import React, { useState, useEffect } from "react";

import Editor from "ckeditor5-custom-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../css/draft.css";
import axios from "axios";
import { getCookie } from "../../../common/Cookies/user_cookies";
import { API_KEY } from "../../../common/constant";
import { postContentChange } from "../Renewal/repo/ContentChange";
import { useDispatch } from "react-redux";
import { setContentAction } from "../../../common/redux/action/ProfileAction";
import Swal from "sweetalert2";
import {} from "../../../common/publicText/PublicText";
import {
  pleaseWrightText,
  profileDataSaveFailText,
  profileDateSaveSuccessText,
} from "../Renewal/text/ReModifyText";
import { useTranslation } from "react-i18next";
import QuillEditor from "./quil";
const TextEditor = ({ data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [editorData, setEditorData] = useState("");
  const [content, setContent] = useState(data);
  useEffect(() => {
    setContent(data);
  }, [data]);
  const handleSubmit = async () => {
    try {
      const resp = postContentChange(editorData);

      if (resp === "Fail") {
        Swal.fire({
          title: `${profileDataSaveFailText}`,
          icon: "error",
        });
        console.log("프로필 수정 실패");
        return;
      } else {
        Swal.fire({
          title: `${profileDateSaveSuccessText}`,
          icon: "success",
        });
        dispatch(setContentAction(editorData));
      }
    } catch (error) {}
  };
  return (
    <div>
      <div className="textEditor editor-class">
        <CKEditor
          editor={Editor}
          data={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
          }}
          config={{
            fontSize: {
              options: [
                10,
                12,
                14,
                16,
                18,
                `default`,
                22,
                24,
                26,
                28,
                30,
                32,
                34,
                36,
                38,
                40,
                42,
                44,
                46,
                48,
                50,
                52,
                54,
                56,
                58,
                60,
              ],
              supportAllValues: false,
            },

            placeholder: `${pleaseWrightText}`,

            simpleUpload: {
              uploadUrl: "",
            },
          }}
          onReady={(editor) => {
            console.log();
          }}
        ></CKEditor>
      </div>
      <div className="editorSave">
        <button
          className="btn btn-primary"
          style={{
            height: `3rem`,
            width: `120px`,
            fontWeight: `700`,
            fontSize: `18px`,
          }}
          onClick={handleSubmit}
        >
          {t("modifyingText")}
        </button>
      </div>
    </div>
  );
};

export default TextEditor;
