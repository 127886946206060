import {
  SET_ACCOUNT,
  SET_BANK,
  SET_CONTENT,
  SET_NICKNAME,
  SET_PROFILE_DATA,
  SET_PROFILE_IMG,
} from "../action/ProfileAction";
import { STORE_RESET } from "../action/SidbarIndexAction";

const initialState = {
  profile: null,
};

const reProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE_DATA:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_NICKNAME:
      return {
        ...state,
        profile: {
          ...state.profile,
          nickname: action.payload,
        },
      };
    case SET_ACCOUNT:
      return {
        ...state,
        profile: {
          ...state.profile,
          account: action.payload,
        },
      };
    case SET_BANK:
      return {
        ...state,
        profile: {
          ...state.profile,
          bank: action.payload,
        },
      };
    case SET_CONTENT:
      return {
        ...state,
        profile: {
          ...state.profile,
          text: action.payload,
        },
      };
    case SET_PROFILE_IMG:
      return {
        ...state,
        profile: {
          ...state.profile,
          profileImgPath: action.payload,
        },
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default reProfileReducer;
