import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 오류를 상태에 반영하여 백업 UI를 표시합니다.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 에러 로깅을 할 수 있습니다.
    // 예: logErrorToMyService(error, errorInfo);

    // navigate 함수를 사용하여 홈으로 리다이렉트합니다.
    this.props.navigate("/");
  }

  render() {
    if (this.state.hasError) {
      // 오류 발생시 렌더링할 UI
      // 리다이렉션되기 때문에 실제로 이 UI는 보여지지 않습니다.
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
