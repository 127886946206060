import api from "../../../../Repository/Interceptor";

export const getProfileData = async () => {
  try {
    const resp = await api.get("/mypage/change", {
      // headers: {
      //   "API-Key": "COFFEE1234!!!",
      // },
    });

    return resp;
  } catch (error) {
    console.log("프로필 호출 에러", error);
    return "Fail";
  }
};
