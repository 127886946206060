import { API_URL } from "../../../../Repository/URL";
const ButtonImage = process.env.PUBLIC_URL;

export const thumbnailButton = [
  {
    src: `${ButtonImage}/img/ko/png/Button L705.png`,
    sizetype: "401",
    address: `${API_URL}/img/Button L705.png`,
    view: `/img/ko/400x220/Button L705.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button L706.png`,
    sizetype: "401",
    address: `${API_URL}/img/Button L706.png`,
    view: `/img/ko/400x220/Button L706.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button L716.png`,
    sizetype: "401",
    address: `${API_URL}/img/Button L716.png`,
    view: `/img/ko/400x220/Button L716.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button L718.png`,
    sizetype: "401",
    address: `${API_URL}/img/Button L718.png`,
    view: `/img/ko/400x220/Button L718.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button L719.png`,
    sizetype: "401",
    address: `${API_URL}/img/Button L719.png`,
    view: `/img/ko/400x220/Button L719.svg`,
  },
];

export const bigButton = [
  {
    src: `${ButtonImage}/img/ko/png/Button 705.png`,
    sizetype: "400",
    address: `${API_URL}/img/Button 705.png`,
    view: `img/ko/400x160/Button 705.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button 706.png`,
    sizetype: "400",
    address: `${API_URL}/img/Button 706.png`,
    view: `img/ko/400x160/Button 706.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button 716.png`,
    sizetype: "400",
    address: `${API_URL}/img/Button 716.png`,
    view: `img/ko/400x160/Button 716.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button 718.png`,
    sizetype: "400",
    address: `${API_URL}/img/Button 718.png`,
    view: `img/ko/400x160/Button 718.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button 719.png`,
    sizetype: "400",
    address: `${API_URL}/img/Button 719.png`,
    view: `img/ko/400x160/Button 719.svg`,
  },
];

export const smallButton = [
  {
    src: `${ButtonImage}/img/ko/png/Button S705.png`,
    sizetype: "170",
    address: `${API_URL}/img/Button S705.png`,
    view: `img/ko/400x160/Button 705.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button S706.png`,
    sizetype: "170",
    address: `${API_URL}/img/Button S706.png`,
    view: `img/ko/400x160/Button 706.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button S716.png`,
    sizetype: "170",
    address: `${API_URL}/img/Button S716.png`,
    view: `img/ko/400x160/Button 716.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button S718.png`,
    sizetype: "170",
    address: `${API_URL}/img/Button S718.png`,
    view: `img/ko/400x160/Button 718.svg`,
  },
  {
    src: `${ButtonImage}/img/ko/png/Button S719.png`,
    sizetype: "170",
    address: `${API_URL}/img/Button S719.png`,
    view: `img/ko/400x160/Button 719.svg`,
  },
];

export const enThumbnailButton = [
  {
    src: `${ButtonImage}/img/en/400x220/Button01.png`,
    sizetype: "401",
    address: `${API_URL}/img/en/400x220/Button01.png`,
    view: `img/en/400x220/svg/Button01.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x220/Button02.png`,
    sizetype: "401",
    address: `${API_URL}/img/en/400x220/Button02.png`,
    view: `img/en/400x220/svg/Button02.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x220/Button03.png`,
    sizetype: "401",
    address: `${API_URL}/img/en/400x220/Button03.png`,
    view: `img/en/400x220/svg/Button03.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x220/Button05.png`,
    sizetype: "401",
    address: `${API_URL}/img/en/400x220/Button05.png`,
    view: `img/en/400x220/svg/Button05.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x220/Button06.png`,
    sizetype: "401",
    address: `${API_URL}/img/en/400x220/Button06.png`,
    view: `img/en/400x220/svg/Button06.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x220/Button07.png`,
    sizetype: "401",
    address: `${API_URL}/img/en/400x220/Button07.png`,
    view: `img/en/400x220/svg/Button07.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x220/Button08.png`,
    sizetype: "401",
    address: `${API_URL}/img/en/400x220/Button08.png`,
    view: `img/en/400x220/svg/Button08.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x220/Button09.png`,
    sizetype: "401",
    address: `${API_URL}/img/en/400x220/Button09.png`,
    view: `img/en/400x220/svg/Button09.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x220/Button10.png`,
    sizetype: "401",
    address: `${API_URL}/img/en/400x220/Button10.png`,
    view: `img/en/400x220/svg/Button10.svg`,
  },
];

export const enBigButton = [
  {
    src: `${ButtonImage}/img/en/400x160/Button01.png`,
    sizetype: "400",
    address: `${API_URL}/img/en/400x160/Button01.png`,
    view: `img/en/400x160/svg/Button01.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x160/Button02.png`,
    sizetype: "400",
    address: `${API_URL}/img/en/400x160/Button02.png`,
    view: `img/en/400x160/svg/Button02.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x160/Button03.png`,
    sizetype: "400",
    address: "https://xrai-api.com/coffeeimg/y300.png",
    address: `${API_URL}/img/en/400x160/Button03.png`,
    view: `img/en/400x160/svg/Button03.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x160/Button05.png`,
    sizetype: "400",
    address: `${API_URL}/img/en/400x160/Button05.png`,
    view: `img/en/400x160/svg/Button05.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x160/Button06.png`,
    sizetype: "400",
    address: `${API_URL}/img/en/400x160/Button06.png`,
    view: `img/en/400x160/svg/Button06.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x160/Button07.png`,
    sizetype: "400",
    address: `${API_URL}/img/en/400x160/Button07.png`,
    view: `img/en/400x160/svg/Button07.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x160/Button08.png`,
    sizetype: "400",
    address: `${API_URL}/img/en/400x160/Button08.png`,
    view: `img/en/400x160/svg/Button08.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x160/Button09.png`,
    sizetype: "400",
    address: `${API_URL}/img/en/400x160/Button09.png`,
    view: `img/en/400x160/svg/Button09.svg`,
  },
  {
    src: `${ButtonImage}/img/en/400x160/Button10.png`,
    sizetype: "400",
    address: `${API_URL}/img/en/400x160/Button10.png`,
    view: `img/en/400x160/svg/Button10.svg`,
  },
];

export const enSmallButton = [
  {
    src: `${ButtonImage}/img/en/170x80/Button01.png`,
    sizetype: "170",
    address: `${API_URL}/img/en/170x80/Button01.png`,
    view: `img/en/400x160/svg/Button01.svg`,
  },
  {
    src: `${ButtonImage}/img/en/170x80/Button02.png`,
    sizetype: "170",
    address: `${API_URL}/img/en/170x80/Button02.png`,
    view: `img/en/400x160/svg/Button02.svg`,
  },
  {
    src: `${ButtonImage}/img/en/170x80/Button03.png`,
    sizetype: "170",
    address: `${API_URL}/img/en/170x80/Button03.png`,
    view: `img/en/400x160/svg/Button03.svg`,
  },
  {
    src: `${ButtonImage}/img/en/170x80/Button05.png`,
    sizetype: "170",
    address: `${API_URL}/img/en/170x80/Button05.png`,
    view: `img/en/400x160/svg/Button05.svg`,
  },
  {
    src: `${ButtonImage}/img/en/170x80/Button06.png`,
    sizetype: "170",
    address: `${API_URL}/img/en/170x80/Button06.png`,
    view: `img/en/400x160/svg/Button06.svg`,
  },
  {
    src: `${ButtonImage}/img/en/170x80/Button07.png`,
    sizetype: "170",
    address: `${API_URL}/img/en/170x80/Button07.png`,
    view: `img/en/400x160/svg/Button07.svg`,
  },
  {
    src: `${ButtonImage}/img/en/170x80/Button08.png`,
    sizetype: "170",
    address: `${API_URL}/img/en/170x80/Button08.png`,
    view: `img/en/400x160/svg/Button08.svg`,
  },
  {
    src: `${ButtonImage}/img/en/170x80/Button09.png`,
    sizetype: "170",
    address: `${API_URL}/img/en/170x80/Button09.png`,
    view: `img/en/400x160/svg/Button09.svg`,
  },
  {
    src: `${ButtonImage}/img/en/170x80/Button10.png`,
    sizetype: "170",
    address: `${API_URL}/img/en/170x80/Button10.png`,
    view: `img/en/400x160/svg/Button10.svg`,
  },
];
