import api from "../../../../Repository/Interceptor";

export const getReUrlData = async () => {
  try {
    const resp = await api.get("/mypage/find/address", {
      // headers: {
      //   "API-Key": "COFFEE1234!!!",
      // },
    });

    return resp;
  } catch (error) {
    console.log("내 URL 주소 불러오기 오류", error);
    return "Fail";
  }
};
