import api from "../../../../Repository/Interceptor";

export const postChangeNickName = async (nickname) => {
  const url = `/mypage/change/nickname?nickname=${encodeURIComponent(
    nickname
  )}`;

  try {
    const resp = await api.post(url, null, {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
    });
    console.log("resp", resp);
    return resp;
  } catch (error) {
    console.log("닉네임 변경 오류", error);
    return {
      data: error.response.data,
      msg: "Fail",
    };
  }
};
