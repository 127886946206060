import React from "react";
import { Route, Navigate } from "react-router-dom";
import { getCookie } from "../common/Cookies/user_cookies";
import { tokenName } from "./const";

export const ProtectedRoute = ({ element: Element }) => {
  const token = getCookie(tokenName);

  if (!token) {
    return <Navigate to="/renewalLogin" />;
  }

  return <Element />;
};

export default ProtectedRoute;
