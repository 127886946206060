import Swal from "sweetalert2";
import { postChangeNickName } from "../repo/ChangeNickName";
import { setNickNameData } from "../../../../common/redux/action/ProfileAction";
import { setLoginType } from "../../../../common/redux/action/LoginTypeAction";
import { useSelector } from "react-redux";

export const handleNickNameChange = async (
  newNickName,
  dispatch,
  setNickName,
  t,
  typeLogin
) => {
  if (
    !newNickName.trim() ||
    newNickName.includes(" ") ||
    newNickName.trim().length < 2
  ) {
    Swal.fire({
      title: `${t("notTrimTwoLengthInputText")}`,
      icon: "error",
    });
    return;
  }

  try {
    const resp = await postChangeNickName(newNickName);

    if (resp.msg === "Fail") {
      if (resp.data.message === "바꾼지 한달 안됨") {
        Swal.fire({
          title: `${t("changeNickNameDayText")}`,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: `${t("nicknameChangeFailedText")}`,
          icon: "error",
        });
      }
      return;
    } else {
      Swal.fire({
        title: `${t("nicknameChangeSuccessText")}`,
        icon: "success",
      });
      dispatch(setNickNameData(newNickName));
      setNickName(newNickName);
    }
  } catch (error) {}

  setNickName(newNickName);
};

// export const modifyHandle = async () => {
//   const typeLogin = useSelector((state) => state?.loginTypeState);

//   try {
//     if (typeLogin === "Kakao") {
//       setLoginType(true);
//     }

//     setNickName(userProfileData?.profile?.nickname || "");
//     const phon = userProfileData?.profile?.phone || "";
//     const formattedNumber = formatPhoneNumber(phon);
//     setPhonNumber(formattedNumber);
//     setEditorData(userProfileData?.profile?.text);
//     if (userProfileData?.bank === null || userProfileData?.bank === undefined) {
//     }
//   } catch (error) {}
// };
