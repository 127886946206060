import {
  SET_CONFIRMATION_DATE,
  UPDATE_CONFIRMATION_CHECK,
} from "../action/ConfirmationAction";
import { STORE_RESET } from "../action/SidbarIndexAction";

const initialState = {
  data: null,
};

const confirmationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIRMATION_DATE:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_CONFIRMATION_CHECK:
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === action.payload ? { ...item, isCheck: true } : item
        ),
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default confirmationReducer;
