import { LANGUAGE_CURRENT_SET } from "../action/languageAction";

const initialState = {
  type: "ko",
};

const languageTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_CURRENT_SET:
      return {
        type: action.payload,
      };
    default:
      return state;
  }
};

export default languageTypeReducer;
