import { useState } from "react";
import { checkText } from "../../../common/publicText/PublicText";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const SaveCheckModal = ({ handleReStartSave }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const ResetDivBox = styled.div`
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
  `;

  const ResetTextDivBox = styled.div`
    display: flex;
    justify-content: center;
    font-size: 15px;
    margin-top: 10px;
  `;

  return (
    <div>
      <div
        href="#"
        className="btn btn-lg btn-primary"
        onClick={toggleModal}
        style={{ height: `3rem`, width: `6rem` }}
      >
        {t("resetText")}
      </div>

      {showModal && (
        <div className="modal-popup modal-sm" id="layer-sample">
          <div className="dimed"></div>
          <div className="popup-inner">
            <div className="popup-header">
              <h2 className="popup-title"> {t("resetText")}</h2>
            </div>
            <div className="popup-body">
              <ResetDivBox>{t("resetCheckText")}</ResetDivBox>
              <ResetTextDivBox>{t("resetInfoText")}</ResetTextDivBox>
            </div>
            <div className="popup-footer">
              <div className="btn-group-center">
                <button
                  type="button"
                  className="btn  btn-lg btn-gray"
                  data-modal="close"
                  onClick={toggleModal}
                >
                  {t("cancellationText")}
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  data-modal="close"
                  onClick={() => {
                    handleReStartSave();
                    toggleModal();
                  }}
                >
                  {t("checkText")}
                </button>
              </div>
            </div>
            {/* <button
                  type="button"
                  className="btn btn-close-popup"
                  data-modal="close"
                  onClick={toggleModal}
                >
                  {closeText}
                </button> */}
          </div>
        </div>
      )}
    </div>
  );
};
