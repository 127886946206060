export const accountNumberChangeFailText = "계좌번호 변경에 실패하였습니다.";
export const accountNumberChangeSuccessText = "계좌번호를 변경하였습니다.";
export const nicknameChangeFailedText = "닉네임 변경에 실패하였습니다.";
export const nicknameChangeSuccessText = "닉네임을 변경하였습니다.";
export const profileDataSaveFailText = "프로필 정보 수정을 실패하였습니다.";
export const profileDateSaveSuccessText = "프로필 정보를 수정했습니다.";
export const pleaseWrightText = "글을 입력하세요";
export const accountTrimNumberInputText =
  "계좌번호는 공백없이 숫자만 입력해주세요.";
export const accountChangeText = "계좌번호 변경";
export const checkBankName = "은행명을 확인해주세요.";
export const changeNickNameText = "닉네임 변경";
export const nickNameSponsorViewText =
  "닉네임은 후원자가 후원시 표시될 내용입니다.";
export const nickNameChangeWarningText =
  "닉네임 수정시 한달간 변경이 불가합니다. 신중히 변경해주세요.";
export const nichNameChangeAnnouncementText =
  "부적적한 닉네임 사용시 닉네임 변경을 요청할 수 있으며, 요청에 응하지 않은 경우 무작위 닉네임으로 설정할 수 있습니다.";
export const choiceChangeNickNameText = "변경할 닉네임";
export const choiceChangeNickNameInputText = "변경할 닉네임을 입력해주세요.";
export const changePasswordText = "비밀번호 변경";
export const currentPasswordText = "현재 비밀번호";
export const passwordGuideText = "6~16 자리 영문, 숫자, 특수문자 혼합";
export const passwordGuidingText =
  "6~16 자리, 영문, 숫자, 특수문자를 조합해주세요.";
export const checkPasswordText = "비밀번호를 다시 확인해주세요.";
export const finalCheckPasswordText = "비밀번호 확인";
export const reInputPasswordText = "비밀번호를 한 번 더 입력해주세요.";
export const noSamePasswordText = "비밀번호가 일치하지 않습니다.";
export const profileUpdateFailText = "프로필 사진 업로드를 실패했습니다.";
export const notTrimTwoLengthInputText = "공백없이 2글자 이상 입력해주세요.";
export const nameTitleText = "이름 (본명)";
export const inputNickNameText = "닉네임을 입력해주세요.";
export const callNumberText = "전화번호";
export const accountNumberText = "계좌번호";
export const accountSaveText = "계좌번호를 등록해주세요.";
export const inputBankNamePleaseText = "은행명을 입력해주세요.";
export const modifyProfileSaveText = "프로필 수정";
