import { useState } from "react";
import styled from "styled-components";
import { ModifyTableDivStyled } from "../css/ReModifyStyled";
import { GoAlertFill } from "react-icons/go";
import { useTranslation } from "react-i18next";

export const NickNameChangeModal = ({ onNickNameChange }) => {
  const { t } = useTranslation();
  /// 변경 스타일 영역 -------------------------------------------------------
  const SubTitleTextStyled = styled.div`
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
  `;

  const GuideTextBoxStyled = styled.div`
    display: flex;
    align-items: start;
    margin-bottom: 10px;
  `;
  /// 상태영역 -------------------------------------------------------
  /// 모달상태
  const [showModal, setShowModal] = useState(false);
  /// 닉네임 상태
  const [isNickname, setNickName] = useState("");

  /// 핸들러영역 -------------------------------------------------------

  /// 모달의 상태 변경
  const toggleModal = () => setShowModal(!showModal);

  const handleSuccessNickName = (e) => {
    setNickName(e.target.value);
  };

  const handleSubmit = () => {
    onNickNameChange(isNickname);
    toggleModal();
  };

  return (
    <div>
      <div
        className="btn btn-lg btn-primary"
        style={{ height: `2.5rem`, marginLeft: `1rem` }}
        onClick={toggleModal}
      >
        {t("changeNickNameText")}
      </div>
      {showModal && (
        <div className="modal-popup modal-sm" id="layer-sample">
          <div className="popup-inner">
            <div className="popup-header">
              <h2 className="popup-title">{t("changeNickNameText")}</h2>
            </div>
            <div className="popup-body">
              <GuideTextBoxStyled>
                <GoAlertFill style={{ color: `#357fed`, minWidth: `20px` }} />
                <div style={{ marginLeft: `10px` }}>
                  <strong>{t("nickNameSponsorViewText")}</strong>
                </div>
              </GuideTextBoxStyled>
              <GuideTextBoxStyled>
                <GoAlertFill style={{ color: `#357fed`, minWidth: `20px` }} />
                <div style={{ marginLeft: `10px` }}>
                  <strong>{t("nickNameChangeWarningText")}</strong>
                </div>
              </GuideTextBoxStyled>
              <GuideTextBoxStyled>
                <GoAlertFill style={{ color: `#357fed`, minWidth: `20px` }} />
                <div style={{ marginLeft: `10px` }}>
                  <strong>{t("nichNameChangeAnnouncementText")}</strong>
                </div>
              </GuideTextBoxStyled>
              <SubTitleTextStyled>
                <strong>{t("choiceChangeNickNameText")}</strong>
              </SubTitleTextStyled>
              <ModifyTableDivStyled>
                <input
                  className="form-control"
                  placeholder={t("choiceChangeNickNameInputText")}
                  value={isNickname}
                  onChange={handleSuccessNickName}
                ></input>
              </ModifyTableDivStyled>
            </div>

            <div className="popup-footer">
              <div className="btn-group-center">
                <button
                  type="button"
                  className="btn btn-lg btn-gray"
                  data-modal="close"
                  onClick={toggleModal}
                >
                  {t("cancellationText")}
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  data-modal="close"
                  onClick={handleSubmit}
                >
                  {t("modifyingWordText")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
