import api from "../../../../Repository/Interceptor";

export const postPasswordChange = async (newPW, orPW) => {
  try {
    const resp = await api.post(
      "/mypage/change/password",
      {
        password: newPW,
        oripassword: orPW,
      },
      {
        headers: {
          "API-Key": "COFFEE1234!!!",
        },
      }
    );

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("비밀번호 변경 실패");
    return {
      data: error,
      msg: "Fail",
    };
  }
};
