import {
  RESET_CALCULATE_DATA,
  SET_CALCULATE_DATA,
} from "../action/CalculateAction";
import { STORE_RESET } from "../action/SidbarIndexAction";

const initialState = {
  data: null,
};

export const calculateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALCULATE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case RESET_CALCULATE_DATA:
      return initialState;
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};
