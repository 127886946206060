import { legacy_createStore as createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { combineReducers } from "redux";
import sidebarReducer from "./reducer/SidbarReducer";
import { profileReducer } from "./reducer/UserProfileImgReducerAction";
import toDayReducer, { lastMonthReducer } from "./reducer/ToDayReducer";
import dashBoardReducer from "./reducer/DashBoardReducer";
import buttonUrlReducer from "./reducer/UrlButtonReducer";
import searchTextReducer, {
  searchSelectedValueReducer,
} from "./reducer/SearchTextReducer";
import paginationReducer from "./reducer/PaginationReducer";
import reProfileReducer from "./reducer/ProfileReducer";
import { calculateReducer } from "./reducer/CalculateReducer";
import loginTypeReducer from "./reducer/LoginTypeReducer";
import authReducer from "./reducer/AuthReducer";
import confirmationReducer from "./reducer/ConfirmationReducer";
import languageTypeReducer from "./reducer/LanguageReducer";
import intlReducer from "./reducer/IntlTelNumberReducer";

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  userprofileImg: profileReducer,
  today: toDayReducer,
  lastDay: lastMonthReducer,
  dashboard: dashBoardReducer,
  buttonURL: buttonUrlReducer,
  searchText: searchTextReducer,
  searchSelectedValue: searchSelectedValueReducer,
  paginationState: paginationReducer,
  profileState: reProfileReducer,
  calculateState: calculateReducer,
  loginTypeState: loginTypeReducer,
  authState: authReducer,
  confirmationDate: confirmationReducer,
  authReducer: authReducer,
  languageType: languageTypeReducer,
  intlReducerState: intlReducer,
});

const persistConfig = {
  key: "coffee",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
