import React from "react";

import { SignUpSuccessButtonStyled } from "../Renewal/css/ReSignUpStyled";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SignUpComponent = ({
  isInputCheckPhoneNumber,
  selectedCountry,
  handleSingUpButton,
}) => {
  const languageData = useSelector((state) => state.languageType.type);

  const { t, i18n } = useTranslation();

  const handleClick = () => {
    if (languageData === "en") {
      handleSingUpButton(selectedCountry);
    } else {
      handleSingUpButton(isInputCheckPhoneNumber);
    }
  };

  return (
    <SignUpSuccessButtonStyled
      className="btn btn-lg btn-primary"
      onClick={handleClick}
    >
      {t("signUpSuccessText")}
    </SignUpSuccessButtonStyled>
  );
};

export default SignUpComponent;
