import { useState } from "react";
import { SignUpDivClickStyled } from "../../SignUp/Renewal/css/ReSignUpStyled";
import { IoIosArrowForward } from "react-icons/io";

import { useTranslation } from "react-i18next";

export const TextModal = ({ children }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <div>
      <SignUpDivClickStyled onClick={toggleModal}>
        <strong>{t("inDetailText")}</strong>
        <IoIosArrowForward size={20} />
      </SignUpDivClickStyled>

      {showModal && (
        <div className="modal-popup modal-sm" id="layer-sample">
          <div className="dimed"></div>
          <div className="popup-inner">
            <div className="popup-body">
              <div className="scroll-inner">{children}</div>
            </div>
            <div className="popup-footer">
              <div className="btn-group-center">
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  data-modal="close"
                  onClick={toggleModal}
                >
                  {t("checkText")}
                </button>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-close-popup"
              data-modal="close"
              onClick={toggleModal}
            >
              {t("closeText")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
