import { SET_PAGINATION_DATA } from "../action/PaginationAction";
import { STORE_RESET } from "../action/SidbarIndexAction";

const initialState = {
  total: 0,
  current: 1,
};

const paginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGINATION_DATA:
      return {
        ...state,
        total: action.payload.total,
        current: action.payload.current,
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default paginationReducer;
