import { useEffect, useState } from "react";
import { SignUpStyledSelect } from "../Renewal/css/ReSignUpStyled";

const CountryDropdown = ({ setSelectedCountry }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const countryOptions = data.map((country) => ({
          name: country.name.common,
          code: country.cca2,
          flag: country.flags.png, // 국기 이미지 URL 추가
        }));

        // 알파벳 순서로 정렬
        countryOptions.sort((a, b) => a.name.localeCompare(b.name));

        setCountries(countryOptions);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleChange = (event) => {
    const selectedCode = event.target.value;
    const selectedCountry = countries.find(
      (country) => country.code === selectedCode
    );
    setSelectedCountry(selectedCountry ? selectedCountry.name : "");
  };

  return (
    <div>
      <SignUpStyledSelect id="country-select" onChange={handleChange}>
        <option value="">-- Please select a country --</option>
        {countries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </SignUpStyledSelect>
    </div>
  );
};

export default CountryDropdown;
