export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_NICKNAME = "SET_NICKNAME";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_BANK = "SET_BANK";
export const SET_CONTENT = "SET_CONTENT";
export const SET_PROFILE_IMG = "SET_PROFILE_IMG";

export const setReProfileImg = (img) => ({
  type: SET_PROFILE_IMG,
  payload: img,
});

export const setProfileData = (profile) => ({
  type: SET_PROFILE_DATA,
  payload: profile,
});

export const setNickNameData = (nickname) => ({
  type: SET_NICKNAME,
  payload: nickname,
});

export const setAccount = (account) => ({
  type: SET_ACCOUNT,
  payload: account,
});

export const setBank = (bank) => ({
  type: SET_BANK,
  payload: bank,
});

export const setContentAction = (content) => ({
  type: SET_CONTENT,
  payload: content,
});
