import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/povisionsDon.css";
import Footer from "../../Footer/screen/Footer";

const ProvisionsDonEn = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="provisionsText">
        <p className="mb-3 Pr-Title"> Terms & Conditions</p>
        <p className="mb-3">
           Payments must be made in your own name, and if you make a payment
          using someone else's name, you may be subject to civil and criminal
          liability.
        </p>
        <p className="mb-0 provisSubText"> 1. Purpose</p>
        <p className="mb-3">
           The purpose of these Terms and Conditions is to stipulate the terms
          of use and procedures for using the Creator Sponsorship Service of
          XRAI Co., Ltd. (hereinafter referred to as the “Company”).
        </p>
        <p className="mb-0 provisSubText">
           2. Specification, Effect, and Revision of Terms and Conditions
        </p>
        <p className="mb-0">
           1. These terms and conditions are posted on the service registration
          screen so that members can easily understand them, and they take
          effect on members who wish to use this service.
        </p>
        <p className="mb-0">
           2. The Company ensures that these Terms and Conditions do not violate
          any relevant laws such as the “Act on Relief of Terms and Conditions,”
          “Act on Promotion of Information and Communications Network
          Utilization and Information Protection, etc.,” “Consumer Protection
          Act” in e-commerce, and “Content Industry Promotion Act”. These Terms
          may be amended within the scope of complying with the laws.
        </p>
        <p className="mb-0">
           3. When the company revises the terms and conditions, it sets the
          revised terms and conditions and the date of enforcement and announces
          them on the service page at least 7 days before the effective date and
          for a considerable period of time after the effective date, and
          announces them 30 days before the date of revision of the terms and
          conditions that are disadvantageous to members. Existing members will
          be separately notified of the revised terms and conditions, date of
          application, and reason.
        </p>
        <p className="mb-3">
           4. If a member does not express your intention to reject the revised
          Terms and Conditions despite being clearly notified that failure to
          express your intention to reject the revised Terms and Conditions 7
          days after the date of enforcement of the revised Terms and Conditions
          from the date of notification will be deemed as approval, the member
          is deemed to have approved the revised Terms and Conditions. If the
          member does not agree to the revised terms and conditions, the member
          may terminate the service agreement.
        </p>
        <p className="mb-0 provisSubText">
           3. Interpretation and Application of Terms and Conditions
        </p>
        <p className="mb-0">
           1. Matters not specified in these Terms and Conditions shall be
          governed by separate detailed terms and conditions, company notices,
          usage guidelines, and relevant laws and regulations.
        </p>
        <p className="mb-3">
           2. When a member uses the service by entering into an individual
          contract with the company, the individual contract takes precedence
          over these terms and conditions.
        </p>
        <p className="mb-0 provisSubText"> 4. Definition of Terms</p>
        <p className="mb-0">
           1. “Sponsorship amount” refers to the amount sponsored by a
          participant or viewer directly by payment.
        </p>
        <p className="mb-0">
           2. “Payment” refers to purchasing a certain amount of money through
          the payment method provided by the company when making a sponsorship.
        </p>
        <p className="mb-0">
           3. “Paid service” refers to a service sponsored by a member by paying
          a set fee through the payment method provided by the company.
        </p>
        <p className="mb-3">
           4. Members using paid services must pay the service fee immediately
          after applying.
        </p>
        <p className="mb-0 provisSubText"> 5. Payment of Fees</p>
        <p className="mb-0">
           1. Payment of the donation amount is possible through payment methods
          provided by the company, such as credit card, mobile phone payment,
          and simple payment. However, if there is a separate business operator
          that operates the payment method, the member must follow the
          procedures presented by the business operator of the payment method
          before using the payment method. When paying the company's service
          fee, you are deemed to have agreed to the procedures and terms of use
          presented by the operator of the relevant payment method.
        </p>
        <p className="mb-0">
           2. Payment of the sponsorship amount can be made in units provided by
          the company's internal policy, and the charging units may differ
          depending on the payment method selected.
        </p>
        <p className="mb-0">
           3. When a minor under the age of 19 pays the donation amount using
          points such as credit card, mobile phone payment, real-time account
          transfer, or bankbook payment method, the consent of the legal
          representative must be obtained and the payment fee can be up to
          70,000 won per month. This may apply. However, payment limits may be
          granted for each payment method depending on the payment method
          operating company or government policy, and the company may also grant
          payment limits for specific payment methods.
        </p>
        <p className="mb-0">
           4. When a minor under the age of 19 pays for a regular payment
          service using a payment method designated by the company, he or she
          must obtain the consent of his or her legal representative, and a fee
          of up to 100,000 won per month may be charged at the time of payment.
          However, payment limits may be granted for each payment method
          depending on the payment method's operating company or government
          policy, and the company may also grant a payment limit for a specific
          payment method.
        </p>
        <p className="mb-0">
           5. In order to consent to payment for minors under Paragraphs 3 and
          4, the legal representative may give consent in bulk through “mobile
          phone authentication” following the company’s instructions, and after
          the legal representative’s consent is completed, the company will
          notify the legal representative via email. We will notify you of the
          payment consent and its details. If a minor member performs an
          obligatory act, such as payment of a fee, without the consent of the
          minor's legal representative, the minor member or the minor's legal
          representative may cancel the contract in accordance with relevant
          laws and regulations. However, cases resulting from fraudulent use by
          minors are excluded.
        </p>
        <p className="mb-3">
           6. Regarding the validity period of the consent form in Paragraph 4,
          the legal representative of the minor may choose between the validity
          periods of “1 month/6 months/1 year/3 years/5 years/until withdrawal”
          according to the company’s guidance, and the company will notify the
          legal representative via email 30 days prior to the expiration of the
          consent validity period selected by the agent to confirm whether the
          consent will be extended. If there is no request for withdrawal of
          consent from the legal representative, the consent validity period
          will be automatically extended under the same conditions. However, if
          the legal representative selects 1 month as the consent validity
          period, the company will notify the legal representative 7 days prior
          to the expiration of the consent validity period.
        </p>
        <p className="mb-0 provisSubText"> 6. Payment Approval</p>
        <p className="mb-0">
           1. The company may not approve applications for use that fall under
          any of the following items, or may cancel such approval at a later
          date: 1) If essential information is omitted or incorrect or essential
          procedures are insufficient. 2) If the usage fee is not paid or the
          payer cannot be confirmed. 3) When a minor under the age of 19 applies
          without the consent of a legal representative. 4) If the usage fee is
          paid by unauthorized use of the payment method without the consent or
          approval of the owner. 5) In other cases where approval is deemed
          impossible due to reasons attributable to the member.
        </p>
        <p className="mb-3">
           2. If any of the following applies, the company may restrict approval
          until the reason for the restriction of approval for the application
          for use is resolved: 1) When it is determined that satisfactory
          service cannot be provided due to insufficient service facilities. 2)
          If a service failure occurs.
        </p>
        <p className="mb-0 provisSubText"> 7. Refund of Payment Amount</p>
        <p className="mb-0">
           1. If a sponsor wishes to refund the amount paid, he or she may
          receive a refund within 7 days after the payment date through legal
          procedures (e-mail, etc.). However, refunds are not possible due to a
          member's simple change of mind. 1) Refund inquiry email :
          dltmdtjr9683@xrai-api.com 2) Refund-related information:   · Name   ·
          Phone number   · Payment amount and payment method   · Payment date
          and time   · Detailed reason for refund
        </p>
        <p className="mb-0">
           2. If 7 days have passed since payment and there are special reasons,
          the sponsor may first contact the creator who sponsored to request a
          refund, and the creator may request a refund from the company and
          receive a refund.
        </p>
        <p className="mb-0">
           3. In the case of a sponsor who is judged to have committed an act
          that has a serious negative impact on the operation of Sponsored
          Drinks, such as a serious illegal act against various laws such as the
          Resident Registration Act, Copyright Act, Computer Program Protection
          Act, and Information and Communications Network Act, the company may
          not refund the payment amount. However, this does not apply if the
          sponsor proves that he or she has not committed any illegal acts or
          any actions that have a serious adverse effect on the sponsored drink.
        </p>
        <p className="mb-3">
           4. Even if a refund is possible according to the above items, the
          refund may be restricted or suspended upon request from a payment
          agency or financial company that provides the specific payment method
          used by the sponsor. Applicable payment methods can be confirmed
          through separate guidance, and the company will immediately notify and
          provide guidance if there is a change in the payment agency or
          financial company's policy for a specific payment method
        </p>
        <p className="mb-0 provisSubText">
           8. Personal Information Protection
        </p>
        <p className="mb-0">
           1. The company uses member information related to paid services only
          for the purpose of providing services under this service agreement and
          cannot disclose or distribute it to a third party without the member’s
          prior consent. However, this does not apply in the following cases: 1)
          When necessary to settle fees for service provision. 2) When it is
          necessary for statistical compilation, academic research, or market
          research, the information is processed and provided in a form that
          does not identify a specific individual. 3) When there are special
          provisions in laws such as the Act on Real Name Financial Transactions
          and Guarantee of Confidentiality, the Act on Use and Protection of
          Credit Information, the Framework Act on Telecommunications, the
          Telecommunications Business Act, the Local Tax Act, the Consumer
          Protection Act, the Bank of Korea Act, and the Criminal Procedure Act.
        </p>
        <p className="mb-0">
           2. If there is an objection from a member related to mobile phone
          payment or a request is made by the mobile phone payment arbitration
          center for a similar matter, the company will determine whether the
          member has made a payment related to the service to the extent
          necessary to resolve the problem. The company can check with the
          arbitration center.
        </p>
        <p className="mb-3">
           3. Other matters related to personal information protection are
          subject to the company’s ‘privacy notice’.
        </p>
        <p className="mb-0 provisSubText"> 9. Obligations of the Company</p>
        <p className="mb-0">
           1. If overpayment occurs due to the company's intention or fault, the
          full amount of overpayment will be refunded. In this case, a refund
          will be made using the same method that the member used to pay. If a
          refund cannot be made using the same method, a full refund will be
          made after verifying the identity of the payer. However, if an
          overpayment occurs due to the member's intention or fault, the sponsor
          shall bear the costs incurred by the company in refunding the
          overpayment within a reasonable range.
        </p>
        <p className="mb-3">
           2. The company must perform the service normally to the extent that
          it does not fall under the disclaimer.
        </p>
        <p className="mb-0 provisSubText"> 10. Restrictions on Service Use</p>
        <p className="mb-3">
           1. If you engage in any of the following actions, the company may
          restrict your use of the service. In addition, the company may take
          civil damages compensation or other legal action in relation to this,
          and may take legal sanctions in accordance with relevant laws and
          regulations: 1) Hacking or other acts that put a strain on the system.
          2) Transferring or selling rights to paid services to a third party.
          3) An act of counterfeiting, altering, stealing, illegally acquiring,
          or using the payment method in payment. 4) Promotional content
          targeting an unspecified number of users or engaging in profit-making
          activities. 5) Refund abuse using loopholes in the refund policy
          system.
        </p>
        <p className="mb-0 provisSubText">
           11. Suspension of Service Provision
        </p>
        <p className="mb-0">
           1. The company may stop providing services in any of the following
          cases: 1) In unavoidable cases due to construction, such as repair of
          service facilities. 2) When the telecommunication business operator
          suspends telecommunication services during the period stipulated in
          the Telecommunications Business Act. 3) In case of other force majeure
          reasons.
        </p>
        <p className="mb-3">
           2. The company may restrict or suspend all or part of the service
          when normal service use is disrupted due to a national emergency,
          power outage, service facility failure, or surge in service use.
        </p>
        <p className="mb-0 provisSubText"> 12. Disclaimer</p>
        <p className="mb-0">
           1. The company is exempt from liability for service provision if
          service provision becomes difficult due to a natural disaster or other
          force majeure.
        </p>
        <p className="mb-0">
           2. The company is not responsible for any disruption in service use
          due to reasons attributable to the sponsor.
        </p>
        <p className="mb-3">
           3. The company is not responsible for any damage caused by data
          obtained by sponsors while using the service.
        </p>
        <p className="mb-0 provisSubText"> 13. Competent Court</p>
        <p className="mb-0">
           1. If there is a dispute between the company and the member regarding
          the use of the service, it must be resolved amicably through agreement
          between both parties.
        </p>
        <p className="mb-0">
           2. If a dispute is not resolved amicably and a lawsuit is filed, the
          competent court shall be the court following the procedures set forth
          in the relevant laws and regulations.
        </p>
        <p className="mb-3">
            3. The laws of the Republic of Korea apply to lawsuits filed between
          the company and users.
        </p>
        <p className="mb-0 provisSubText"> 14. Google API Policy</p>
        <p className="mb-0">
           1. The use and transfer of information received from Google APIs to
          other apps comply with the Google API Services User Data Policy,
          including acceptable use requirements.
        </p>
        <p className="mb-0 provisSubText"> 15. General Provisions</p>
        <p className="mb-0">
           Even if some of these terms and conditions are not enforceable by the
          court's ruling, the remaining terms are valid.   If we fail to enforce
          the Terms of Use or suspend the execution, we will not be deemed to
          waive the right to enforce these Terms of Use.   You may not transfer
          the rights and obligations prescribed in the Terms of Use to others
          without the written consent of the Company. However, in the event of a
          service sale or merger, we may transfer our rights and obligations to
          you without your consent.   These Terms and Conditions represent a
          complete and exclusive agreement between us and you regarding the
          Services. Replace all previously concluded contracts related to the
          service. We may amend these Terms and Conditions and, in our sole
          discretion, notify you by appropriate means, depending on the type of
          web page or notification, if any modifications are deemed material. If
          you continue to use the Service after the Terms and Conditions have
          been amended, you will be deemed to have accepted the Terms and
          Conditions after the change.   We will complete these Terms and
          Conditions in English and may be translated into other languages. Any
          discrepancy between the English version of this Terms of Use and any
          other language version will take precedence over the English version.
        </p>
        <p className="mb-0">
           1. &lt;Addendum&gt; These Terms of Use are effective as of December
          8, 2023.  
        </p>
      </div>
      <Footer termsLink="/termosfuseCr" />
    </div>
  );
};

export default ProvisionsDonEn;
