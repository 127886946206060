import { monthText, yearText } from "../common/publicText/PublicText";

/// 현재 년도 날짜를 구하는 함수
export const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return { year, month, day };
};

/// 하루 전 날짜를 구하는 함수
export const getLastDayDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return { year, month, day };
};

/// 한달전 날짜를 구하는 함수
export const getLastMonthDate = () => {
  const lastMonthDate = new Date();
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

  const year = lastMonthDate.getFullYear();
  const month = String(lastMonthDate.getMonth() + 1).padStart(2, "0");
  const day = String(lastMonthDate.getDate()).padStart(2, "0");

  return { year, month, day };
};
/// 일주일 전 날짜를 구하는 함수
export const getLastWeekDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return { year, month, day };
};
/// 3개월 전 날짜를 구하는 함수
export const getLastThreeMonthsDate = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 3);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return { year, month, day };
};
/// 1년 전 날짜를 구하는 함수
export const getLastYearDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return { year, month, day };
};

// 날짜 형식을 변경하는 함수
export const getFormattedCurrentDate = (dateObject) => {
  const { year, month, day } = dateObject;
  const formattedDate = `${year}-${month}-${day}T00:00:00+09:00`;
  return formattedDate;
};

export const getLastThreeMonths = () => {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const oneMonthAgo =
    new Date(date.setMonth(date.getMonth() - 1)).getMonth() + 1;
  const twoMonthsAgo =
    new Date(date.setMonth(date.getMonth() - 1)).getMonth() + 1;
  const threeMonthsAgo =
    new Date(date.setMonth(date.getMonth() - 1)).getMonth() + 1;

  return {
    one: String(oneMonthAgo).padStart(2, "0"),
    two: String(twoMonthsAgo).padStart(2, "0"),
    three: String(threeMonthsAgo).padStart(2, "0"),
  };
};

// 데이터를 변환하는 함수
export function convertDateFormat(dateString) {
  // "2024 년6 월" -> ["2024", "년6", "월"]
  const [year, monthWithChar] = dateString.split(" ");
  // "년6" -> "6"
  const month = monthWithChar
    .replace(`${yearText}`, "")
    .replace(`${monthText}`, "")
    .trim()
    .padStart(2, "0");
  const day = "01"; // 일자를 "01"로 고정

  // 새로운 형식으로 변환
  return `${year}-${month}-${day}`;
}

// date 변환 함수: "2024-06-10T05:52:49" -> "2024.06"
export function ConfirmationFormatDate(dateString) {
  const [year, month] = dateString.split("T")[0].split("-");
  return `${year}.${month}`;
}

// writeDate 변환 함수: "2024-06-10T05:52:49" -> "2024.06.10"
export function ConfirmationFormatWriteDate(dateString) {
  const [year, month, day] = dateString.split("T")[0].split("-");
  return `${year}.${month}.${day}`;
}

export function parseDateString(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1 필요
  const day = String(date.getDate()).padStart(2, "0");

  return { year, month, day };
}
