import React, {
  ReactChild,
  ReactFragment,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from "react";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { postContentChange } from "../Renewal/repo/ContentChange";
import {
  profileDataSaveFailText,
  profileDateSaveSuccessText,
} from "../Renewal/text/ReModifyText";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Table from "quill-table";
Quill.register({
  "modules/table": Table,
});

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["12px", "13px", "14px", "small", "large", "huge"];
Quill.register(Size, true);
const formats = [
  "font",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "align",
  "color",
  "background",
  "size",
  "h1",
];

export default function QuillEditor() {
  const { t } = useTranslation();

  const [values, setValues] = useState();

  const handleSubmit = async () => {
    try {
      const resp = postContentChange(values);

      if (resp === "Fail") {
        Swal.fire({
          title: `${profileDataSaveFailText}`,
          icon: "error",
        });
        console.log("프로필 수정 실패");
        return;
      } else {
        Swal.fire({
          title: `${profileDateSaveSuccessText}`,
          icon: "success",
        });
        // dispatch(setContentAction(editorData));
      }
    } catch (error) {}
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          [{ align: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          [
            {
              color: [],
            },
            { background: [] },
          ],
        ],
      },
    };
  }, []);

  return (
    <div>
      <div className="textEditor editor-class">
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          onChange={setValues}
          style={{ height: `400px` }}
        />
      </div>
      <div className="editorSave">
        <button
          className="btn btn-primary"
          style={{
            height: `3rem`,
            width: `120px`,
            fontWeight: `700`,
            fontSize: `18px`,
          }}
          onClick={handleSubmit}
        >
          {t("modifyingText")}
        </button>
      </div>
    </div>
  );
}
