export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";

export const setActionSearchText = (text) => ({
  type: SET_SEARCH_TEXT,
  payload: text,
});

export const setSearchValueSelected = (index) => ({
  type: SET_SEARCH_VALUE,
  payload: index,
});
