import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/povisionsDon.css";
import Footer from "../../Footer/screen/Footer";
const ProvidsionsCr = () => {
  return (
    <div>
      <div className="provisionsText">
        <p className="mb-3 Pr-Title"> 후원한잔 이용약관</p>
        <p className="mb-0 provisSubText">제 1조 목적 </p>
        <p className="mb-3">
          {" "}
           본 약관은 크리에이터(블로거, 유튜버, 방송진행자, 스트리머 등 이하
          회원이라고 합니다.)가 주식회사 엑스알에이아이(이하 회사라 합니다.)가
          인터넷에서 제공하는 “후원한잔 서비스”를 이용함에 있어 회사와 회원간의
          권리, 의무 및 책임사항을 규정하기 위함을 목적으로 합니다.
        </p>
        <p className="mb-0 provisSubText">제 2조 약관의 명시, 효력 및 개정 </p>
        <p className="mb-0">
           1. 본 약관은 회원이 쉽게 알 수 있도록 서비스 가입화면에 게시하며, 본
          서비스를 사용하고자 하는 회원에 대하여 그 효력을 발생합니다.{" "}
        </p>
        <p className="mb-0">
           2. 회사는 본 약관을 “약관의 구제에 관한 법률”, “정보통신망 이용 촉진
          및 정보보호 등에 관한 법률”, 전자상거래에서 “소비자 보호 관련 법률”,
          “콘텐츠 산업 진흥법” 등 관련 법령을 위배하지 않는 범위에서 본 약관을
          개정할 수 있습니다.{" "}
        </p>
        <p className="mb-0">
           3. 회사가 약관을 개정하는 경우 개정된 약관 내용과 시행일을 정하여
          시행일로부터 최소 7일 이전부터 시행일 이후 상당기간 서비스 페이지에
          공지하고 회원에게 불리한 약관 개정일 경웅 30일 전부터 공지합니다. 기존
          회원에게는 개정된 약관, 적용일자 및 사유를 기존 회원에게 별도로
          공지합니다.
        </p>
        <p className="mb-3">
           4. 회원은 공지일로부터 개정된 약관 시행일 7일 후까지 거부의사를
          표하지 않을 경우 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도
          불구하고 거부 의사표시를 하지 않는 경우 개정약관에 승인한 것으로
          간주합니다. 회원이 개정약관에 동의하지 않은 경우 회원은 이용계약을
          해지 할 수 있습니다.
        </p>
        <p className="mb-0 provisSubText">제 3조 약관 해석 및 적용</p>
        <p className="mb-0">
           1. 본 약관의 계약에 명시되지 않는 사항에 대해서는 별도의 세부 약관,
          회사 공지, 이용안내, 관계법령에서 정한 바에 따릅니다.{" "}
        </p>
        <p className="mb-3">
           2. 회원이 회사와 개별계약을 서비스를 체결하여 서비스 이용시
          개별계약이 본 약관보다 우선됩니다.
        </p>
        <p className="mb-0 provisSubText">제 4조 용어 정의 </p>
        <p className="mb-0">
           1. “후원한잔 서비스”(이하 “서비스”라고 합니다.)는 회원이 회사가
          운영하는 사이트를 통해 회원이 운영하는 블로그, 유튜브, 기타 방송 채널
          등에 참여하는 불특정 다수의 후원자, 시청자로부터 받은 후원금을 회원이
          수취할 수 있도록 제공하는 서비스를 말합니다.{" "}
        </p>
        <p className="mb-0">
           2. “후원 금액”은 회원에게 참여자 혹은 시청자가 직접 결제하여 후원한
          금액을 말합니다.
        </p>
        <p className="mb-0">
           3. “서비스 수수료”는 회사가 회원에게 제공하는 서비스에 대한 대가를
          말합니다.
        </p>
        <p className="mb-3">
           4. “정산 금액”은 “후원 금액”에서 서비스 수수료 및 결제 대행사
          수수료를 차감한 후 회사가 회원에게 지급하는 금액을 말합니다. 5.
          “결제기관”은 통신사, 신용카드사, 선불결제수단의 발행기관등을 말하며,
          “결제대행사”는 해당 결제기관과 회사 사이에서 결제업무를 대행하는 기관
          혹은 회사를 뜻합니다.
        </p>
        <p className="mb-0 provisSubText">
          제 5조 결제서비스의 내용과 회사와 회원의 의무 등
        </p>
        <p className="mb-0">
           1. 회사는 회원에게 서비스를 상시 제공함을 원칙으로 합니다.{" "}
        </p>
        <p className="mb-0">
           2. 회사의 의무는 아래와 같습니다. 1) OOO 서비스 시스템 구축, 개선 및
          시스템 연동 등 서비스를 상시제공하기 위한 기술적 조치입니다. 단, 다음
          각 호의 사유 발생시 예고 없이 서비스의 일부 또는 전부 제공이 일시
          중단될 수 있으며 해당 내용은 회원에게 사후공지됩니다. 가) 회사가
          긴급한 시스템 점검, 증설, 교체, 보수 또는 공사가 필요하여 부득이한
          경우 나) 새로운 서비스 시스템 도입으로 회사가 필요하다고 판단되는 경우
          다) 기타 서비스 설비의 장애, 네트워크 장애등으로 서비스 제공이 불가할
          경우 라) 천재지변, 국가비상사태 등 회사가 통제 불가능한 불가항력적
          사유로 인한 경우 2) 회사의 시스템 장애 발생시 신속한 복구 3) 회원의
          후원금액에 대한 정산
        </p>
        <p className="mb-0">
           3. 회원의 의무는 아래와 같습니다. 1) 회사가 제공하는 서비스 수수료
          지급 2) 회원은 서비스 이용을 위한 회원가입 진행시 사실에 근거하여
          작성해야 합니다. 회원이 허위 또는 타인의 정보를 이용하여 회원가입한
          경우 회사에 대하여 일체 권리를 주장할 수 없으며, 이로 인해 발생하는
          손해는 회사에서 책임지지 않습니다. 3) 회사가 제공하는 방법 혹은 별도
          계약한 제 3자의 위탁서비스를 통한 정산정보(이름, 계좌번호 등) 등록 4)
          청소년 보호법 등 관계법령 준수
        </p>
        <p className="mb-0">
           4. 회원은 아래와 같은 방법으로 후원을 받거나 유도해서는 안됩니다. 1)
          특정 상품 판매를 목적으로 후원금을 받거나, 유도하는 경우 2) 후원한
          사람에게 정산 후 금액을 다시 돌려주는 목적으로 후원 받는 경우 3) 기타
          현행 법령상 위법 사유를 포함한 컨텐츠를 활용하여 후원금을 받거나
          유도하는 경우
        </p>
        <p className="mb-3">
           5. 전항의 사유가 발생하는 경우, 회사는 아래와 같은 조치를 취할 수
          있습니다. 1) 본 계약 해지 2) 전 항의 사유로 발생한 모든 거래의 거래
          취소 및 결제금액 환불 3) 본 계약 정산 조항과 관계없이 정산금액 지급의
          보류 4) 전 항의 사유로 인하여 회사 손해 발생 시 회원에게 손해액 배상
          청구
        </p>
        <p className="mb-0 provisSubText">제 6조 거래 취소 및 결제금액 환불 </p>
        <p className="mb-0">
           1. 회원의 컨텐츠를 접한 후원자가 후원금 취소 또는 환불을 요청할 경우
          회원이 응대 및 처리 하는 것을 원칙으로 합니다. 다만 회사와 회원의 별도
          협의가 있거나, 관계법령 또는 정부기관 및 이에 준하는 제 3자의 명령이
          있는 예외적인 경우 회사가 처리할 수 있습니다.
        </p>
        <p className="mb-0">
           2. 회원의 컨텐츠를 접한 후원자가 회사에 직접 또는 결제기관 또는 관계
          기관 및 협회를 통해 클레임(후원자 본인이 아닌 제 3자가 결제정보를
          도용하여 이루어지는 부정거래 관련 클레임 포함)을 제기하는 경우 회사는
          아래와 같은 조치를 취할 수 있습니다. 1) 클레임 관련 결제 내역자료 제공
          2) 클레임 관련금액 상당액 정산금액 지급 보류 3) 결제서비스 제공 일시
          정지 또는 계약 해지 4) 클레임 관련된 결제의 거래 취소 또는 결제금액
          환불
        </p>
        <p className="mb-3">
           3. 후원자의 결제금액 환불이 이루어지는 경우 회사는 해당 금액 환불에
          대하여 회원이 회사로부터 지급받아야 하는 정산금액에서 차감할 수
          있습니다. 단, 해당 서비스에 대해 별도의 계약이 존재하는 경우 해당 계약
          내용을 우선하여 따릅니다.
        </p>
        <p className="mb-0 provisSubText">제 7조 서비스 수수료 </p>
        <p className="mb-0">
           1. 본 계약에 따른 서비스 수수료, 정산 및 정산 금액의 지급 방법 등은
          아래 항에서 정하기로 합니다. 단, 회사는 매출액의 증감, 결제기관의 정책
          등에 따라 서비스 수수료를 조정할 수 있습니다.{" "}
        </p>
        <p className="mb-0">
           2. 정산 대상 기간은 매월 1일부터 말일 까지로 하여 1개월 단위로 정산을
          진행합니다. 단, 회사 및 결제 대행사의 사정에 의하여 정산 대상 기간을
          달리 정하여야 하는 경우에는 회사 및 결제기관의 정책에 따릅니다.
        </p>
        <p className="mb-0">
           3. 회원은 후원받은 이후 처음 도래하는 월 말까지 회사가 제공하는
          절차를 통하여 정산 정보 등록(정산 계약)을 해야 하며, 등록하지 않은
          경우 정산을 진행할 수 없습니다.
        </p>
        <p className="mb-0">
           4. 회사는 회원이 정산 받을 금액이 4만원 미만일 경우 정산을 유예하고
          다음 정산 금액에 합산하여 정산할 수 있습니다.
        </p>
        <p className="mb-0">
           5. 회사와 회원이 각각 보유한 회원의 후원 받은 내역이 상이할 경우,
          회사와 회원은 상호 보유한 자료를 비교하여 정산 금액을 합의할 수 있고,
          결제가 이루어진 결제대행사의 정보를 참고할 수 있습니다. 합의가
          지연되는 경우에는 회사가 양 당사자가 주장하는 정산금액 중 적은 금액을
          기준으로 정산을 실행할 수 있습니다. 이 경우 합의 시까지 회사는
          정산금액 지급 연체에 대한 책임을 면합니다.
        </p>
        <p className="mb-0">
           6. 회사는 회원이 요청하는 경우 정산과 관련된 자료를 회원에게
          제공하여야 합니다.
        </p>
        <p className="mb-0">
           7. 정산 대상 기간에 발생한 정산금액 지급기일은 다음 달 영업일 기준
          10일 이내에 진행됩니다. 단, 요청 서류 누락, 계좌번호 오기입 등 회원의
          귀책사유로 정산 금의 지급이 실패한 경우 해당 사유가 해소된 날부터
          영업일 기준 15일 이내 지급합니다.
        </p>
        <p className="mb-0">
           8. 회사는 정산금액의 지급기일에 정산금액(후원받은 금액에서 서비스
          수수료, 결제대행사 수수료, 소득세 등 세금을 공제한 금원)을 회원이
          지정한 계좌에 이체합니다.
        </p>
        <p className="mb-0">
           9. 회원이 병역 근무 중인 경우, 병무청의 별도 요청이 있을 시 병역 기간
          중 후원금의 정산이 제한될 수 있습니다.
        </p>
        <p className="mb-0">
           10. 회사는 도용 등 불법적인 사용에 의한 결제로 후원된 금액에 대해서는
          그 내용을 전자우편, 전화 등의 방법으로 통보 후 회원의 정산 금액에서
          제외할 수 있습니다. 또, 불법적인 방법으로 취득 혹은 취득하였다고
          의심되는 후원 금액의 경우 사전 안내 없이 후원 금액의 정산을 취소,
          의심되는 상황에 대한 회원의 소명이 종료될 때까지 지급을 거부 또는
          기지급된 정산금액을 회수할 수 있습니다.
        </p>
        <p className="mb-0">
           11. 회우너은 원활한 정산처리를 위해 회사가 별도의 공지사항 또는
          이용안내로 고지한 방법을 통해 계좌 정보 등 정산금 지급에 필요한 정보
          기재 후 일정한 방법을 통해 본인인증 혹은 전자서명을 진행하여 정산 등록
          신청을 하여야 합니다. 해당 절차를 모두 완료하고 승인된 경우 회사와
          회원은 정산계약을 체결한 것으로 간주합니다.
        </p>
        <p className="mb-0">
           12. 회원이 미성년자 이거나 한정치산자인 경우 법정대리인(부모 등)의
          동의를 받아 정산 계약을 할 수 있으며, 회사가 지정 및 안내하는 방법에
          따라 이용 동의를 하여야 합니다. 그렇지 않을 경우 회사는 회원과의
          계약을 취소할 수 있습니다.
        </p>
        <p className="mb-3">
           13. 후원금에 대한 정산을 최종 이용일로부터 서비스 이용내역(접속이력)
          없이 60개월이 경과한 경우 후원금은 자동 소멸됩니다.
        </p>
        <p className="mb-0 provisSubText">제 8조 계약 기간</p>
        <p className="mb-3">
           본 계약은 체결일로부터 회원의 회원탈퇴 시까지 유효합니다. 단, 회원
          당사자가 본 계약의 해지 의사가 있음에도 직접 본인 의사를 표하지 못할
          경우 법정대리인이 대신하여 회원과 회사의 계약을 해지할 수 있습니다.
        </p>{" "}
        <p className="mb-0 provisSubText">제 9조 회원에 대한 통지 </p>
        <p className="mb-0">
           1. 회사는 회원이 제공한 이메일, SMS 등을 통해 회원에게 통지할 수
          있습니다.
        </p>
        <p className="mb-0">
           2. 회사는 불특정 다수 회원에 대한 통지의 경우 게시판 공지 또는 팝업
          공지를 통해 개별 통지에 갈음할 수 있습니다.{" "}
        </p>
        <p className="mb-3">
           3. 회사가 위와 같이 정상적으로 통지하였음에도, 회원이 통지된 내용을
          확인하지 않음으로 회원에게 발생한 불이익에 대해 회사는 책임지지
          않습니다.
        </p>
        <p className="mb-0 provisSubText">제 10조 서비스 이용제한 </p>
        <p className="mb-0">
           1. 회사는 아래 각 호에 해당되는 경우 회원에 대하여 서비스 제공을
          제한하거나 중단할 수 있습니다. 1) 회사와 회원간 서비스 제공과 관련된
          약정이 종료된 경우 2) 회원이 본 약관에서 정한 의무사항을 위반하여
          서비스의 정상적인 운영을 방해한 경우 3) 회원이 타인의 명의를
          도용하거나 정보를 잘못 입력하여 회원가입한 경우 4) 유료결제를 함에
          있어 타인 명의 결제 도용, 전화번호 도용 등 불법적인 결제를 진행했을
          경우 5) 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법
          통신 및 해킹, 악성 프로그램 배포, 접속 권한 초과행위 등과 같이 회원이
          관련 법령을 위반한 경우 6) 회원이 계속해서 1년동안 접속하지 않아 휴면
          게정으로 전환된 경우 7) 회원간 또는 회사와 회원간 분쟁이 발생하여
          분쟁이 해결되지 않은 경우{" "}
        </p>
        <p className="mb-0">
           2. 본 조 제1항에 따라 서비스 이용이 제한되는 경우 회사는 회원이
          획득한 혜택과 보유한 권리 등을 소멸시킬 수 있으며, 회사는 이에 대해
          별도로 보상하지 않습니다. 다만, 회원이 본 약관이나 관련법령에 위반하지
          않고 취득한 후원금은 정상적으로 정산하여 지급됩니다.
        </p>
        <p className="mb-3">
           3. 본 조 제1항에 따라 서비스 이용이 제한 또는 중지된 회원에게
          통지로써 회원자격을 상실 또는 1년동안 정지시킬 수 있습니다.
        </p>
        <p className="mb-0 provisSubText">제 11조 계약 해제, 해지</p>
        <p className="mb-0">
           1. 회사 또는 회원은 다음 각 호에 해당하는 경우 본 계약을 해지할 수
          있습니다. 1) 회원이 본 약관에서 정한 회원의 의무를 위반하거나 서비스
          이용 제한 사유에 해당하는 행위를 하는 경우 회사는 시정요구를
          요청합니다. 회원이 영업일 기준 7일 이내에 위반사항을 시정하지 않은
          경우 계약이 해지 될 수 있습니다. 단, 특정 제한사유를 행위했을 시
          시정요구없이 계약해지가 진행될 수 있습니다. 2) 회사 또는 회원의 주요
          재산에 대한 보전처분 결정, 강제집행, 체납절차, 회생절차, 파산절차 및
          이에 준하는 법정절차가 개시되어 더 이상 계약 유지가 곤란한 경우 3)
          회사 또는 회원의 업무 관련 인허가 미비 등의 법률상 하자가 있는 경우 4)
          기타 본 계약을 유지하기 어려운 중대한 사유가 발생하는 경우
        </p>
        <p className="mb-3">
           2. 본 조의 해지는 이미 발생한 청구권 및 손해배상청구에 영향을 미치지
          아니합니다.
        </p>
        <p className="mb-0 provisSubText">제 12조 </p>
        <p className="mb-3">
           양도 등 금지 회사와 회원은 상대방의 사전 서면에 대한 동의 없이는 본
          계약상의 권리 또는 의무를 제 3자에게 양도하거나 담보로 제공할 수
          없습니다.
        </p>
        <p className="mb-0 provisSubText">
          제 13조 개인정보 수집, 이용 및 보호의무
        </p>
        <p className="mb-0">
           1. 회사는 서비스의 원활한 제공을 위해 개인정보를 수집 및 이용 합니다.
          이를 위해서 회사는 회원에게 별도 동의를 구합니다.{" "}
        </p>
        <p className="mb-3">
           2. 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의
          개인정보를 보호하기 위해 노력합니다. 개인정보의 수집, 보호 및 사용에
          대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.
        </p>
        <p className="mb-0 provisSubText">
          제 14조 서비스 관리 책임 회사의 서비스 관리 책임자는 다음과 같습니다.
        </p>
        <p className="mb-3">
           1. 성명 : 이승석 2. 직위 : 이사 3. 연락처 : 010-3261-9683
        </p>
        <p className="mb-0 provisSubText">제 15조 저작권의 귀속 및 이용제한 </p>
        <p className="mb-0">
           1. 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에
          귀속됩니다.{" "}
        </p>
        <p className="mb-3">
           2. 회원은 서비스 이용과정에서 얻은 정보를 회사의 사전 승낙 없이 복제,
          송신, 출판, 배포, 방송기타 방법에 의하여 영리목적으로 이용하거나 제
          3자가 이용하도록 하여서는 안됩니다.
        </p>
        <p className="mb-0 provisSubText">제 16조 게시물 권리/관리 </p>
        <p className="mb-0">
           1. 회원은 서비스에 제공한 회원의 게시물과 관련하여 저작권, 인격권 등
          제 3자와의 분쟁 발생 시 그 법적대응 및결과에 대한 책임을 지며 회사는
          해당 게시물과 관련된 일체 책임을지지 않습니다.
        </p>
        <p className="mb-0">
           2. 회원의 게시물이 정보통신망법 및 저작권법 등 기타 관련 법령에
          위반되는 내용을 포함하는 경우 권리자는 관련 법령이 정한 절차에 따라
          해당 게시물의 게시 중단 및 삭제 등을 요청할 수 있으며, 회사는 관련
          법령에 따라 조치를 취해야 합니다.
        </p>
        <p className="mb-0">
           3. 회원의 게시물에 문제가 있다고 판단되는 경우, 회사는 회원에게
          사전통지 없이 삭제하거나, 이동, 등록 거부할 수 있습니다.
        </p>
        <p className="mb-3">
           4. 회원이 게시한 게시물은 회사의 서비스 및 관련 프로모션에 노출될 수
          있으며, 해당 노출을 위해 필요한 범위 내에서는 수정, 복제, 편집이
          진행되어 게시될 수 있습니다. 이 경우 회사는 저작권법을 준수하며 회원은
          언제든지 회사에게 해당 게시물에 대한 삭제, 검색결과제외, 비공개 등의
          조치를 취하도록 요청할 수 있습니다.
        </p>
        <p className="mb-0 provisSubText">제 17조</p>
        <p className="mb-3">
           Google API 정책 Google API에서 받은 정보를 사용하고 다른 앱으로
          전송하는 것은 제한적 사용 요구 사항을 포함하여 Google API 서비스
          사용자 데이터 정책을 준수합니다.
        </p>{" "}
        <p className="mb-0 provisSubText">제 18조 서비스 종료</p>
        <p className="mb-0">
           1. 회사가 서비스를 종료하고자 할 경우 서비스를 종료하고자 하는
          날로부터 3개월 이전에 회원에게 개별 혹은 전체 공지합니다.{" "}
        </p>
        <p className="mb-3">
           2. 서비스가 종료될 경우 회사는 관계법령에 따라 보유하고 있는
          개인정보를 파기합니다.
        </p>
        <p className="mb-0 provisSubText">제 19조 관할법원 및 준거법</p>
        <p className="mb-0">
           1. 서비스 이용시 이용에 관하여 회사와 회원간 분쟁이 생길 시 당사자간
          원만한 해결이 이루어져야 합니다.
        </p>
        <p className="mb-0">
           2. 분쟁이 원만히 해결되지 않을 경우 소송이 제기된 경우 소송은 관련
          법령에 따른 법원을 관할 법원으로 합니다.
        </p>
        <p className="mb-3">
           3. 회사와 이용자간 제기된 소송에서는 대한민국 법을 적용합니다.
          &lt;부칙&gt; 본 이용약관은 2023년 12월 06일부터 적용됩니다.
        </p>
      </div>
      <Footer termsLink="/termosfuseCr" />
    </div>
  );
};

export default ProvidsionsCr;
