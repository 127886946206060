import "./App.css";
import "./home/login/css/login_screen.css";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ProvidsionsCr from "./common/agreement/screen/ProvisionsCr";
import PersonalInfoCr from "./common/agreement/screen/PersonalInfoCr";
import DontaionPage from "./home/DonationPage/screen/DontaionScreen";
import NotFoundPage from "./home/NotFound/screen/NotFoundPage";
import ProvisionsDon from "./common/agreement/screen/ProvisionsDon";
import PayMentPage from "./home/paymentPage/screen/PaymentPage";

import ErrorBoundaryWithNavigate from "./common/utils/ErrorBoundaryNave";
import ReLoginPage from "./home/login/Renewal/screen/ReLoginPage";
import ReMyPage from "./home/MyPage/Renewal/screen/ReMyPage";
import { ReCreateButtonPage } from "./home/CreateButton/Renewal/screen/ReCreateButton";
import { CalculatePage } from "./home/Calculate/screen/CalculatePage";
import { ReSignUpPage } from "./home/SignUp/Renewal/screen/ReSignUp";
import { ReModifyPage } from "./home/Modifying/Renewal/screen/ReModifyScreen";
import { FindingPasswordPage } from "./home/Finding/screen/FindingPassword";
import { ReDontaionPage } from "./home/DonationPage/Renewal/ReDontaionPage";
import { NavigationProvider } from "./util/NavigateionProvider";
import { KakaoRedurectURL } from "./home/login/components/kakaoLoginCallBaack";
import { ConfirmationScreen } from "./home/Confirmation/screen/ConfirmationScreen";
import { AuthGuard } from "./common/authGuard/AuthGuard";
import LanguageGuard from "./util/LanguageGuard";
import i18n from "./i18n";
import ProvidsionsCrEn from "./common/agreement/screen/ProvisionsCrEn";
import PersonalInfoCrEn from "./common/agreement/screen/PersonallnfoCrEn";
import ProvisionsDonEn from "./common/agreement/screen/ProvisionsDonEn";
import ProtectedRoute from "./util/ProtectedRoute";
import { DonationRenewalPage } from "./home/DonationPage/Renewal/DontaionRenewal";

function App() {
  const [i18nInitialized, setI18nInitialized] = useState(false);

  useEffect(() => {
    i18n.on("initialized", () => {
      setI18nInitialized(true);
    });
  }, []);
  if (!i18nInitialized) {
    return <div></div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <NavigationProvider>
          <ErrorBoundaryWithNavigate>
            <AuthGuard>
              <Routes>
                <Route path="/" element={<Navigate to="/renewalLogin" />} />
                <Route path="/mypage/*" element={<NotFoundPage />} />
                {/* <Route path="/d/:uuid" element={<DontaionPage />} /> */}
                <Route path="/d/:uuid" element={<DonationRenewalPage />} />

                <Route path="/not-found" element={<NotFoundPage />} />
                <Route path="/termosfuse" element={<ProvisionsDon />} />
                <Route path="/termosfuseEn" element={<ProvisionsDonEn />} />

                <Route path="/termosfuseCr" element={<ProvidsionsCr />} />
                <Route path="/termosfuseCrEn" element={<ProvidsionsCrEn />} />
                <Route path="/personalInfo" element={<PersonalInfoCr />} />
                <Route path="/personalInfoEn" element={<PersonalInfoCrEn />} />

                <Route path="/payment" element={<PayMentPage />} />

                <Route path="/kakaoLogin" element={<KakaoRedurectURL />} />
                <Route
                  path="/Confirmation"
                  element={<ProtectedRoute element={ConfirmationScreen} />}
                />
                <Route path="/renewalLogin" element={<ReLoginPage />} />
                <Route path="/ReSignUpPage" element={<ReSignUpPage />} />
                <Route path="/FindPassword" element={<FindingPasswordPage />} />
                <Route
                  path="/ReModifyPage"
                  element={<ProtectedRoute element={ReModifyPage} />}
                />
                <Route
                  path="/ReMyPage"
                  element={<ProtectedRoute element={ReMyPage} />}
                />
                <Route
                  path="/reCreateButton"
                  element={<ProtectedRoute element={ReCreateButtonPage} />}
                />
                <Route
                  path="/CalculatePage"
                  element={<ProtectedRoute element={CalculatePage} />}
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </AuthGuard>
          </ErrorBoundaryWithNavigate>
        </NavigationProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
