import axios from "axios";
import api from "../../../Repository/Interceptor";
import { API_URL } from "../../../Repository/URL";
import { API_KEY } from "../../../util/const";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const PayPalCreateRepo = async ({ money, memo, name, token }) => {
  try {
    const baseURL = API_URL;
    const payToken = token;

    const requestBody = {
      money: money,
    };

    if (memo) {
      requestBody.memo = memo;
    }
    if (name) {
      requestBody.name = name;
    }

    const resp = await axios.post(`${baseURL}/paypal/orders`, requestBody, {
      headers: {
        Authorization: `Bearer ${payToken}`,
        "API-Key": API_KEY,
      },
    });
    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("페이팔 생성 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
