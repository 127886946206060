import { ScrollTextTitle } from "../css/ScrillableDiv";
import { confirmationApplicationText } from "./CalculateText";
import { confirmationText, confirmationText2 } from "./consentText";

export const Confirmation = () => {
  return (
    <div>
      <ScrollTextTitle className="mb-3">
        정산 등록 신청서 제출에 대한 확인
      </ScrollTextTitle>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0">
         본인은 ㈜엑스알에이아이의 서비스인 후원한잔의 크리에이터로써 등록된
        회원이며 본인의 후원한잔 계정에 적립된 수익금을 정산받고자 후원한잔
        크리에이터 이용약관에 따라 위와 같이 정산등록신청서를 제출합니다.;
      </p>
      <p className="mb-0">
         위 내용은 모두 사실이고 향후 위 신고된 계좌 혹은 지급방식으로의 지급은
        문제가 없고 적절한 것으로 인정하며, 이와 관련하여 발생된 손실에 대해서는
        이의를 제기하거나 배상을 청구하지 않을 것을 약정합니다.;
      </p>
    </div>
  );
};
