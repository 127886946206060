import { ScrollTextTitle } from "../css/ScrillableDiv";

export const InformedConsentEn = () => {
  return (
    <div>
      <ScrollTextTitle className="mb-3">
        Notice and Consent Regarding Use of Hanzan Services
      </ScrollTextTitle>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0">
         During the use of the Hanzan service, the following situations may lead
        to suspension of service without prior notice from the company
      </p>
      <p className="mb-0" style={{ marginTop: `10px`, marginLeft: `20px` }}>
         ① If a creator using Hanzan is discovered or confirmed to be violating
        the Terms of Use or engaging in illegal content.
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ② If found engaging in activities that violate current laws and
        regulations, including the Information and Communications Act.
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ③ Discovery of content that compromises the dignity or quality of the
        Hanzan service, including illegal acts such as pornography, socially
        controversial content, or defamation.
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ④ Violation of terms and conditions of other platforms supported by
        Hanzan.
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ⑤ If a legal issue not mentioned above arises or an act detrimental to
        the Sponsorship Service is determined.
      </p>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0">
         If any of the above cases apply during the use of the Hanzan service,
        service usage may be suspended. In cases of clear violation of current
        laws or illegal use, settlement processing may be suspended or denied.
      </p>
    </div>
  );
};
