import styled, { keyframes } from "styled-components";

export const rotate = keyframes`
from {
  transform: translate(-50%, -50%) rotate(45deg);
}
to {
  transform: translate(-50%, -50%) rotate(405deg); /* 360 + 45 = 405 */
}
`;

export const BackgroundStyledDiv = styled.div`
  background-color: rgba(166, 173, 185, 0.2);
  overflow: hidden;
  position: relative;
  height: 100%;

  @media (max-width: 1770px) {
    overflow-x: auto; /* 좌우 스크롤을 허용합니다 */
    min-width: 1770px;
    height: 100%;
  }
`;

export const RotatingLine = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: 1;
  animation: ${rotate} 10s linear infinite;
`;

export const DiagonalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -70%) rotate(45deg);
  z-index: -1;
`;

export const DiagonalBox2 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
`;

export const DiagonalBox3 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -70%) rotate(45deg);
  z-index: -1;
`;

export const TotalText = styled.span`
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-top: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

export const StringText = styled.span`
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: bold;
  color: black;
  margin-top: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

export const CoinDivBox = styled.div`
  height: 20rem;
  background-color: white;
  width: 30%;
  border-radius: 8px;
  padding: 10px;
  overflow: auto;
  /* flex-direction: column; */
  /* justify-content: center; */
`;
export const CoinCard = styled.div`
  width: 8rem;
  padding: 8px;
  height: 92%;
  margin-left: 20px;
  border: 2px solid rgba(166, 173, 185, 0.3);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: "hidden";
  border-radius: 8px;
  cursor: pointer;
`;

export const TotalCoinCard = styled.div`
  /* width: 15rem; */
  /* height: 92%; */
  /* margin-left: 0px; */
  /* border: 2px solid rgba(166, 173, 185, 0.3); */
  /* margin-top: 0.5rem; */
  /* margin-bottom: 0.5rem; */
  /* overflow: "hidden"; */
  border-radius: 8px;
  display: flex;
  justify-content: center;
`;

export const TopMarginDiv = styled.div`
  margin: 0rem 20rem 0rem 20rem;
`;

export const TopSpaceDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexBoxDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProfileBorderBoxDiv = styled.div`
  height: 25rem;
  width: 70%;
  border-radius: 16px;
  display: flex;
`;

export const ProfileBorderBox2Div = styled.div`
  margin: 1rem 0.5rem 1rem 1rem;
  border-radius: 8px;
  width: 30%;
  background-color: rgba(255, 255, 255, 0.7);
  position: relative;
  overflow: hidden;
`;

export const DonateFlexCenterColumnBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const DonateFlexCenterColumnBox2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileBorderBox3Div = styled.div`
  margin: 0.3rem;
  background-color: white;
  border-radius: 8px;
  height: 98%;
  position: relative;
  z-index: 2;
`;

export const ProfileBorderBox4Div = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ProfileImgBox = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ProfileLogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
`;

export const HtmlCodeBox = styled.div`
  margin: 1rem 1rem 1rem 0rem;
  border-radius: 8px;
  width: 70%;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  position: relative;
  overflow: hidden;
`;
export const HtmlCodeInBox = styled.div`
  margin: 0.3rem;
  background-color: white;
  height: 98%;
  border-radius: 8px;
  padding: 10px;
`;

export const InputTextBox = styled.input`
  width: 10rem;
  border: none; /* 모든 테두리 제거 */
  /* border-bottom: 1px solid #000; 아래쪽 테두리만 추가 */
  font-size: 1rem; /* 글꼴 크기 */
  font-weight: bold; /* 글꼴 두께 */
  color: black; /* 글꼴 색상 */
  margin-top: 10px; /* 상단 마진 */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* 텍스트 그림자 */
  background-color: transparent; /* 배경 투명하게 */

  &::placeholder {
    color: #000; /* 플레이스홀더 색상 */
  }

  &:focus {
    outline: none; /* 포커스 시 테두리 제거 */
    caret-color: #000; /* 깜빡이는 커서 색상 */
  }
`;

export const SubInputTextBox = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.9rem;
  height: 1.5rem;
  border: none;
  /* border-bottom: 1px solid #000; 아래쪽 테두리만 추가 */
  font-size: 1rem; /* 글꼴 크기 */
  font-weight: bold; /* 글꼴 두께 */
  color: black; /* 글꼴 색상 */
  margin-top: 10px; /* 상단 마진 */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* 텍스트 그림자 */
  background-color: transparent; /* 배경 투명하게 */

  &::placeholder {
    font-size: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #000; /* 플레이스홀더 색상 */
  }

  &:focus {
    outline: none; /* 포커스 시 테두리 제거 */
    caret-color: #000; /* 깜빡이는 커서 색상 */
  }
`;

export const TryBox = styled.div`
  position: absolute;
  top: 55%;
  left: 55%;
  width: 0;
  height: 0;
  border-left: 40px solid transparent; /* 왼쪽 경계 */
  border-right: 40px solid transparent; /* 오른쪽 경계 */
  border-bottom: 2000px solid rgba(0, 0, 0, 0.3); /* 아래쪽 경계: 길이와 색상 */
  transform: translate(-50%, -50%) rotate(-55deg); /* 중앙에 위치시키고 회전 */
  z-index: -1;
`;

export const TryBox2 = styled.div`
  position: absolute;
  top: 40%;
  left: 0%;
  width: 0;
  height: 0;
  border-left: 50px solid transparent; /* 왼쪽 경계 */
  border-right: 50px solid transparent; /* 오른쪽 경계 */
  border-bottom: 2000px solid rgba(255, 80, 80, 1); /* 삼각형의 높이를 길게 설정하고, 색상과 투명도 조정 */
  transform: translate(-50%, -50%) rotate(60deg); /* 중앙에 위치시키고 회전 */
  z-index: -1;
`;

export const TryBox3 = styled.div`
  position: absolute;
  top: 70%;
  left: 25%;
  width: 0;
  height: 0;
  border-left: 50px solid transparent; /* 왼쪽 경계 */
  border-right: 50px solid transparent; /* 오른쪽 경계 */
  border-bottom: 2000px solid #efbad6; /* 삼각형의 높이를 길게 설정하고, 색상과 투명도 조정 */
  transform: translate(-50%, -50%) rotate(350deg); /* 중앙에 위치시키고 회전 */
  z-index: -1;
`;

export const TryBox4 = styled.div`
  position: absolute;
  top: -20%;
  left: 30%;
  width: 0;
  height: 0;
  border-left: 30px solid transparent; /* 왼쪽 경계 */
  border-right: 30px solid transparent; /* 오른쪽 경계 */
  border-bottom: 3000px solid yellow; /* 삼각형의 높이를 길게 설정하고, 색상과 투명도 조정 */
  transform: translate(-50%, -50%) rotate(200deg); /* 중앙에 위치시키고 회전 */
  z-index: -1;
`;

export const LogoBlackBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20rem;
`;

export const DonateInfoTextBox = styled.div`
  margin-top: 1rem;
  font-weight: 700;
`;

export const DonateBox = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`;

export const DonateWHBox = styled.div`
  width: 36.5%;
  height: 10rem;
`;

export const DonateWHInBox = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  padding: 0.3rem;
`;

export const DonateWHIn2Box = styled.div`
  background-color: white;
  height: 10rem;
  border-radius: 8px;
  height: 100%;
  padding: 10px;
`;

export const DonateWHIn3Box = styled.div`
  border-bottom: 1px solid gray;
  font-size: 22px;
  font-weight: 800;
`;

export const DonateWHIn4Box = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
`;

export const DonateWHIn5Box = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
`;

export const DonateTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  box-sizing: border-box;
  height: 7rem;
  resize: none;
`;

export const DonateWHIn6Box = styled.div`
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const DonateWHIn7Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
`;

export const DonateCoinButton = styled.button`
  border-radius: 8px;
  border: 1px solid gray;
  background-color: white;
  width: 45%;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 700;
`;

export const DonateWHIn8Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const DonateWHIn9Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
