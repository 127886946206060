export const CustomSVG = () => (
  <svg
    width="50"
    height="40"
    viewBox="0 0 50 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="50" height="40" fill="url(#pattern0)" />
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image0_51_8"
          transform="matrix(0.02 0 0 0.025 0 -0.125)"
        />
      </pattern>
      <image
        id="image0_51_8"
        width="50"
        height="50"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEL0lEQVR4nO2Z+4tVVRTHPzk1U1mjSZCpPTQbiuzFGJJomjpJIo02JiX2F4SZ4GSJYFqIL5SICLEfJKiosEITsR8qf7AoCaIHFc6QFihJBWVajTY3Fnw3LLYz555z7zn3Gt4vbDhn3b3XXmuftdfrQgMNNNDA+YrrgEXAZmA38BXwI/Cbhj1/CezSnEeAazlHMBZYA3wNlCocpvAzwPX1UGAi8C7wrxPoL+AjYC3QCdwBXANcoWHPdwLzNGe/1oT1xusdoL0WCowEXgH6tfkZYCfQAbRUwM/W3Ae8LV4l8d4BXEVBmAMc12Z/Axt0ynnB7stG4B/t8TNwPzljqTOjj4FbKA43Ah+6r2P3Jxesc2bUDQyheAwBnnTm9my1DJ8QI/vcD1B7zHemtqRSJlOA0zKpLuqHhTKxPmByJd7ke52Eucp6Y51k+RZozrJwmRZaJL6I+qPZBd3H0y4ywX/SotzdXxWYK5kOAxemWTBbC+wELnD0Lc6LxMPMsBW4XTnV58CIiO+rmjcmoncqwi93tJnAZ8AMRzNZvtN+s9Io8oImr47oexLyJRNklPP/JSnu0Sd67Dg2i75X7ybkKdHWR3PXiP58GkU+0eTpEf1S5UE2TmrOcr3bKY+TdwmB83h0MU+LviBBkQ6nxPvAJdHcGfrtQBpFQhqSlOuc0JwHHW2taJZ79eh5fgZFzCSTlAi5XkhfyiKYQHMGRSwSH3G0oNSuDIqEYbyHDbJvswvQZRGEbM2gSIfef9dJ3qR3E/7qjIqUdE8HwmVO2bL4QZPHZ1DkNSd4qAiDUN0pFTEv9ZxbZxE9xnj9ZjKWxXua/HBKRYY72x5ofBMp8pgcwzjFA3/Zm4AP3Ne1TNhjoX6zUrosntbkl1Mq8qie/wRGu4rwHqfMBKeIHzsHcL8jgWOimcv32CH6ijSKtMmNnki4J/vciS2Qg4jjBip9jc/NwOvO7Gz8ovR8rg7BMu2AacDRqBYZpj37y5j9WQKY5isT5vgcLKlGyat+WSWZ7BBTY5ozF2vznAvdmpP6GlOzLn7LlbaVNBbyQgvwqWSxxkdmjFCmWVLCZx6l1mgC3pAMPQmBsizadcGM0ZtZi5oqcbH2tL1/leerCnc7ZQ4CN1A8JihAluTd7sqL8a3AITH+Qx2OIu5Ni/K00HA4pHQnVwzXXQnBrBe4NyfeVwJPucSzpOB3OQViljO1rio7i4vliXwP+IscDygRrYrkfYN4kSlKIl9U5F6mhoGlFVuVlgRvGEa/cqzOqLwuFPO0uUX/2Mtsijr0SeOUSuPuGjmQs/DSAIlbu2vXnFHetVR1/xbV2OtVGj+kJkWqTkiR6JXAt0mYFc7L9FaSQtQDbRL4qDryB52NbwOG8j/BEgl+TP+RhGqtJl4mT2yPPM32on19UZipL2DeZlJhuzTQQAMNMAj+A02RkeRhUx2dAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
