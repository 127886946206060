import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProvidsionsCr from "../../../../common/agreement/screen/ProvisionsCr";
import PersonalInfoCr from "../../../../common/agreement/screen/PersonalInfoCr";
import {
  SigUpH6Styled,
  SignPhoneUpStyledInput,
  SignUpCallNumberStyled,
  SignUpCheckBoxStyled,
  SignUpCheckNumberButtonStyled,
  SignUpCheckNumberStyledInput,
  SignUpContainerDivStyled,
  SignUpContentBetweenDivStyled,
  SignUpContentDivStyled,
  SignUpDivClickStyled,
  SignUpEmailStyledInput,
  SignUpEyeDivStyled,
  SignUpH2TitleStyled,
  SignUpImgLogoStyled,
  SignUpLinkStyled,
  SignUpMarginBottomDivStyled,
  SignUpMarginTopDivStyled,
  SignUpOverlapButtonStyled,
  SignUpPasswordDivStyled,
  SignUpPasswordInputStyled,
  SignUpStyledFaRegEyeIcon,
  SignUpStyledFaRegEyeSlashIcon,
  SignUpStyledHorizontalLine,
  SignUpStyledIcon,
  SignUpStyledInput,
  SignUpSuccessButtonStyled,
} from "../css/ReSignUpStyled";
import { IoIosArrowForward } from "react-icons/io";
import {
  getEmailCheck,
  getSendAuthCode,
  getVerifyAuthCode,
} from "../repo/EmailCheck";
import { getCheckPhoneNumber } from "../repo/PhonNumberCheck";
import formatPhoneNumber from "../../../../util/FormatPhoneNumber";
import { numericRegex } from "../../../../util/const";
import { validatePassword } from "../../../../common/utils/validator";
import { postAuthSignup } from "../repo/SingUpRepo";
import { debounce, slice } from "lodash";
import emailLogin from "../../../login/Renewal/repo/EmailLogin";
import { getReUrlData } from "../../../CreateButton/Renewal/repo/UrlData";
import { useDispatch, useSelector } from "react-redux";
import { setButtonUrl } from "../../../../common/redux/action/UrlButtonAction";
import {
  setLastMonth,
  setToday,
} from "../../../../common/redux/action/ToDayAction";
import { getProfileData } from "../../../Modifying/Renewal/repo/Profile";
import { setProfileData } from "../../../../common/redux/action/ProfileAction";
import {
  getCurrentDate,
  getLastMonthDate,
} from "../../../../util/DateCalculation";
import Swal from "sweetalert2";

import {
  MessageDisplaySuccessTop5px,
  MessageDisplayTop5px,
} from "../../../../util/MessageDisplay";

import { useTranslation } from "react-i18next";
import PhoneInput from "../../components/IntlTelInput";
import styled from "styled-components";
import CountryDropdown from "../../components/CountryDropdown";
import SignUpComponent from "../../components/SignUpComponents";
import {
  handleCheckNumber,
  handleEmailCheck,
  handleEmailCheckNumberRe,
} from "../../components/SignUpHandle";

export const ReSignUpPage = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allPass, setAllPass] = useState(true);
  const languageData = useSelector((state) => state.languageType.type);
  const enPhoneNumber = useSelector((state) => state.intlReducerState);
  /// 약관 동의 상태
  const [allChecked, setAllChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const [isCheckBoxValid, setCheckBoxValid] = useState(null);
  useEffect(() => {
    if (privacyChecked !== true || termsChecked !== true) {
      setCheckBoxValid(false);
    } else {
      setCheckBoxValid(true);
    }
  }, [termsChecked, privacyChecked]);

  /// 약관 모달상태
  const [termsModalShow, setTermsModalShow] = useState(false);
  const [termsModalShow2, setTermsModalshow2] = useState(false);

  /// 비밀번호, 비밀번호 확인 상태
  const [isEyeState, setEyeState] = useState(false);
  const [isCheckEyeState, setCheckEyeState] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");

  const [isPasswordValid, setPasswordValid] = useState(null);
  const [isPasswordValid2, setPasswordValid2] = useState(null);

  const [isCheckPasswordValid, setCheckPasswordValid] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryMSG, setSelectedCountryMSG] = useState(false);
  useEffect(() => {
    if (inputPassword.trim() === "") {
      setPasswordValid(inputPassword === "" ? null : false);
    } else {
      setPasswordValid(true);
    }

    if (!validatePassword(inputPassword)) {
      setPasswordValid2(inputPassword === "" ? null : false);
    } else {
      setPasswordValid2(true);
    }
  }, [inputPassword]);
  useEffect(() => {
    if (checkPassword.trim() === "") {
      setCheckPasswordValid(null);
    } else if (checkPassword !== inputPassword) {
      setCheckPasswordValid(false);
    } else {
      setCheckPasswordValid(true);
    }
  }, [checkPassword, inputPassword]);

  /// 이메일 상태확인
  const [isCurrentEmail, setCurrentEmail] = useState("");
  const [isCheckEmail, setCheckEmail] = useState(false);
  /// 이메일 상태MSG
  const [overlapUser, setOverlapUser] = useState(false);
  const [isNoEmail, setNoEmail] = useState(false);
  const [whatError, setWhatError] = useState(false);
  const [isCheckNumber, setCheckNumber] = useState(false);

  /// 회원가입 inputText 상태 MSG

  const [isEmailFilterText, setEmailFilterText] = useState(false);

  /// 인증번호 input상태
  const [isInputCheckNumber, setInputCheckNumber] = useState("");
  const [isInputCheckNumberBox, setInputCheckNumberBox] = useState(false);

  const handleInputCheckNumber = (e) => {
    setInputCheckNumber(e.target.value);
  };

  const [enPhoneNumberState, setEnPhonNumberState] = useState(false);

  /// 휴대폰 번호 상태확인
  const [isCheckPhonNumber, setCheckPhonNumber] = useState(false);
  const [noCheckPhoneNumber, setNoCheckPhoneNumber] = useState(false);
  const [isPhoneNumberReCheck, setPhoneNumberReCheck] = useState(false);
  const [isFormattedPW, setFormattedPW] = useState("");
  /// 휴대폰 번호 상태
  const [isInputCheckPhoneNumber, setInputCheckPhoneNumber] = useState("");
  // const [checkPhonNumberOut, checkPhonNumberIn] = useState()
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(null);

  useEffect(() => {
    if (isInputCheckPhoneNumber.trim() === "") {
      setPhoneNumberValid(null);
    } else {
      setPhoneNumberValid(true);
    }
  }, [isInputCheckPhoneNumber]);

  const [isNaveValid, setIsNameValid] = useState(null);
  const [isNickNameValid, setNickNameValid] = useState(null);

  /// 현제 입력된 값 상태관리
  const [isCurrentName, setCurrentName] = useState("");

  useEffect(() => {
    // 이름이 비어있는지 확인하는 로직
    if (isCurrentName.trim() === "") {
      setIsNameValid(isCurrentName === "" ? null : false);
    } else {
      setIsNameValid(true);
    }
  }, [isCurrentName]);

  const handleCurrentName = (e) => {
    setCurrentName(e.target.value);
  };

  /// 현재 입력된 닉네임값 상태관리
  const [isCurrentNickName, setCurrentNickName] = useState("");

  const handleCurrentNickName = (e) => {
    setCurrentNickName(e.target.value);
  };

  /// 현재 입력된 Password값 상태관리
  const [isCurrentPassword, setCurrentPassword] = useState("");

  useEffect(() => {
    // 이름이 비어있는지 확인하는 로직
    if (isCurrentNickName.trim() === "") {
      setNickNameValid(isCurrentNickName === "" ? null : false);
    } else {
      setNickNameValid(true);
    }
  }, [isCurrentNickName]);

  const [isNickNameToValid, setNickNameToValid] = useState(null);

  const debouncedValidateNickName = useCallback(
    debounce((nickname) => {
      if (nickname.trim() === "") {
        setNickNameToValid(null);
      } else if (/[^a-zA-Z0-9가-힣]/.test(nickname)) {
        setNickNameToValid(false);
      } else {
        setNickNameToValid(true);
      }
    }, 200),
    []
  );

  // 닉네임이 변경될 때마다 디바운스된 검증 함수 호출
  useEffect(() => {
    debouncedValidateNickName(isCurrentNickName);

    // clean-up 함수로 디바운스된 함수 취소
    return () => {
      debouncedValidateNickName.cancel();
    };
  }, [isCurrentNickName, debouncedValidateNickName]);
  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  /// 현재 입력된 비밀번호 확인 상태관리
  const [isCheckCurrentPassword, setCheckCurrentPassword] = useState("");

  const handleCheckCurrentPassword = (e) => {
    setCheckCurrentPassword(e.target.value);
  };

  const handleCheckPhoneNumber = async (e) => {
    setInputCheckPhoneNumber(e.target.value);
  };

  const handleOnChange = (e) => {
    setCurrentEmail(e.target.value);
  };

  /// 비밀번호 상태변경 핸들러
  const handleInputChange = (event) => {
    setInputPassword(event.target.value);
  };
  const handleEyeClick = () => {
    setEyeState(!isEyeState);
  };

  /// 비밀번호 확인 상태변경 핸들러
  const handleCheckInputChange = (event) => {
    setCheckPassword(event.target.value);
  };

  const handleCheckEyeClick = () => {
    setCheckEyeState(!isCheckEyeState);
  };

  /// 이용약관 전체동의 핸들러
  const handleAllChecked = (e) => {
    const isChecked = e.target.checked;
    setAllChecked(isChecked);
    setTermsChecked(isChecked);
    setPrivacyChecked(isChecked);
  };

  const handleIndividualCheck = (setter) => (e) => {
    setter(e.target.checked);
    if (!e.target.checked) {
      setAllChecked(false);
    }
  };

  const handleTermsIconClick = () => {
    setTermsModalShow(true);
  };

  const handleTermsIconClicke2 = () => {
    setTermsModalshow2(true);
  };

  const handlePhonNumberCheckReSet = () => {
    setCheckPhonNumber(false);
  };

  /// 휴대폰 번호 중복확인
  const handlePhonNumberCheck = async (number) => {
    if (!numericRegex.test(number)) {
      setPhoneNumberReCheck(true);
      return;
    }

    if (number.length !== 11) {
      setPhoneNumberReCheck(true);
      return;
    }

    if (!number.startsWith("010")) {
      setPhoneNumberReCheck(true);
      return;
    }

    const phone = formatPhoneNumber(number);
    const resp = await getCheckPhoneNumber(phone);
    setFormattedPW(phone);

    if (resp.msg === "Success") {
      if (resp.data.data === true) {
        setNoCheckPhoneNumber(true);
        setPhoneNumberReCheck(false);

        return;
      }

      if (resp.data.data === false) {
        setCheckPhonNumber(true);
        setNoCheckPhoneNumber(false);
        setPhoneNumberReCheck(false);
        return;
      }
    }

    if (resp.msg === "Fail") {
      return;
    }

    return;
  };

  /// 이메일 중복확인 핸들러
  // const handleEmailCheck = async (email) => {
  //   const resp = await getEmailCheck(email, true);

  //   if (resp.msg === "Success") {
  //     setOverlapUser(true);
  //     return;
  //   }

  //   if (resp.msg === "Fail") {
  //     if (resp.data.response.data.message === "잘못된 형식의 이메일") {
  //       setNoEmail(true);
  //       return;
  //     }

  //     /// 여기서 이후 로직 처리
  //     if (
  //       resp.data.response.data.message === "이메일 주소와 일치하는 회원이 없음"
  //     ) {
  //       const sendCode = await getSendAuthCode(email, true);

  //       if (sendCode.msg === "Success") {
  //       }

  //       if (sendCode.msg === "Fail") {
  //         if (
  //           sendCode.data.response.data.message === "이메일 전송중에 문제생김"
  //         ) {
  //           setCheckEmail(false);

  //           setOverlapUser(false);

  //           setNoEmail(false);
  //           setWhatError(true);
  //           return;
  //         }
  //       }

  //       setCheckEmail(true);
  //       setOverlapUser(false);
  //       setNoEmail(false);

  //       return;
  //     }
  //   }

  //   return;
  // };

  // const handleEmailCheckNumberRe = async (email) => {
  //   const sendCode = await getSendAuthCode(email, true);

  //   if (sendCode.msg === "Success") {
  //   }

  //   if (sendCode.msg === "Fail") {
  //     if (sendCode.data.response.data.message === "이메일 전송중에 문제생김") {
  //       setCheckEmail(false);

  //       setOverlapUser(false);

  //       setNoEmail(false);
  //       setWhatError(true);
  //       console.log("이메일 전송중에 문제가 생김");
  //       return;
  //     }
  //   }
  // };

  // const handleCheckNumber = async (email, code) => {
  //   const resp = await getVerifyAuthCode(email, code);

  //   if (resp.msg === "Success") {
  //     setInputCheckNumberBox(true);
  //     setEmailFilterText(false);
  //     setCheckNumber(false);
  //   }

  //   if (resp.msg === "Fail") {
  //     if (resp.data.response.data.message === "잘못된 이메일 혹은 인증번호") {
  //       setEmailFilterText(false);

  //       setCheckNumber(true);
  //       console.log("인증번호 잘못됨");
  //       return;
  //     }
  //   }

  //   return;
  // };

  const handleSingUpButton = async (phoneNumber) => {
    let resp;
    // 일단 유효성 검사
    setAllPass(true);
    let pass = true;

    /// 1. 이메일 중복확인을 하지 않음
    if (isInputCheckNumberBox !== true) {
      setEmailFilterText(true);
      pass = false;
    }

    /// 2. 이름을 입력 안했을 경우
    if (isCurrentName.trim() === "") {
      setIsNameValid(false);
      pass = false;
    }

    /// 3. 닉네임을 입력안한경우
    if (isCurrentNickName.trim() === "") {
      setNickNameValid(false);
      pass = false;
    }

    if (/[^a-zA-Z0-9가-힣]/.test(isCurrentNickName)) {
      pass = false;
    }

    /// 4. 비밀번호를 입력하지 않은 경우
    if (inputPassword.trim() === "") {
      setPasswordValid(false);
      pass = false;
    }

    /// 5. 비밀번호가 조건에 맞지 않을경우

    if (!validatePassword(inputPassword)) {
      pass = false;
    }

    /// 6. 비밀번호 확인이 빈값일경우
    if (checkPassword.trim() === "") {
      pass = false;
    }

    /// 7. 비밀번호와 확인이 값이 다를 경우
    if (inputPassword !== checkPassword) {
      pass = false;
    }

    /// 8. 비밀번호와 값이 같을 경우 텍스트 출력
    if (inputPassword === checkPassword) {
      // pass = false;
    }

    /// 국가정보가 들어오지 않았을 경우
    if (languageData !== "ko") {
      if (!selectedCountry.trim()) {
        setSelectedCountryMSG(true);
        pass = false;
      }
    } else {
      setSelectedCountryMSG(false);
    }

    /// 9. 휴대폰 번호가 빈값일 경우
    if (languageData === "ko") {
      if (isInputCheckPhoneNumber.trim() === "") {
        setPhoneNumberValid(false);
        pass = false;
        return;
      }

      if (!numericRegex.test(phoneNumber)) {
        setPhoneNumberReCheck(true);
        return;
      }

      if (phoneNumber.length !== 11) {
        setPhoneNumberReCheck(true);
        return;
      }

      if (!phoneNumber.startsWith("010")) {
        setPhoneNumberReCheck(true);
        return;
      }
    } else {
      const phoneNumber = enPhoneNumber.tel;
      const afterPlusAndNumber = phoneNumber.split(/\s+/)[1];
      if (!afterPlusAndNumber || afterPlusAndNumber.trim() === "") {
        setEnPhonNumberState(true);

        pass = false;
      } else {
        setEnPhonNumberState(false);
      }
    }

    /// 10. 휴대폰 중복확인을 하지 않은 경우
    // if (isCheckPhonNumber !== true) {
    //   pass = false;
    // }

    /// 11. 이용약관이 동의가 되어있지 않은 경우
    if (termsChecked !== true || privacyChecked !== true) {
      pass = false;
    }

    /// 12. 검증로직 통과한게 있는지 확인
    if (!pass) {
      setAllPass(false);
      return;
    }

    /// 검증로직 통과 통신로직 실행
    if (languageData === "ko") {
      const phone = formatPhoneNumber(phoneNumber);

      resp = await postAuthSignup(
        isCurrentEmail,
        isCurrentNickName,
        isCurrentName,
        checkPassword,
        phone,
        "South Korea"
      );
    } else {
      resp = await postAuthSignup(
        isCurrentEmail,
        isCurrentNickName,
        isCurrentName,
        checkPassword,
        enPhoneNumber.tel,
        selectedCountry
      );
    }

    if (resp?.msg === "Success") {
      try {
        const currentDay = getCurrentDate();
        const lastDay = getLastMonthDate();
        const resp = await emailLogin(isCurrentEmail, checkPassword);

        if (resp === true) {
          const buttonURL = await getReUrlData();
          dispatch(setButtonUrl(buttonURL.data));
          dispatch(setLastMonth(lastDay.year, lastDay.month, lastDay.day));
          dispatch(setToday(currentDay.year, currentDay.month, currentDay.day));

          const profile = await getProfileData();

          if (profile === "Fail") {
            return;
          } else {
            dispatch(setProfileData(profile.data));
          }

          navigate("/ReMyPage");
        } else {
          if (resp.error.status_code === 500) {
            Swal.fire({
              title: `${t("signUpFailText")}`,
              icon: "error",
              confirmButtonText: `${t("checkText")}`,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/");
              }
            });
          }
          return;
        }
      } catch (error) {
        console.log("로그인 오류", error);
        return;
      }
    }

    if (resp.msg === "Fail") {
      Swal.fire({
        title: `${t("signUpFailText")}`,
        icon: "error",
        confirmButtonText: `${t("checkText")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
      return;
    }
    return;
  };

  const TextStateColorBox = styled.div`
    color: red;
    margin-top: 5px;
  `;

  const TextStateColorBlueBox = styled.div`
    color: #357fed;
    margin-top: 5px;
  `;

  return (
    <SignUpContentDivStyled className="content">
      <SignUpContainerDivStyled className="container ">
        <div>
          <SignUpMarginTopDivStyled>
            <img src="/group.svg" alt="icon" />

            <SignUpImgLogoStyled
              src={languageData === "en" ? "/GroupEn.svg" : "/LogoBlack.svg"}
              alt=""
            />
          </SignUpMarginTopDivStyled>
        </div>
        <SignUpLinkStyled to="/">
          <SignUpStyledIcon />

          {t("loginPageText")}
        </SignUpLinkStyled>
        <SignUpMarginBottomDivStyled className="card">
          <div className="card-body">
            <SignUpH2TitleStyled className="title1">
              {t("singUpText")}
            </SignUpH2TitleStyled>
            <br />
            <SigUpH6Styled>{t("wordEmailText")}</SigUpH6Styled>
            <SignUpCallNumberStyled>
              <SignUpEmailStyledInput
                placeholder="xrai@xrai.com"
                value={isCurrentEmail}
                onChange={handleOnChange}
                disabled={isCheckEmail}
              ></SignUpEmailStyledInput>
              {isCheckEmail ? (
                <SignUpOverlapButtonStyled
                  className="btn btn-lg btn-gray"
                  onClick={() =>
                    handleEmailCheckNumberRe(
                      isCurrentEmail,
                      setCheckEmail,
                      setOverlapUser,
                      setNoEmail,
                      setWhatError
                    )
                  }
                  disabled={isInputCheckNumberBox}
                >
                  {t("retransmissionText")}
                </SignUpOverlapButtonStyled>
              ) : (
                <SignUpOverlapButtonStyled
                  className="btn btn-lg btn-primary"
                  onClick={() =>
                    handleEmailCheck(
                      isCurrentEmail,
                      setOverlapUser,
                      setNoEmail,
                      setCheckEmail,
                      setWhatError
                    )
                  }
                >
                  {t("emailCertifiedText")}
                </SignUpOverlapButtonStyled>
              )}
            </SignUpCallNumberStyled>
            {isCheckEmail && (
              <SignUpCallNumberStyled style={{ marginTop: `10px` }}>
                <SignUpCheckNumberStyledInput
                  placeholder={t("pleaseEnterNumberText")}
                  value={isInputCheckNumber}
                  onChange={handleInputCheckNumber}
                  disabled={isInputCheckNumberBox}
                ></SignUpCheckNumberStyledInput>

                <SignUpCheckNumberButtonStyled
                  className="btn btn-lg btn-primary"
                  onClick={() =>
                    handleCheckNumber(
                      isCurrentEmail,
                      isInputCheckNumber,
                      setInputCheckNumberBox,
                      setEmailFilterText,
                      setCheckNumber
                    )
                  }
                  disabled={isInputCheckNumberBox}
                >
                  {t("checkEnterNumberText")}
                </SignUpCheckNumberButtonStyled>
              </SignUpCallNumberStyled>
            )}

            {/* 
            TODO: 이후 인증로직으로 처리해야할 부분
            */}
            <MessageDisplayTop5px
              state={overlapUser}
              message={t("alreadyAccountsText")}
            />
            <MessageDisplayTop5px
              state={isNoEmail}
              message={t("invalidEmailText")}
            />
            <MessageDisplayTop5px
              state={whatError}
              message={t("awaitRePlayText")}
            />

            <MessageDisplayTop5px
              state={isCheckNumber}
              message={t("invalidNumberText")}
            />
            <MessageDisplaySuccessTop5px
              state={isInputCheckNumberBox}
              message={t("emailCheckSuccessText")}
            />
            <MessageDisplayTop5px
              state={isEmailFilterText}
              message={t("pleaseEmailCheckText")}
            />

            <SigUpH6Styled>{t("nameText")}</SigUpH6Styled>

            <SignUpStyledInput
              placeholder={t("realNameInputText")}
              value={isCurrentName}
              onChange={handleCurrentName}
            ></SignUpStyledInput>

            {isNaveValid === false && (
              <TextStateColorBox>{t("inputNamePleaseText")}</TextStateColorBox>
            )}
            {languageData !== "ko" && (
              <>
                <SigUpH6Styled>Nationality</SigUpH6Styled>
                <CountryDropdown setSelectedCountry={setSelectedCountry} />
              </>
            )}
            <MessageDisplayTop5px
              state={selectedCountryMSG}
              message="Please select a country"
            />

            {/* <SignUpStyledInput
              placeholder={t("realNameInputText")}
              value={isCurrentName}
              onChange={handleCurrentName}
            ></SignUpStyledInput> */}

            <SigUpH6Styled>{t("titleNickName")}</SigUpH6Styled>
            <SignUpStyledInput
              placeholder={t("sponsorViewNickNameText")}
              value={isCurrentNickName}
              onChange={handleCurrentNickName}
            ></SignUpStyledInput>
            {isNickNameValid === false && (
              <TextStateColorBox>
                {t("inputPleaseNickNameText")}
              </TextStateColorBox>
            )}
            {isNickNameToValid === false && (
              <TextStateColorBox>{t("nicknameCannotText")}</TextStateColorBox>
            )}

            <SigUpH6Styled>{t("passwordText")}</SigUpH6Styled>
            <SignUpPasswordDivStyled>
              <SignUpPasswordInputStyled
                placeholder={t("passwordGuideText")}
                type={isEyeState ? "text" : "password"}
                value={inputPassword}
                onChange={handleInputChange}
              ></SignUpPasswordInputStyled>

              <SignUpEyeDivStyled onClick={() => handleEyeClick()}>
                {isEyeState === true && <SignUpStyledFaRegEyeIcon />}

                {isEyeState === false && <SignUpStyledFaRegEyeSlashIcon />}
              </SignUpEyeDivStyled>
            </SignUpPasswordDivStyled>
            {isPasswordValid === false && (
              <TextStateColorBox>
                {t("passwordInputPleaseText")}
              </TextStateColorBox>
            )}

            {isPasswordValid2 === false && (
              <TextStateColorBox>{t("passwordRuleText")}</TextStateColorBox>
            )}

            <SigUpH6Styled>{t("finalCheckPasswordText")}</SigUpH6Styled>
            <SignUpPasswordDivStyled>
              <SignUpPasswordInputStyled
                placeholder={t("passwordGuideText")}
                type={isCheckEyeState ? "text" : "password"}
                value={checkPassword}
                onChange={handleCheckInputChange}
              ></SignUpPasswordInputStyled>
              <SignUpEyeDivStyled onClick={() => handleCheckEyeClick()}>
                {isCheckEyeState === true && <SignUpStyledFaRegEyeIcon />}

                {isCheckEyeState === false && <SignUpStyledFaRegEyeSlashIcon />}
              </SignUpEyeDivStyled>
            </SignUpPasswordDivStyled>
            {checkPassword &&
              (isCheckPasswordValid === false ? (
                <TextStateColorBox>{t("noSamePasswordText")}</TextStateColorBox>
              ) : (
                isCheckPasswordValid === true && (
                  <TextStateColorBlueBox>
                    {t("passwordSameCheckText")}
                  </TextStateColorBlueBox>
                )
              ))}
            <SigUpH6Styled>{t("callNumberText")}</SigUpH6Styled>
            <SignUpCallNumberStyled>
              {languageData === "en" ? (
                <PhoneInput />
              ) : (
                <SignPhoneUpStyledInput
                  placeholder={t("phoneNumberRuleText")}
                  value={isInputCheckPhoneNumber}
                  onChange={handleCheckPhoneNumber}
                  type="text"
                  maxLength={11}
                  disabled={isCheckPhonNumber}
                ></SignPhoneUpStyledInput>
              )}
              {/* {isCheckPhonNumber ? (
                <SignUpOverlapButtonStyled
                  className="btn btn-lg btn-gray"
                  onClick={() => handlePhonNumberCheckReSet()}
                >
                  수정
                </SignUpOverlapButtonStyled>
              ) : (
                <SignUpOverlapButtonStyled
                  className="btn btn-lg btn-primary"
                  onClick={() => handlePhonNumberCheck(isInputCheckPhoneNumber)}
                >
                  중복확인
                </SignUpOverlapButtonStyled>
              )} */}
            </SignUpCallNumberStyled>
            <MessageDisplayTop5px
              state={enPhoneNumberState}
              message={t("invalidNumberText")}
            />
            {isPhoneNumberValid === false && (
              <TextStateColorBox>
                {t("pleaseInputPhoneNumber")}
              </TextStateColorBox>
            )}
            {isPhoneNumberReCheck && (
              <TextStateColorBox>
                {t("phoneNumberReCheckText")}
              </TextStateColorBox>
            )}

            <SignUpStyledHorizontalLine />

            <div>
              <div className="modal-checkBox">
                <SignUpCheckBoxStyled>
                  <input
                    type="checkBox"
                    className="input-checkBox"
                    checked={allChecked}
                    onChange={handleAllChecked}
                  />
                  <span className="modal-checkBox-span">
                    {t("allSuccessText")}
                  </span>
                </SignUpCheckBoxStyled>
              </div>

              <div className="modal-checkBox">
                <SignUpContentBetweenDivStyled>
                  <div>
                    <SignUpCheckBoxStyled>
                      <input
                        type="checkBox"
                        className="input-checkBox"
                        checked={termsChecked}
                        onChange={handleIndividualCheck(setTermsChecked)}
                      />
                      <span className="modal-checkBox-span">
                        {t("termsAndConditionsText")}
                      </span>
                    </SignUpCheckBoxStyled>
                  </div>
                  <div>
                    <SignUpDivClickStyled onClick={handleTermsIconClick}>
                      <strong>{t("inDetailText")}</strong>
                      <IoIosArrowForward size={20} />
                    </SignUpDivClickStyled>
                    <Modal
                      className="modal-lg "
                      show={termsModalShow}
                      onHide={() => setTermsModalShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t("termsAndConditionsText")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-fixed">
                        <ProvidsionsCr />
                      </Modal.Body>
                    </Modal>
                  </div>
                </SignUpContentBetweenDivStyled>
              </div>
              <div className="modal-checkBox">
                <SignUpContentBetweenDivStyled>
                  <div>
                    <SignUpCheckBoxStyled>
                      <input
                        type="checkBox"
                        className="input-checkBox"
                        checked={privacyChecked}
                        onChange={handleIndividualCheck(setPrivacyChecked)}
                      />
                      <span className="modal-checkBox-span">
                        {t("personalInfoText")}
                      </span>
                    </SignUpCheckBoxStyled>
                  </div>
                  <div>
                    <SignUpDivClickStyled onClick={handleTermsIconClicke2}>
                      <strong>{t("inDetailText")}</strong>
                      <IoIosArrowForward size={20} />
                    </SignUpDivClickStyled>
                    <Modal
                      className="modal-lg"
                      show={termsModalShow2}
                      onHide={() => setTermsModalshow2(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t("persnolText")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-fixed">
                        <PersonalInfoCr />
                      </Modal.Body>
                    </Modal>
                  </div>
                </SignUpContentBetweenDivStyled>
              </div>
            </div>
            {!isCheckBoxValid && (
              <TextStateColorBox>
                {t("consentPersnoalInfoText")}
              </TextStateColorBox>
            )}

            <SignUpComponent
              isInputCheckPhoneNumber={isInputCheckPhoneNumber}
              selectedCountry={selectedCountry}
              handleSingUpButton={handleSingUpButton}
            />
            {/* <SignUpSuccessButtonStyled
              className="btn btn-lg btn-primary"
              onClick={() => handleSingUpButton(isInputCheckPhoneNumber)}
            >
              {t("signUpSuccessText")}
            </SignUpSuccessButtonStyled> */}
          </div>
        </SignUpMarginBottomDivStyled>
      </SignUpContainerDivStyled>
    </SignUpContentDivStyled>
  );
};
