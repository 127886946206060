import GoHomeButton from "../components/GoHomeButton";
import ImageComponent from "../components/ImageComponent";
import "../css/NotFoundPage.css";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div>
      <ImageComponent src="/image404.svg" />
      <GoHomeButton onClick={handleGoHome} />
    </div>
  );
};

export default NotFoundPage;
