// src/components/MessageDisplay.js
import React from "react";
import styled from "styled-components";

export const MessageDisplayStyled = styled.div`
  color: red;
  margin-top: 12px;
`;

export const MessageDisplay = ({ state, message }) => {
  return (
    <>
      {state === true && <MessageDisplayStyled>{message}</MessageDisplayStyled>}
    </>
  );
};

export const MessageDisplayNoMargin = ({ state, message }) => {
  return <>{state === true && <div style={{ color: "red" }}>{message}</div>}</>;
};

export const MessageDisplayTop5px = ({ state, message }) => {
  return (
    <>
      {state === true && (
        <div style={{ color: "red", marginTop: "5px" }}>{message}</div>
      )}
    </>
  );
};

export const MessageDisplaySuccessTop5px = ({ state, message }) => {
  return (
    <>
      {state === true && (
        <div style={{ color: "#2977ff", marginTop: "5px" }}>{message}</div>
      )}
    </>
  );
};
export default MessageDisplay;
