export const AUTH_ERROR = "AUTH_ERROR";
export const RESET_AUTH_ERROR = "RESET_AUTH_ERROR";

export const authError = (error) => ({
  type: AUTH_ERROR,
  payload: error,
});

export const resetAuthError = () => ({
  type: RESET_AUTH_ERROR,
});
