import { SET_SEARCH_TEXT, SET_SEARCH_VALUE } from "../action/SearchTextAction";
import { STORE_RESET } from "../action/SidbarIndexAction";

const initialState = {
  text: null,
};

const searchTextReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return {
        ...state,
        text: action.payload,
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default searchTextReducer;

const valueInitialState = {
  index: 1,
};

export const searchSelectedValueReducer = (
  state = valueInitialState,
  action
) => {
  switch (action.type) {
    case SET_SEARCH_VALUE:
      return {
        ...state,
        index: action.payload,
      };
    case STORE_RESET:
      return valueInitialState;
    default:
      return state;
  }
};
