import React from "react";
import axios from "axios";

export const handleCheckRegistrationNumber = async (req) => {
  const url = `https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=${process.env.REACT_APP_REGISTRATION_API_KEY}`;
  const { data } = await axios.post(url, {
    b_no: [req],
  });
  return data;
};
