import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Document, Page, pdfjs } from "react-pdf";
//
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { IoClose } from "react-icons/io5";
import "pdfjs-dist/build/pdf.worker.mjs";

import { GlobalWorkerOptions } from "pdfjs-dist/webpack";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationCheck } from "../home/Confirmation/repo/CheckSuccessRepo";
import Swal from "sweetalert2";
import { updateConfirmationCheck } from "../common/redux/action/ConfirmationAction";
import { PdfDownloadRepo } from "../home/Confirmation/repo/PdfDownloadRepo";
import { Loading01 } from "./spinner";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// const pdfjs = await import("pdfjs-dist/build/pdf");
// const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// const loadPdfWorker = async () => {
//   // pdfjs 및 pdf.worker.entry를 비동기로 가져옴
//   const pdfjs = await import("pdfjs-dist/build/pdf");
//   const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");

//   // GlobalWorkerOptions.workerSrc 설정
//   pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// };
// loadPdfWorker();

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = "/path-to-local-pdf.worker.min.js";

Modal.setAppElement("#root");

const SizedBox = styled.div`
  height: 30px;
`;

const PdfViewer = ({ isOpen, onClose, check = false, id, path, date }) => {
  // GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const useData = useSelector((state) => state?.profileState?.profile?.name);
  const [checked, setChecked] = useState(check);
  const [isDisable, setDisable] = useState(check);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [pdfDocument, setPdfDocument] = useState(null);

  useEffect(() => {
    let isMounted = true;
    // GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    const fetchPdf = async () => {
      try {
        const resp = await PdfDownloadRepo(id, path);
        if (isMounted) {
          if (resp.msg === "Success") {
            const pdfBlob = new Blob([resp.data.data], {
              type: "application/pdf",
            });
            const url = URL.createObjectURL(pdfBlob);
            setPdfBlobUrl(url);
          } else {
            Swal.fire({
              title: `${t("pdfDownloadFailText")}`,
              icon: "error",
            });
            onClose();
          }
        }
      } catch (error) {
        if (isMounted) {
          Swal.fire({
            title: `${t("pdfDownloadErrorText")}`,
            icon: "error",
          });
          onClose();
        }
      }
    };

    fetchPdf();

    // 클린업 함수
    return () => {
      isMounted = false;
      if (pdfDocument) {
        pdfDocument
          .destroy()
          .then(() => {
            console.log("PDF 작업이 중지되었습니다.");
          })
          .catch((error) => {
            console.error("PDF 작업 종료 중 오류:", error);
          });
      }
    };
  }, []);

  const handleCheckboxChange = () => {
    setChecked(!checked);
    setDisable(!isDisable);
  };

  const handleSuccess = async (id) => {
    try {
      const resp = await ConfirmationCheck(id);
      if (resp.msg === "Success") {
        dispatch(updateConfirmationCheck(id));
        onClose();
        return;
      }

      if (resp.msg === "Fail") {
        console.log("동의 실패", resp);
        Swal.fire({
          title: `${t("NetWorkErrorMSG")}`,
          icon: `error`,
        });
        onClose();
        return;
      }
    } catch (error) {
      Swal.fire({
        title: `${t("NetWorkErrorMSG")}`,
        icon: `error`,
      });
      onClose();
      return;
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="PDF Viewer">
      <div className="modal-popup" id="layer-sample">
        <div className="dimed"></div>
        <div className="popup-inner">
          <div className="popup-header">
            <div
              style={{
                display: `flex`,
                justifyContent: `space-between`,
                alignItems: `center`,
                width: `100%`,
              }}
            >
              <h2 className="popup-title"> {t("statementOfAccountsText")}</h2>
              <div>
                <IoClose
                  style={{ height: `30px`, width: `30px`, cursor: `pointer` }}
                  onClick={onClose}
                />
              </div>
            </div>
          </div>
          <div
            className="popup-body"
            style={{
              justifyContent: `center`,
              // alignItems: `center`,
              display: `flex`,
            }}
          >
            <div className="scroll-inner">
              {pdfBlobUrl ? (
                <Document
                  file={pdfBlobUrl}
                  onLoadSuccess={(pdf) => {
                    setPdfDocument(pdf);
                  }}
                  onLoadError={(error) => {
                    console.error("PDF 로딩 오류:", error);
                  }}
                >
                  <Page pageNumber={1} />
                </Document>
              ) : (
                <>
                  <Loading01 />
                </>
              )}
              <div
                style={{
                  marginLeft: `1rem`,
                }}
              >
                <div
                  style={{
                    fontSize: `20px`,
                    fontWeight: `700`,
                    marginBottom: `10px`,
                  }}
                >
                  {t("agreeToConfirmText")}
                </div>
                <p>
                  {t("agreeGuidelinesText")}
                  <br />
                  {t("agreeGuidelinesText2")}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>{t("agreeGuidelinesTitleText")}</div>
                  {check === false ? (
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleCheckboxChange}
                      style={{ marginLeft: "10px" }}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      checked={checked}
                      disabled={isDisable}
                      onChange={handleCheckboxChange}
                      style={{ marginLeft: "10px" }}
                    />
                  )}
                </div>
                <div style={{ display: `flex`, justifyContent: `flex-end` }}>
                  {t("userNameText")} : {useData}
                </div>
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    marginTop: `20px`,
                  }}
                >
                  {check === false ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-lg btn-gray"
                        data-modal="close"
                        onClick={onClose}
                      >
                        {t("cancellationText")}
                      </button>
                      <div
                        style={{
                          marginLeft: `1rem`,
                        }}
                      ></div>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        data-modal="close"
                        onClick={() => handleSuccess(id)}
                        disabled={!isDisable}
                      >
                        {t("checkText")}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        data-modal="close"
                        onClick={onClose}
                      >
                        {t("checkText")}
                      </button>
                    </>
                  )}
                </div>
                <SizedBox></SizedBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PdfViewer;
