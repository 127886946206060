import {
  MyPageMoneyTableTableCellStyled,
  MyPageMoneyTableTableRowStyled,
  TableTableStyled,
} from "../css/ReMyPageStyld";
import { formatNumber } from "../../../../util/const";
import {
  getCurrentDate,
  getLastThreeMonths,
} from "../../../../util/DateCalculation";

import { monthText } from "../../../../common/publicText/PublicText";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const MyPageTable = ({ data }) => {
  const { t } = useTranslation();
  const languageData = useSelector((state) => state.languageType.type);

  return (
    <div className="table ">
      <div className="table-inner ">
        <table style={{ width: `100%` }}>
          {data.length === 0 ? (
            <div>
              <div
                style={{
                  display: `flex`,
                  width: `100%`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  marginTop: `20px`,
                }}
              >
                {languageData === "en" ? (
                  <img src="/GroupEn.svg" alt="" width={300} />
                ) : (
                  <img src="LogoBlack.svg" alt="" />
                )}
              </div>
              <div
                style={{
                  display: `flex`,
                  width: `100%`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  marginTop: `20px`,
                  fontSize: `18px`,
                  fontWeight: `700`,
                  marginBottom: `30px`,
                }}
              >
                {t("sponsorshipDetailsNotExistText")}
              </div>
            </div>
          ) : (
            <>
              <caption className="sr-only "></caption>
              <colgroup>
                <col style={{ width: "160px" }} />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr style={{ textAlign: `center` }}>
                  <th scope="col">{t("nameText")}</th>
                  <th scope="col">{t("amountOfSponsorShipText")}</th>
                  <th scope="col">{t("amountOfSponsorShipDayText")}</th>
                  <th scope="col">{t("amountOfSponsorShipContentsText")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <MyPageMoneyTableTableRowStyled key={index}>
                    <th scope="row">{row.name ? row.name : "-"}</th>
                    <MyPageMoneyTableTableCellStyled>
                      {formatNumber(row.money)}
                    </MyPageMoneyTableTableCellStyled>
                    <MyPageMoneyTableTableCellStyled>
                      {row.date}
                    </MyPageMoneyTableTableCellStyled>
                    <MyPageMoneyTableTableCellStyled>
                      {row.memo}
                    </MyPageMoneyTableTableCellStyled>
                  </MyPageMoneyTableTableRowStyled>
                ))}
              </tbody>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export const MyPageMoneyTable = (data) => {
  const { t } = useTranslation();

  if (!data || !data.data) {
    return (
      <TableTableStyled className="">
        <div
          style={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            height: `100%`,
          }}
        >
          <div style={{ height: `80px`, width: `80px`, display: `flex` }}>
            <img src="Icon.svg" alt="로고" />
          </div>
          <div
            style={{ marginLeft: `15px`, fontWeight: `800`, fontSize: `20px` }}
          >
            {t("threeMonthsHistoryDoesNotExistText")}
          </div>
        </div>
      </TableTableStyled>
    );
  }

  const totalMonths = getLastThreeMonths();
  const toDayYear = getCurrentDate();

  const newYear = toDayYear.year;

  const month = data?.data[0]?.month?.split("-")[1] ?? "";
  const money = formatNumber(data?.data[0]?.money ?? "0");
  const settlementMoney = formatNumber(data?.data[0]?.settlementMoney ?? "0");

  const month2 = data?.data[1]?.month?.split("-")[1] ?? "";
  const money2 = formatNumber(data?.data[1]?.money ?? "0");
  const settlementMoney2 = formatNumber(data?.data[1]?.settlementMoney ?? "0");

  const month3 = data?.data[2]?.month?.split("-")[1] ?? "";
  const money3 = formatNumber(data?.data[2]?.money ?? "0");
  const settlementMoney3 = formatNumber(data?.data[2]?.settlementMoney ?? "0");

  return (
    <TableTableStyled className="">
      <div
        className=""
        style={{
          width: `100%`,
        }}
      >
        <table>
          <colgroup>
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <MyPageMoneyTableTableRowStyled>
              <th scope="col"></th>
              <th scope="col">{t("amountOfSponsorShipText")}</th>
              <th scope="col">{t("settlementAmountText")}</th>
            </MyPageMoneyTableTableRowStyled>
          </thead>

          <tbody>
            <MyPageMoneyTableTableRowStyled>
              <th scope="row">
                {newYear}. {totalMonths.one}.
              </th>
              <MyPageMoneyTableTableCellStyled>
                {money} {t("wonText")}
              </MyPageMoneyTableTableCellStyled>
              <MyPageMoneyTableTableCellStyled>
                {settlementMoney} {t("wonText")}
              </MyPageMoneyTableTableCellStyled>
            </MyPageMoneyTableTableRowStyled>
            <MyPageMoneyTableTableRowStyled>
              <th scope="row">
                {newYear}. {totalMonths.two}.
              </th>
              <MyPageMoneyTableTableCellStyled>
                {money2} {t("wonText")}
              </MyPageMoneyTableTableCellStyled>
              <MyPageMoneyTableTableCellStyled>
                {settlementMoney2} {t("wonText")}
              </MyPageMoneyTableTableCellStyled>
            </MyPageMoneyTableTableRowStyled>

            <MyPageMoneyTableTableRowStyled>
              <th scope="row">
                {newYear}. {totalMonths.three}.
              </th>
              <MyPageMoneyTableTableCellStyled>
                {money3} {t("wonText")}
              </MyPageMoneyTableTableCellStyled>
              <MyPageMoneyTableTableCellStyled>
                {settlementMoney3} {t("wonText")}
              </MyPageMoneyTableTableCellStyled>
            </MyPageMoneyTableTableRowStyled>
          </tbody>
        </table>
      </div>
    </TableTableStyled>
  );
};
