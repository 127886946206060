import api from "../../../../Repository/Interceptor";

export const getEstimatedAmount = async () => {
  try {
    const resp = await api.get("/mypage", {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
    });
    return resp.data;
  } catch (error) {
    console.log("정산예정 금액 오류", error);
    return " Fail";
  }
};

export const getEstimatedAmount2 = async (month) => {
  try {
    const resp = await api.get("/mypage", {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
      params: {
        page: 1,
        month: month,
      },
    });
    return resp.data;
  } catch (error) {
    console.log("정산예정 금액 오류", error);
    return " Fail";
  }
};
