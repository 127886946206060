import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterBackColor = styled.div`
  background-color: #fff;
`;

export const TextColor = styled.div`
  font-size: 14px;
  line-height: 1.6;
  color: #292929;
`;

export const TextLiColor = styled.li`
  font-size: 14px;
  line-height: 1.6;
  color: #292929;
`;

export const BoxMargin = styled.div`
  margin-top: 15px;
`;

const ReFooter = ({ termsLink, personalInfoLink }) => {
  const { t } = useTranslation();
  const languageData = useSelector((state) => state.languageType.type);

  return (
    <footer>
      <FooterBackColor className="card py-3">
        <div className="footer-content">
          <div className="container-fluid">
            <div className="paddingLeft2">
              <div className="">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="footer-loge mb-2">
                      <img
                        src="/xrai.png"
                        className="loge-footer"
                        alt="logo"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="row">
                <BoxMargin className="col-lg-8 order-lg-first  text-lg-start">
                  <div className="paddingLeft">
                    <TextColor>
                      |주|엑스알에이아이 | 대표 : 고봉균 | 사업자등록번호
                      771-87-02154
                      <br></br> 61186 광주광역시 북구 용봉로 77 전남대학교
                      산학협력 2호관 110호 <br></br> TEL 062-530-3470 | FAX
                      062-530-3449 | MAIL xrai.inc@gmail.com
                    </TextColor>
                  </div>
                </BoxMargin>
                <div className="col-lg-4 mb-0 mb-lg-0 order-lg-last">
                  <div className="footer-menu">
                    <div className="paddingRight">
                      <ul className="nav justify-content-center justify-content-lg-end">
                        <li className="nav-item">
                          <a
                            className="nav-link link-light"
                            href="https://xrai-api.com/page_btLQ36"
                          >
                            <TextColor>
                              {t("companyIntroductionText")}
                            </TextColor>
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link to={termsLink} className="nav-link link-light">
                            <TextColor>{t("termsOfServiceText")}</TextColor>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={personalInfoLink}
                            className="nav-link link-light"
                          >
                            <TextColor>{t("privacyPolicyText")}</TextColor>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="paddingRight">
                    <TextColor className="copyright text-lg-end">
                      통신판매번호: 2021-광주북구-1398
                      <br></br>
                      <svg
                        style={{ width: "16px" }}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="copyright"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 448c-110.532 0-200-89.451-200-200 0-110.531 89.451-200 200-200 110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200zm107.351-101.064c-9.614 9.712-45.53 41.396-104.065 41.396-82.43 0-140.484-61.425-140.484-141.567 0-79.152 60.275-139.401 139.762-139.401 55.531 0 88.738 26.62 97.593 34.779a11.965 11.965 0 0 1 1.936 15.322l-18.155 28.113c-3.841 5.95-11.966 7.282-17.499 2.921-8.595-6.776-31.814-22.538-61.708-22.538-48.303 0-77.916 35.33-77.916 80.082 0 41.589 26.888 83.692 78.277 83.692 32.657 0 56.843-19.039 65.726-27.225 5.27-4.857 13.596-4.039 17.82 1.738l19.865 27.17a11.947 11.947 0 0 1-1.152 15.518z"
                        ></path>
                      </svg>
                      2023. XRAI co.,Ltd. ALL RIGHTS RESERVED.
                    </TextColor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterBackColor>
    </footer>
  );
};

export default ReFooter;
