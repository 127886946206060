import api from "../../../../Repository/Interceptor";

export const getStateDonator = async () => {
  try {
    const resp = await api.get("/dashboard/stat/donetor", {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
    });

    return resp;
  } catch (error) {
    console.log("최근 후원자 정보 가져오기", error);
    return "Fail";
  }
};
