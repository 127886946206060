import styled, { keyframes } from "styled-components";
import ReFooter from "../../../common/Footer/screen/ReFooter";
import ReKaKaoButton from "../../../common/Header/components/reKakaoButton";

import {
  BackgroundStyledDiv,
  CoinDivBox,
  DonateBox,
  DonateCoinButton,
  DonateFlexCenterColumnBox,
  DonateFlexCenterColumnBox2,
  DonateInfoTextBox,
  DonateTextarea,
  DonateWHBox,
  DonateWHIn2Box,
  DonateWHIn3Box,
  DonateWHIn4Box,
  DonateWHIn5Box,
  DonateWHIn6Box,
  DonateWHIn7Box,
  DonateWHIn8Box,
  DonateWHIn9Box,
  DonateWHInBox,
  FlexBoxDiv,
  HtmlCodeBox,
  HtmlCodeInBox,
  InputTextBox,
  LogoBlackBox,
  ProfileBorderBox2Div,
  ProfileBorderBox3Div,
  ProfileBorderBox4Div,
  ProfileBorderBoxDiv,
  ProfileImgBox,
  ProfileLogoBox,
  TopMarginDiv,
  TopSpaceDiv,
  TotalCoinCard,
  TotalText,
  TryBox,
  TryBox2,
  TryBox3,
} from "../css/DontaionStyled";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../common/redux/action/languageAction";
import { Toggle } from "../../../util/toggle";
import { useTranslation } from "react-i18next";
import { DonationRepo } from "../repo/DontaionRepo";
import { PayPalPage } from "../../paymentPage/Paypal";
import { EmailModal } from "../components/EmailModal";

export const DonationRenewalPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [dontaionToken, setDonationToken] = useState(null);
  const [sponsor, setSponsor] = useState();
  const [dontaionData, setDonationData] = useState(null);
  const [isSponsorName, setSponsorName] = useState("");
  const languageData = useSelector((state) => state.languageType.type);
  const { uuid } = useParams();
  const [shouldRender, setShouldRender] = useState(false);

  const is_mobile = () => {
    // console.log(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  useEffect(() => {}, [dontaionToken]);

  const handleSponsorName = (e) => {
    setSponsorName(e.target.value);
  };

  useEffect(() => {
    dispatch(setLanguage(i18n.language));
    console.log("지금 언어가 뭐지?", i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const fetchData = async () => {
      if (uuid) {
        try {
          const resp = await DonationRepo(uuid);
          setDonationData(resp.data.data);
          setDonationToken(resp.data.data.token);
        } catch (error) {
          console.log("도네이션페이지 정보가져오기 실패", error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {}, [dontaionData]);

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "ko" ? "en" : "ko";
    i18n.changeLanguage(newLanguage);
  };

  const [isCoin, setCoin] = useState(0);
  const [isText, setText] = useState("");

  const handleChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 0) {
      setCoin(value);
    }
  };
  const handleCoin = (won) => {
    setCoin((prevCoin) => Number(prevCoin) + won);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleButtonClick = () => {
    if (isCoin <= 0) {
      alert("후원 금액은 최소 1,000원 이상이어야 합니다.");
      return;
    }

    if (isCoin % 1000 !== 0) {
      alert("후원 금액은 최소 1000원 단위로 입력해주세요.");
      return;
    }

    if (isText.length > 50) {
      alert("하고 싶은 말은 최대 50자까지 입력할 수 있습니다.");
      return;
    }

    const encodedSponsor = isSponsorName
      ? encodeURIComponent(isSponsorName)
      : "";
    const url = `/payment?name=${encodedSponsor}&amount=${isCoin}&memo=${encodeURIComponent(
      isText
    )}&donaToken=${encodeURIComponent(dontaionToken)}`;
    window.open(
      url,
      "팝업 이름",
      "width=530,height=718,left=00,top=100,resizable=no"
    );
  };

  const CharCount = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 0.9rem;
    color: gray;
  `;
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    const handler = setTimeout(() => {
      // 일정 시간 동안 입력이 없으면 컴포넌트를 리렌더링합니다.
      setRenderKey((prevKey) => prevKey + 1);
    }, 200);

    // 클린업 함수에서 타이머를 초기화합니다.
    return () => {
      clearTimeout(handler);
    };
  }, [isText, isCoin, isSponsorName]);

  const handleImgError = (event) => {
    event.target.src = "/group.svg";
  };

  return (
    <BackgroundStyledDiv>
      <TryBox />
      <TryBox2 />
      <TryBox3 />
      <div style={{ height: `5rem` }}></div>
      <TopMarginDiv>
        <TopSpaceDiv>
          <div style={{ marginBottom: `3rem` }}>
            <img src="/group.svg" alt="" style={{ marginRight: `1rem` }} />
            {languageData === "en" ? (
              <img
                src="/GroupEn.svg"
                style={{
                  width: `200px`,
                }}
              ></img>
            ) : (
              <img src="/LogoBlack.svg"></img>
            )}
          </div>
          <div>
            <div style={{ display: `flex` }}>
              <div
                style={{
                  display: `flex`,
                  marginRight: `2rem`,
                  marginTop: `0.2rem`,
                }}
              >
                <Toggle defaultChecked={false} onChange={toggleLanguage} />
              </div>
              {languageData === "en" ? (
                <EmailModal />
              ) : (
                <div style={{ width: `10rem`, display: `flex` }}>
                  <ReKaKaoButton />
                </div>
              )}
            </div>
          </div>
        </TopSpaceDiv>
        <FlexBoxDiv>
          <ProfileBorderBoxDiv>
            <ProfileBorderBox2Div>
              <ProfileBorderBox3Div>
                <ProfileBorderBox4Div>
                  <div>
                    {dontaionData?.profileImg ? (
                      <ProfileImgBox
                        src={dontaionData?.profileImg}
                        onError={handleImgError}
                      />
                    ) : (
                      <ProfileImgBox src="/group.svg" />
                    )}
                    <ProfileLogoBox>
                      <img
                        src="/group.svg"
                        style={{
                          height: `35px`,
                          width: `35px`,
                          marginRight: `10px`,
                        }}
                      />
                      {dontaionData?.name}
                    </ProfileLogoBox>
                  </div>
                </ProfileBorderBox4Div>
              </ProfileBorderBox3Div>
            </ProfileBorderBox2Div>
            {dontaionData?.content ? (
              <HtmlCodeBox>
                <HtmlCodeInBox
                  dangerouslySetInnerHTML={{ __html: dontaionData?.content }}
                />
              </HtmlCodeBox>
            ) : (
              <HtmlCodeBox>
                <HtmlCodeInBox>
                  <LogoBlackBox>
                    {languageData === "en" ? (
                      <img
                        src="/GroupEn.svg"
                        style={{
                          width: `350px`,
                        }}
                      ></img>
                    ) : (
                      <img src="/LogoBlack.svg"></img>
                    )}
                    <DonateInfoTextBox>{t("donateInfoText")}</DonateInfoTextBox>
                  </LogoBlackBox>
                </HtmlCodeInBox>
              </HtmlCodeBox>
            )}
          </ProfileBorderBoxDiv>
        </FlexBoxDiv>

        <DonateBox>
          <DonateWHBox>
            <DonateWHInBox>
              <DonateWHIn2Box>
                <DonateWHIn3Box>{t("optionalText")}</DonateWHIn3Box>
                <div>
                  <DonateWHIn4Box>{t("supporterText")}</DonateWHIn4Box>
                  <input
                    type="text"
                    placeholder={t("supporterInfoText")}
                    style={{
                      width: `100%`,
                    }}
                    className="form-control"
                    value={isSponsorName}
                    onChange={handleSponsorName}
                  ></input>
                </div>
                <div>
                  <DonateWHIn4Box>{t("messageText")}</DonateWHIn4Box>
                  <DonateWHIn5Box>
                    <DonateTextarea
                      className="form-control"
                      value={isText}
                      placeholder={t("messageInfoText")}
                      onChange={handleTextChange}
                      maxLength={50}
                    />
                    <CharCount>{isText.length}/50</CharCount>
                  </DonateWHIn5Box>
                </div>
              </DonateWHIn2Box>
            </DonateWHInBox>
          </DonateWHBox>
          <div style={{ width: `10px` }}></div>
          <CoinDivBox>
            <DonateFlexCenterColumnBox>
              <DonateWHIn3Box>{t("donateText")}</DonateWHIn3Box>
              <DonateWHIn6Box>{t("amountOfSponsorShipText")}</DonateWHIn6Box>
              <DonateWHIn7Box>
                <DonateCoinButton
                  onClick={() => {
                    if (languageData === "en") {
                      handleCoin(1);
                    } else {
                      handleCoin(1000);
                    }
                  }}
                >
                  {t("money1000")}
                </DonateCoinButton>
                <div style={{ width: `1rem` }}></div>
                <DonateCoinButton
                  onClick={() => {
                    if (languageData === "en") {
                      handleCoin(50);
                    } else {
                      handleCoin(5000);
                    }
                  }}
                >
                  {t("money5000")}
                </DonateCoinButton>
              </DonateWHIn7Box>
              <DonateWHIn8Box>
                <DonateCoinButton
                  onClick={() => {
                    if (languageData === "en") {
                      handleCoin(10);
                    } else {
                      handleCoin(10000);
                    }
                  }}
                >
                  {t("money10000")}
                </DonateCoinButton>
                <div style={{ width: `1rem` }}></div>

                <DonateCoinButton
                  onClick={() => {
                    if (languageData === "en") {
                      handleCoin(50);
                    } else {
                      handleCoin(50000);
                    }
                  }}
                >
                  {t("money50000")}
                </DonateCoinButton>
              </DonateWHIn8Box>
              <TotalCoinCard>
                <DonateWHIn9Box
                  style={{
                    justifyContent: `center`,
                    alignItems: `center`,
                    display: `flex`,
                    flexDirection: `column`,
                  }}
                >
                  <DonateFlexCenterColumnBox2>
                    <strong
                      style={{
                        fontSize: `16px`,
                        marginTop: `0.5rem`,
                      }}
                    >
                      {dontaionData?.name}
                      {t(`toText`)}
                    </strong>
                    <InputTextBox
                      type="number"
                      id="userId"
                      value={isCoin}
                      onChange={handleChange}
                    ></InputTextBox>{" "}
                    <TotalText>{t("wonText")}</TotalText>
                  </DonateFlexCenterColumnBox2>
                  {languageData === "en" ? (
                    <div
                      style={{
                        width: `20rem`,
                      }}
                    >
                      {dontaionToken && (
                        <div key={renderKey}>
                          <PayPalPage
                            money={isCoin}
                            memo={isText}
                            name={isSponsorName}
                            token={dontaionToken}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <button
                      className="btn btn-lg btn-primary"
                      style={{ width: `100%`, marginTop: `10px` }}
                      onClick={handleButtonClick}
                    >
                      후원하기
                    </button>
                  )}
                </DonateWHIn9Box>
              </TotalCoinCard>
            </DonateFlexCenterColumnBox>
          </CoinDivBox>
        </DonateBox>
        <div style={{ marginTop: `5rem` }}>
          <ReFooter />
        </div>
        <br></br>
      </TopMarginDiv>
    </BackgroundStyledDiv>
  );
};
