import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/povisionsDon.css";
import Footer from "../../Footer/screen/Footer";
const ProvidsionsCrEn = () => {
  return (
    <div>
      <div className="provisionsText">
        <p className="mb-3 Pr-Title">
           Hanzan erms and Conditions (for Creators)
        </p>
        <p className="mb-0 provisSubText">1. Purpose</p>
        <p className="mb-3">
          {" "}
           These terms and conditions apply to creators (bloggers, YouTubers,
          broadcasters, streamers, etc., hereinafter referred to as "members")
          using the “Sponsored Drink Service” provided by XRAI Co., Ltd.
          (hereinafter referred to as the "Company") on the Internet. The
          purpose is to regulate rights, duties, and responsibilities among
          members.
        </p>
        <p className="mb-0 provisSubText">
          2. Specification, Effect, and Revision of Terms and Conditions{" "}
        </p>
        <p className="mb-0">
           1. These terms and conditions are displayed on the service
          registration screen for easy understanding by members, and they become
          effective for members who wish to use this service.
        </p>
        <p className="mb-0">
           2. The Company ensures that these Terms and Conditions comply with
          relevant laws such as the Act on the Adjustment of Terms and
          Conditions, the Act on Promotion of Information and Communications
          Network Utilization and Information Protection, etc., the Consumer
          Protection Act in e-commerce, and the Content Industry Promotion Act.
          These Terms and Conditions may be amended to the extent permitted by
          law.
        </p>
        <p className="mb-0">
           3. When the company revises the terms and conditions, it specifies
          the revised terms and conditions and their effective date, announcing
          them on the service page at least 7 days before the effective date and
          for a significant period thereafter. For revisions that disadvantage
          members, the company announces them 30 days before the effective date,
          separately notifying existing members of the revised terms and
          conditions, effective date, and reasons for revision.
        </p>
        <p className="mb-3">
           4. If a member does not express rejection of the revised Terms and
          Conditions despite clear notification, failure to do so within 7 days
          after the revised Terms and Conditions take effect from the
          notification date will be deemed as approval. The member is considered
          to have accepted the revised Terms and Conditions. If the member does
          not agree to the revised terms and conditions, the member may
          terminate the service agreement.
        </p>
        <p className="mb-0 provisSubText">
          3. Interpretation and Application of Terms and Conditions
        </p>
        <p className="mb-0">
           1. Matters not specified in these Terms and Conditions shall be
          governed by separate detailed terms and conditions, company notices,
          usage guidelines, and relevant laws and regulations.
        </p>
        <p className="mb-3">
           2. When a member uses the service by entering into an individual
          contract with the company, the individual contract takes precedence
          over these terms and conditions.
        </p>
        <p className="mb-0 provisSubText">4. Definition of Terms</p>
        <p className="mb-0">
           1. “Hanzan Service” (hereinafter referred to as “Service”) refers to
          donations received from an unspecified number of sponsors and viewers
          who participate in blogs, YouTube, and other broadcasting channels
          operated by members through sites operated by the company. This refers
          to services provided for members to receive.
        </p>
        <p className="mb-0">
           2. “Sponsorship amount” refers to the amount sponsored by a
          participant or viewer directly by payment.
        </p>
        <p className="mb-0">
           3. “Service fee” refers to the compensation for services provided by
          the company to members.
        </p>
        <p className="mb-3">
           4. “Settlement amount” refers to the amount paid by the company to
          the member after deducting the service fee and payment agency fee from
          the “Sponsorship amount.”
        </p>
        <p className="mb-3">
           5. “Payment institution” refers to a telecommunication company,
          credit card company, issuing institution of prepaid payment means,
          etc., and “payment agency” refers to an institution or company that
          acts as an agent for payment between the relevant payment institution
          and the company.
        </p>
        <p className="mb-0 provisSubText">
          5. Contents of Payment Services and Obligations of the Company and
          Members, etc.
        </p>
        <p className="mb-0">
           1. The company's principle is to provide services to members at all
          times.
        </p>
        <p className="mb-0">
           2. The company’s obligations are as follows: 1) This includes
          technical measures to provide services on a regular basis, such as
          constructing, improving, and linking the Hanzan service system.
          However, in the event of any of the following reasons, the provision
          of part or all of the service may be temporarily suspended without
          notice, and members will be informed accordingly: 가) When unavoidable
          due to urgent system inspection, expansion, replacement, repair, or
          construction required by the company. 나) When the company deems it
          necessary to introduce a new service system. 다) When service cannot
          be provided due to other failures of service facilities, network
          failures, etc. 라)  In case of force majeure beyond the company’s
          control, such as natural disasters or national emergencies. 2) Prompt
          recovery in case of company system failures. 3) Settlement of member’s
          donation amount.
        </p>
        <p className="mb-0">
           3. Member obligations are as follows: 1) Payment of service fees
          provided by the company. 2) Members must provide accurate information
          when registering to use the service. If a member registers using false
          or someone else's information, they cannot claim any rights against
          the company, and the company is not responsible for any resulting
          damages. 3) Registration of settlement information (name, account
          number, etc.) through the method provided by the company or through a
          separately contracted third-party consignment service. 4) Compliance
          with relevant laws such as the Youth Protection Act.
        </p>
        <p className="mb-0">
           4. Members must not receive or induce sponsorship in the following
          ways: 1) Soliciting donations for the purpose of selling a specific
          product. 2) Receiving donations with the intention of refunding the
          amount to the sponsor after settlement. 3) Receiving or inducing
          donations using content that violates current laws and regulations.
        </p>
        <p className="mb-3">
           5. If any of the reasons stated in the preceding paragraph occur, the
          Company may take the following measures: 1) Termination of this
          Agreement. 2) Cancellation of all transactions resulting from the
          reasons stated in the preceding paragraph and refund of the payment
          amount. 3) Withholding payment of the settlement amount irrespective
          of the settlement provisions of this agreement. 4) Claiming
          compensation from the member if the company incurs damages due to the
          reasons stated in the preceding paragraph.
        </p>
        <p className="mb-0 provisSubText">
          6. Transaction Cancellation and Payment Amount Refund
        </p>
        <p className="mb-0">
           1. Generally, if a sponsor who has accessed the member's content
          requests cancellation or refund of their donation, the member shall
          respond and process the request. However, in exceptional cases where
          there is a separate agreement between the company and the member, or
          there is a relevant law or order from a government agency or an
          equivalent third party, the company may handle the request.
        </p>
        <p className="mb-0">
           2. If a sponsor who has accessed the member's content files a claim
          directly with the company or through a payment institution or related
          organization or association (including claims related to unauthorized
          transactions made by a third party other than the sponsor using the
          payment information), the company will respond as follows: 1) Provide
          payment history data related to the claims. 2) Withhold payment of the
          settlement amount equivalent to the amount related to the claim. 3)
          Temporarily suspend payment service provision or terminate the
          contract. 4) Cancel the payment transaction related to the claim or
          refund the payment amount.
        </p>
        <p className="mb-3">
           3. If a refund of the sponsor's payment amount is processed, the
          company may deduct the refund from the settlement amount that the
          member is supposed to receive from the company. However, if there is a
          separate contract governing the service, the terms of that contract
          will take precedence.
        </p>
        <p className="mb-0 provisSubText">7. Service Fee</p>
        <p className="mb-0">
           1. The service fee, settlement, and payment method of the settlement
          amount pursuant to this contract are specified in the following
          paragraphs. However, the company may adjust the service fee depending
          on fluctuations in sales, policies of the payment institution, etc.
        </p>
        <p className="mb-0">
           2. The settlement period is from the 1st to the last day of each
          month, and settlement occurs on a monthly basis. However, if
          circumstances require a different settlement period due to the
          company's and payment agency's policies, it will adhere to those
          policies.
        </p>
        <p className="mb-0">
           3. Members must register settlement information (settlement contract)
          through procedures provided by the company by the end of the first
          month after receiving sponsorship. Failure to register will result in
          the inability to process settlement.
        </p>
        <p className="mb-0">
           4. If the amount to be settled by a member is less than 40,000 won,
          the company may postpone settlement and add it to the next settlement
          amount.
        </p>
        <p className="mb-0">
           5. If there are discrepancies in sponsorship details between the
          company and the member, they may compare their respective data to
          agree on the settlement amount and may refer to information from the
          payment agency where the payment was made. If agreement is delayed,
          the company may proceed with settlement based on the lesser of the
          settlement amounts claimed by both parties. In such cases, the company
          is not liable for late payment of the settlement amount until
          agreement is reached.
        </p>
        <p className="mb-0">
           6. Upon request, the company must provide the member with information
          related to settlement.
        </p>
        <p className="mb-0">
           7. The settlement amount for the settlement period is due within 10
          business days of the following month. However, if payment fails due to
          reasons attributable to the member, such as missing requested
          documents or providing an incorrect account number, payment will be
          made within 15 business days from the resolution of the issue.
        </p>
        <p className="mb-0">
           8. On the payment due date, the company transfers the settlement
          amount (the sponsored amount minus taxes such as service fees, payment
          agency fees, and income tax) to the account designated by the member.
        </p>
        <p className="mb-0">
           9. If a member is serving in the military, settlement of donations
          during the military service period may be restricted upon separate
          request from the Military Manpower Administration.
        </p>
        <p className="mb-0">
           10. The company may exclude amounts sponsored through payment due to
          illegal use, such as theft, from the member's settlement amount after
          notifying the member via email, phone, etc. Additionally, in cases
          where donation amounts are suspected to have been acquired through
          illegal means, settlement of the donation amount may be canceled
          without prior notice, payment may be refused pending completion of the
          member's explanation of the suspicious situation, or already paid
          amounts may be recovered.
        </p>
        <p className="mb-0">
           11. To facilitate smooth settlement processing, the company must
          enter necessary payment information for settlement, such as account
          information, through methods notified by the company in separate
          notices or user guides. Subsequently, settlement registration must be
          applied for by verifying identity or electronically signing through
          specified methods. Upon completion and approval of all relevant
          procedures, the company and the member are considered to have entered
          into a settlement agreement.
        </p>
        <p className="mb-0">
           12. If a member is a minor or legally incompetent, a settlement
          agreement may be made with consent from a legal representative
          (parent, etc.), and consent to use must be given in accordance with
          methods designated and guided by the company. Failure to do so may
          lead the company to terminate the contract with the member.
        </p>
        <p className="mb-3">
           13. Donations will automatically expire if there has been no service
          usage history (access history) for 60 months from the date of the last
          donation use.
        </p>
        <p className="mb-0 provisSubText">8. Contract Period</p>
        <p className="mb-3">
           This agreement is effective from the date of conclusion until the
          member withdraws membership. However, if the member is unable to
          directly express intent to terminate this contract, their legal
          representative may terminate the contract with the company on their
          behalf.
        </p>{" "}
        <p className="mb-0 provisSubText">9. Notification to Members </p>
        <p className="mb-0">
           1. The company may notify members via email, SMS, etc., provided by
          the member.
        </p>
        <p className="mb-0">
           2. In the case of notifications to an unspecified number of members,
          the company may substitute individual notifications with bulletin
          board notices or pop-up notices.
        </p>
        <p className="mb-3">
           3. Even if the company has properly notified the member as described
          above, the company is not liable for any disadvantages incurred by the
          member due to the member's failure to confirm the content of the
          notice.
        </p>
        <p className="mb-0 provisSubText">10. Restrictions on Service Use</p>
        <p className="mb-0">
           1. The company may restrict or suspend the provision of services to
          members under any of the following circumstances: 1) Termination of
          the agreement related to service provision between the company and
          members. 2) Member's interference with the normal operation of the
          service by violating obligations stipulated in these Terms and
          Conditions. 3) Registration using stolen identity or incorrect
          information by the member. 4) Illegal payment, such as using someone
          else's payment credentials or mobile number theft during paid
          transactions. 5) Violation of relevant laws, including the Information
          and Communications Network Act, by providing or interfering with
          illegal programs, illegal communication, hacking, distribution of
          malicious software, or exceeding access rights. 6) Account dormancy
          due to member's failure to log in for one year. 7) Unresolved disputes
          between members or between the company and members.
        </p>
        <p className="mb-0">
           2. If service use is restricted as per Paragraph 1 of this Article,
          the company may nullify benefits and rights acquired by the member,
          and no separate compensation will be provided. However, donations
          obtained by members without violating these Terms and Conditions or
          relevant laws will be settled and paid as usual.
        </p>
        <p className="mb-3">
           3. Membership may be terminated or suspended for up to one year by
          notifying members whose service use has been restricted or suspended
          as per Paragraph 1 of this Article.
        </p>
        <p className="mb-0 provisSubText">
          11. Contract Cancellation and Termination
        </p>
        <p className="mb-0">
           1. The company or member may terminate this agreement under any of
          the following circumstances: 1) Member's violation of obligations
          stipulated in these Terms and Conditions or engagement in acts
          warranting service use restriction. The company will request
          correction, and if the member fails to rectify the violation within 7
          business days, the contract may be terminated. However, for severe
          violations, the contract may be terminated without correction request.
          2) Inability to maintain the contract due to commencement of
          preservation disposition decisions, compulsory execution, delinquency
          procedures, rehabilitation procedures, bankruptcy procedures, or
          similar legal procedures affecting major assets of the company or
          member. 3) Legal deficiencies such as lack of business-related
          licenses for the company or member. 4) Emergence of other serious
          reasons making it difficult to maintain this agreement.
        </p>
        <p className="mb-3">
           2. Termination under this Article does not affect claims and demands
          for damages that have already arisen.
        </p>
        <p className="mb-0 provisSubText">12. Prohibition of Transfer, etc.</p>
        <p className="mb-3">
           The company and members may not transfer or assign any rights or
          obligations under this Agreement to a third party without prior
          written consent from the other party.
        </p>
        <p className="mb-0 provisSubText">
          13. Collection, Use, and Protection Obligations of Personal
          Information
        </p>
        <p className="mb-0">
           1. The company collects and uses personal information to facilitate
          smooth service provision. For this purpose, separate consent is
          obtained from members.
        </p>
        <p className="mb-3">
           2. The company endeavors to protect members' personal information in
          accordance with relevant laws such as the Information and
          Communications Network Act. The collection, protection, and use of
          personal information are subject to applicable laws and the company's
          personal information processing policy
        </p>
        <p className="mb-0 provisSubText">
          14. Service Management Responsibility
        </p>
        <p className="mb-3">
           The service management officer of the company is: 1. Name: Seungseok
          Lee 2. Position: Director 3. Contact: dltmdtjr9683@xrai-api.com
        </p>
        <p className="mb-0 provisSubText">
          15. Attribution of Copyright and Restrictions on Use
        </p>
        <p className="mb-0">
           1. Copyright and other intellectual property rights for works created
          by the company belong to the company.
        </p>
        <p className="mb-3">
           2. Members must not use information obtained during the use of the
          service for commercial purposes or allow third parties to use such
          information obtained by copying, transmitting, publishing,
          distributing, broadcasting, or other methods without prior consent
          from the company.
        </p>
        <p className="mb-0 provisSubText">16. Posting Rights/Management </p>
        <p className="mb-0">
           1. Members are responsible for legal actions and consequences in
          disputes with third parties, such as copyright or moral rights related
          to postings provided to the service, and the company bears no
          responsibility for postings.
        </p>
        <p className="mb-0">
           2. If a member's posting violates the Information and Communications
          Network Act, Copyright Act, or other relevant laws, the rights holder
          may request suspension or deletion of the posting in accordance with
          procedures set forth in relevant laws, and the company must take
          necessary actions according to the laws and regulations.
        </p>
        <p className="mb-0">
           3. If a problem is identified with a member's posting, the company
          may delete, move, or refuse registration without prior notice to the
          member.
        </p>
        <p className="mb-3">
           4. Posts by members may be exposed through the company's services and
          related promotions, and may be modified, copied, or edited to the
          extent necessary for such exposure. In this case, the company complies
          with copyright law, and members may request actions such as deletion
          of the post, exclusion from search results, or making it private at
          any time.
        </p>
        <p className="mb-0 provisSubText">17. Google API Policy</p>
        <p className="mb-3">
           Your use and transfer of information received from Google APIs to
          other apps comply with the Google API Services User Data Policy,
          including acceptable use requirements.
        </p>{" "}
        <p className="mb-0 provisSubText">18. Termination of Service</p>
        <p className="mb-0">
           1. If the company wishes to terminate the service, it will notify
          members individually or collectively at least 3 months prior to the
          date of termination of the service.
        </p>
        <p className="mb-3">
           2. Upon termination of the service, the company will destroy personal
          information held in accordance with relevant laws and regulations.
        </p>
        <p className="mb-0 provisSubText">19, Jurisdiction and Governing Law</p>
        <p className="mb-0">
           1. If a dispute arises between the company and the member regarding
          the use of the service, an amicable resolution must be sought between
          the parties.
        </p>
        <p className="mb-0">
           2. If the dispute cannot be resolved amicably and a lawsuit is filed,
          the competent court shall be determined in accordance with relevant
          laws and regulations.
        </p>
        <p className="mb-3">
           3. Lawsuits between the company and users shall be governed by the
          laws of the Republic of Korea.
        </p>
      </div>
      <Footer termsLink="/termosfuseCr" />
    </div>
  );
};

export default ProvidsionsCrEn;
