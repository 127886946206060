import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/povisionsDon.css";
import Footer from "../../Footer/screen/Footer";

const ProvisionsDon = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="provisionsText">
        <p className="mb-3 Pr-Title"> 후원한잔 유료서비스 이용약관 </p>
        <p className="mb-3">
           결제는 반드시 본인명의로 결제해야하며, 타인 명의를 도용하여 결제하는
          경우 민·형사상의 책임을 물을 수 있습니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 1조 목적</p>
        <p className="mb-3">
           본 약관은 주식회사 엑스알에이아이(이하 ‘회사’라고 합니다.)의
          크리에이터 후원 서비스 후원한잔 서비스 이용을 위한 이용조건 및 절차에
          관한 사항을 규정함을 목적으로 합니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 2조 약관의 명시, 효력 및 개정</p>
        <p className="mb-0">
           1. 본 약관은 회원이 쉽게 알 수 있도록 서비스 가입화면에 게시하며, 본
          서비스를 사용하고자 하는 회원에 대하여 그 효력을 발생합니다.{" "}
        </p>
        <p className="mb-0">
           2. 회사는 본 약관을 “약관의 구제에 관한 법률”, “정보통신망 이용 촉진
          및 정보보호 등에 관한 법률”, 전자상거래에서 “소비자 보호 관련 법률”,
          “콘텐츠 산업 진흥법” 등 관련 법령을 위배하지 않는 범위에서 본 약관을
          개정할 수 있습니다.{" "}
        </p>
        <p className="mb-0">
           3. 회사가 약관을 개정하는 경우 개정된 약관 내용과 시행일을 정하여
          시행일로부터 최소 7일 이전부터 시행일 이후 상당기간 서비스 페이지에
          공지하고 회원에게 불리한 약관 개정일 경웅 30일 전부터 공지합니다. 기존
          회원에게는 개정된 약관, 적용일자 및 사유를 기존 회원에게 별도로
          공지합니다.{" "}
        </p>
        <p className="mb-3">
           4. 회원은 공지일로부터 개정된 약관 시행일 7일 후까지 거부의사를
          표하지 않을 경우 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도
          불구하고 거부 의사표시를 하지 않는 경우 개정약관에 승인한 것으로
          간주합니다. 회원이 개정약관에 동의하지 않은 경우 회원은 이용계약을
          해지 할 수 있습니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 3조 약관 해석 및 적용 </p>
        <p className="mb-0">
           1. 본 약관의 계약에 명시되지 않는 사항에 대해서는 별도의 세부 약관,
          회사 공지, 이용안내, 관계법령에서 정한 바에 따릅니다.{" "}
        </p>
        <p className="mb-3">
           2. 회원이 회사와 개별계약을 서비스를 체결하여 서비스 이용시
          개별계약이 본 약관보다 우선됩니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 4조 용어 정의 </p>
        <p className="mb-0">
           1. “후원 금액”은 회원에게 참여자 혹은 시청자가 직접 결제하여 후원한
          금액을 말합니다.{" "}
        </p>
        <p className="mb-0">
           2. “결제”는 후원을 진행할 경우 회사에서 제공하는 결제 방식을 통해
          일정 금액 단위로 구입하는 것을 말합니다.{" "}
        </p>
        <p className="mb-0">
           3. “유료 서비스”는 회원이 회사가 제공하는 결제 방식을 통해 책정된
          요금을 결제하여 후원하는 서비스를 말합니다.{" "}
        </p>
        <p className="mb-3">
           4. 유료 서비스를 이용하는 회원은 서비스 이용 요금을 신청 후 즉시
          결제해야 합니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 5조 요금 결제 </p>
        <p className="mb-0">
           1. 후원금액 결제는 신용카드, 휴대폰 결제, 간편 결제 등 회사에서
          제공하는 결제방식을 통해서 가능합니다. 단, 결제수단을 운영하는
          사업자가 별도로 있는 경우, 회원은 해당 결제수단을 이용하기 전에 해당
          결제수단의 사업자가 제시하는 절차를 이행해야 합니다. 회사의 서비스
          이용요금을 결제할 때에는 해당 결제수단의 사업자가 제시하는 절차 이행
          및 이용약관에 동의한 것으로 간주합니다.{" "}
        </p>
        <p className="mb-0">
           2. 후원금액 결제는 회사의 내부 정책에 의해 제공되는 단위로 충전할 수
          있으며, 이때 선택된 결제수단별로 충전 단위가 상이할 수 있습니다.{" "}
        </p>
        <p className="mb-0">
           3. 만 19세 미만의 미성년자가 신용카드, 휴대폰 결제, 실시간 계좌이체,
          무통장 결제수단 등의 포인트류를 이용하여 후원금액을 결제할 때에는
          법정대리인의 동의를 받아야 하며 결제시 최대 월 7만원까지의 요금이
          부과될 수 있습니다. 단, 결제수단 운영 회사나 정부의 방침 등에 따라 각
          결제수단별로 결제 한도가 부여될 수 있으며, 또한 회사는 특정 결제수단의
          결제 한도를 부여할 수 있습니다.{" "}
        </p>
        <p className="mb-0">
           4. 만 19세 미만의 미성년자가 회사가 지정한 결제수단을 이용하여 정기
          결제 서비스를 결제할 때에 또한 법정대리인의 동의를 받아야 하며 결제시
          최대 월 10만원 까지의 요금이 부과될 수 있습니다. 단, 결제수단의 운영
          회사나 정부 방침 등에 따라 각 결제수단별로 결제 한도가 부여될 수
          있으며, 회사 또한 특정 결제수단의 결제 한도를 부여할 수 있습니다.{" "}
        </p>
        <p className="mb-0">
           5. 제3항 및 제4항의 미성년자 결제에 대한 동의를 위해 법정대리인은
          회사의 안내에 따라 “휴대폰 인증”으로 일괄 동의할 수 있으며,
          법정대리인의 동의가 완료된 이후 회사는 법정대리인에게 이메일을 통해
          결제동의 사실 및 그 내역을 고지합니다. 미성년자인 회원이 그
          법정대리인의 동의를 받지않고 요금 결제 등 의무부담 행위를 한 경우,
          미성년자인 회원 또는 그 미성년자의 법정대리인은 관련 법령에 따라 해당
          계약을 취소할 수 있습니다. 단, 미성년자의 사술로 인한 경우는
          제외됩니다.{" "}
        </p>
        <p className="mb-3">
           6. 4항의 동의서 유효기간에 대해 미성년자의 법정대리인은 회사의 안내에
          따라 “1개월/6개월/1년/3년/5년/탈퇴 시까지”의 유효기간 중 선택할 수
          있으며, 회사는 법정대리인이 선택한 동의 유효기간 만료 시점으로부터
          30일 전에 법정대리인에게 이메일을 통해 통지하여 동의 연장 여부를
          확인하며, 이에 대해 법정대리인의 동의 철회 요구등이 없을 경우 동의
          유효기간은 동일한 조건으로 자동 연장됩니다. 단, 법정 대리인이 동의
          유효기간으로 1개월을 선택한 경우, 회사는 법정대리인에게 그 동의
          유효기간 만료 시점 7일전에 고지합니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 6조 결제 승인 </p>
        <p className="mb-0">
           1. 회사는 다음에 각 항에 해당하는 이용 신청에 대해서는 승인을 하지
          않거나, 추후 해당 승인을 취소할 수 있습니다. 1) 필수적 기재 사항을
          누락 또는 오기했거나 필수적 절차가 미비한 경우 2) 이용 요금을 결제하지
          않거나 결제자를 확인할 수 없는 경우 3) 만 19세 미만의 미성년자가
          법정대리인의 동의없이 신청한 경우 4) 명의자의 동의나 승낙 없이 결제
          수단을 무단 도용하여 이용요금을 결제한 경우 5) 그 밖에 회원의
          귀책사유로 승인이 불가능하다고 판단되는 경우{" "}
        </p>
        <p className="mb-3">
           2. 회사는 다음의 각 항에 해당하는 경우에는 이용 신청에 대한 승인 제한
          사유가 해소될 때까지 승인을 제한할 수 있습니다. 1) 서비스 설비가
          부족하여 만족스러운 서비스를 제공할 수 없다고 판단될 경우 2) 서비스
          상의 장애가 발생한 경우{" "}
        </p>
        <p className="mb-0 provisSubText">  제 7조 결제 금액 환불 </p>
        <p className="mb-0">
           1. 후원자가 결제한 금액을 환불하고자 하는 경우 적법한 절차에 따라
          환불 받을 수 있습니다. 단 회원의 단순 변심에 의한 환불은 불가합니다.{" "}
        </p>
        <p className="mb-0">
           2. 후원자는 먼저 후원했던 크리에이터에게 문의 하여 환불 요청을
          진행하며 해당 크리에이터가 회사에 환불 요청하여 환불을 받을 수
          있습니다.{" "}
        </p>
        <p className="mb-0">
           3. 주민등록법, 저작권법, 컴퓨터프로그램보호법, 정보통신망법 등 각종
          법령에 대한 중대한 불법행위와 같이 후원한잔 운영에 심각한 악영향을
          미치는 행위를 한 것으로 판단되는 후원자의 경우 회사는 결제금액을
          환불하지 않을 수 있습니다. 단, 후원자가 불법행위 또는 후원한잔에
          심각한 악영향을 끼치는 행위르 하지 않았다는 것을 소명하는 경우에는
          그러하지 않습니다.{" "}
        </p>
        <p className="mb-3">
           4. 위 항목에 따라 환불이 가능한 경우에도 후원자가 결제시 이용한 특정
          결제수단을 제공하는 결제대행사 혹은 금융사의 요청이 있을 경우 환불이
          제한되거나 보류될 수 있습니다. 이에 해당하는 결제수단의 경우 별도
          안내를 통해 확인할 수 있으며, 회사는 특정 결제수단에 대한 결제대행사
          혹은 금융사의 정책 변경이 있을 경우 즉각 고지, 안내합니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 8조 개인정보 보호 </p>
        <p className="mb-0">
           1. 회사는 유료 서비스와 관련한 회원의 정보를 본 이용계약상의 서비스
          제공을 위한 목적으로만 사용하며 본인의 사전 동의 없이 제 3자에게 누설
          또는 배포할 수 없습니다. 그러나 다음 각 호에 해당하는 경우에는 그렇지
          않습니다. 1) 서비스 제공에 따른 요금 정산을 위하여 필요한 경우 2)
          통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을
          알아볼 수 없는 형태로 가공하여 제공하는 경우 3) 금융실명거래 및
          비밀보장에 관한 법률, 신용 정보의 이용 및 보호에 관한 법률,
          전기통신기본법, 전기통신사업법, 지방세법, 소비자보호법, 한국은행법,
          형사소송법 등 법률에 특별한 규정이 있는 경우{" "}
        </p>
        <p className="mb-0">
           2. 회사는 휴대폰 결제와 관련하여 회원의 이의 제기가 있는 경우 또는
          이에 준하는 사안으로 휴대폰 결제중재센터의 요청이 있는 경우, 문제
          해결을 위해 필요한 범위 내에서 서비스와 관련된 회원의 결제 여부 등을
          휴대폰 결제 중재센터에 확인하여 줄 수 있습니다.{" "}
        </p>
        <p className="mb-3">
           3. 기타 개인정보 보호와 관련된 사항은 회사의 ‘개인정보 취급방침’에
          따릅니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 9조 회사의 의무 </p>
        <p className="mb-0">
           1. 회사의 고의 또는 귀책사유로 과오금이 발생한 경우, 과오금 전액을
          환불합니다. 이 경우 회원이 결제한 방법과 동일한 방법으로 환불하며,
          동일한 방법으로 환불이 불가능한 경우 결제자 신원 확인 후 전액
          환불합니다. 단 , 회원의 고의 또는 귀책사유로 과오금이 발생한 경우,
          회사가 과오금을 환불하는데 소용되는 비용은 합리적인 범위내에서
          후원자가 부담하도록 합니다.{" "}
        </p>
        <p className="mb-3">
           2. 회사는 면책조항에 해당하지 않는 범위에서 서비스를 정상적으로
          이행하여야 합니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 10조 서비스 이용 제한 </p>
        <p className="mb-3">
           1. 다음의 각 항에 해당하는 행위를 하는 경우 회사는 서비스 이용을
          제한할 수 있습니다. 또 이에 대해 회사는 민사상 손해배상이나 기타
          법적인 조치를 취할 수 있으며, 관련 법규에 따라 법적인 제재 조치를 취할
          수 있습니다. 1) 해킹 또는 기타 시스템에 무리를 주는 행위 2) 유료
          서비스에 대한 권리를 제 3자에게 양도, 매매하는 행위 3) 결제에 있어 그
          결제수단을 위, 변조, 도용하거나 부정취득 또는 사용하는 행위 4) 불특정
          다수의 사용자를 대상으로 하여 선전하는 내용, 영리활동을 하는 행위 5)
          환불 정책 시스템의 허점을 이용한 환불 악용 행위{" "}
        </p>
        <p className="mb-0 provisSubText">  제 11조 서비스 제공 중지 </p>
        <p className="mb-0">
           1. 회사는 다음 각 홍에 해당하는 경우 서비스 제공을 중지할 수
          있습니다. 1) 서비스용 설비의 보수 등 공사로 인한 부득이한 경우 2)
          전기통신사업법에 규정된 기간 통신 사업자가 전기통신서비스를 중지했을
          경우 3) 기타 불가항력적 사유가 있는 경우{" "}
        </p>
        <p className="mb-3">
           2. 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의
          폭주 등으로 정상적인 서비스 이용에 지장이 있는 떄에는 서비스의 전부
          또는 일부를 제한하거나 정지할 수 있습니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 12조 면책조항 </p>
        <p className="mb-0">
           1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스 제공이
          어려워진 경우 서비스 제공에 관한 책임이 면제됩니다.{" "}
        </p>
        <p className="mb-0">
           2. 회사는 후원자의 귀책사유로 인한 서비스 이용의 장애에 대하여
          책임을지지 않습니다.{" "}
        </p>
        <p className="mb-3">
           3. 회사는 후원자가 서비스를 이용하며 얻은 자료로 인한 손해에 관하여
          책임을지지 않습니다.{" "}
        </p>
        <p className="mb-0 provisSubText">  제 13조 관할법원 </p>
        <p className="mb-0">
           1. 서비스 이용과 관련하여 회사와 회원간 분쟁이 있는 경우 양 당사자간
          합의하에 원만히 해결해야 합니다.{" "}
        </p>
        <p className="mb-0">
           2. 만약 분쟁이 원만히 해결되지 않아 소송이 제기된다면, 소송은 관련
          법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.
        </p>
        <p className="mb-3">
           3. 회사와 이용자 간에 제기된 소송에는 대한민국 법을 적용합니다.   제
          14조 GoogleAPI 정책
        </p>
        <p className="mb-0">
           Google API에서 받은 정보를 사용하고 다른 앱으로 전송하는 것은 제한적
          사용 요구 사항을 포함하여 Google API 서비스 사용자 데이터 정책을
          준수합니다.
        </p>
        <p className="mb-0">
           1. &lt;부칙&gt; 본 이용약관은 2023년 12월 8일부터 적용됩니다.  
        </p>
      </div>
      <Footer termsLink="/termosfuseCr" />
    </div>
  );
};

export default ProvisionsDon;
