import api from "../../../../Repository/Interceptor";

export const getPriceStatistics = async () => {
  try {
    const resp = await api.get("/dashboard/stat/money", {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
    });

    return resp;
  } catch (error) {
    console.log("최근 3개월 목록 오류", error);
    return "Fail";
  }
};
