import api from "../../../Repository/Interceptor";

export const EmailVerifyAuthCode = async (email, authcode) => {
  try {
    const resp = await api.get("/user/email/verifyauthcode", {
      params: {
        email: email,
        authCode: authcode,
      },
    });
    console.log("인증코드 확인 성공", resp);

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("인증코드 확인 에러", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
