// ErrorBoundaryWithNavigate.js

import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

function ErrorBoundaryWithNavigate(props) {
  const navigate = useNavigate();

  return <ErrorBoundary navigate={navigate}>{props.children}</ErrorBoundary>;
}

export default ErrorBoundaryWithNavigate;
