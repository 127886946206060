import { useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useTranslation } from "react-i18next";

export const AddressModal = ({ onAddressPost }) => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const [isModalState, setModalState] = useState(false);
  const [isInputAddressValue, setInputAddressValue] = useState(null);
  const [isInputZipCodeValue, setInputZipCodeValue] = useState(null);

  const onCompletePost = (data) => {
    setInputAddressValue(data.address);
    setInputZipCodeValue(data.zonecode);
    setModalState(false);
    setShowModal(false);
    onAddressPost(data);
  }; // onCompletePost 함수

  return (
    <div>
      <div
        href="#"
        className="btn btn-lg btn-primary"
        style={{ height: `2.5rem`, marginLeft: `1rem` }}
        onClick={toggleModal}
      >
        {t("AddressSearch")}
      </div>

      {showModal && (
        <div className="modal-popup modal-mg" id="layer-sample">
          <div className="popup-inner">
            <div className="popup-header">
              <h2 className="popup-title">{t("AddressSearch")}</h2>
            </div>
            <div className="popup-body">
              <DaumPostcode onComplete={onCompletePost}></DaumPostcode>
            </div>
            <div className="popup-footer">
              <div className="btn-group-center">
                <button
                  type="button"
                  className="btn btn-lg btn-gray"
                  data-modal="close"
                  onClick={toggleModal}
                >
                  {t("closeText")}
                </button>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-close-popup"
              data-modal="close"
              onClick={toggleModal}
            >
              {t("closeText")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
