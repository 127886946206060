export const SET_SIDEBAR_INDEX = "SET_SIDEBAR_INDEX";

export const STORE_RESET = "STORE_RESET";

export const setSideBar = (index) => ({
  type: SET_SIDEBAR_INDEX,
  payload: index,
});

export const resetStore = () => ({
  type: STORE_RESET,
});
