import React from "react";
import { useTranslation } from "react-i18next";

const ParentComponent = ({ isToOpen, toggleToModal }) => {
  const { t } = useTranslation();
  return (
    <>
      {isToOpen && (
        <div className="modal-popup modal-sm" id="layer-sample">
          <div className="dimed" onClick={toggleToModal}></div>
          <div className="popup-inner">
            <div className="popup-header">
              <h2 className="popup-title">{t("contactUs")}</h2>
            </div>
            <div className="popup-body">
              <div
                style={{
                  display: `flex`,
                  justifyContent: `center`,
                  fontSize: `18px`,
                  fontWeight: `700`,
                }}
              >
                {t("inconvenienceText")}
              </div>
              <div
                style={{
                  display: `flex`,
                  justifyContent: `center`,
                  fontSize: `15px`,
                  marginTop: `10px`,
                }}
              >
                dltmdtjr9683@xrai-api.com
              </div>
            </div>
            <div className="popup-footer">
              <div className="btn-group-center">
                <button
                  type="button"
                  className="btn btn-lg btn-gray"
                  data-modal="close"
                  onClick={toggleToModal}
                >
                  {t("cancellationText")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ParentComponent;
