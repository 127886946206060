import React from "react";
import "../css/PaymentPage.css";

const ModalTwo = ({ closeModal2 }) => {
  return (
    <div
      className="modal fade show"
      id="paymentPolicyModal"
      tabindex="-1"
      aria-labelledby="paymentPolicyModalLabel"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="paymentPolicyModalLabel">
              결제 대항사 결제정보 위탁 동의 약관
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal2}
            ></button>
          </div>
          <div className="modal-body">
            <p className="mb-3">● 목적</p>
            <p className="mb-0">
              1. 결제 수단 처리 및 요금 정산(정보통신망 이용촉진 및 정보 보호
              등에 관련 법률에 의거)
            </p>
            <p className="mb-3">2. 부정결제 검증 및 방지</p>
            <p className="mb-0">● 제공되는 정보 보유 및 이용 기간</p>
            <p className="mb-3">1. 결제일 기준 5년</p>
            <p className="mb-0">● 위탁 범위</p>
            <p className="mb-3">
              1. 본 정보는 선택하신 결제수단에 대해 위탁 제공합니다.
            </p>
            <p className="mb-0">1) 신용 카드 결제</p>
            <p className="mb-0">가) 제공 받는 자(결제대행업체)</p>
            <p className="mb-0">        (1) 회사명 : 페이레터 주식회사</p>
            <p className="mb-0">        (2) 연락처 : 1599-7591</p>
            <p className="mb-3">나) 제공되는 정보 : 성명</p>
            <p className="mb-0">2) Toss Pay 결제</p>
            <p className="mb-0">가) 제공 받는 자(결제대행업체)</p>
            <p className="mb-0">        (1) 회사명 : ㈜비바리퍼블리카</p>
            <p className="mb-0">        (2) 연락처 : 1599-4905</p>
            <p className="mb-3">나) 제공되는 정보 : 성명, 휴대전화 번호</p>
            <p className="mb-0">3) 카카오페이 결제</p>
            <p className="mb-0">가) 제공 받는 자(결제대행업체)</p>
            <p className="mb-0">        (1) 회사명 : 주식회사 카카오페이</p>
            <p className="mb-0">        (2) 연락처 : 1644-7405</p>
            <p className="mb-3">나) 제공되는 정보 : 성명, 휴대전화 번호</p>
            <p className="mb-0">4) 네이버페이 결제</p>
            <p className="mb-0">가) 제공 받는 자(결제대행업체)</p>
            <p className="mb-0">        (1) 회사명 : 페이레터 주식회사</p>
            <p className="mb-0">         (2) 연락처 : 1599-7591</p>
            <p className="mb-0">
              나) 제공되는 정보 : 결제승인번호, 결제자 명(일부)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalTwo;
