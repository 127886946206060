import { STORE_RESET } from "../action/SidbarIndexAction";
import { SET_USER_PROFILE_IMG } from "../action/UserProfileImgAction";

const initialState = {
  profileImage: null,
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE_IMG:
      return {
        ...state,
        profileImage: action.payload,
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};
