import { useState } from "react";
import {
  MyPageMoneyTableTableCellStyled,
  MyPageMoneyTableTableRowStyled,
} from "../../MyPage/Renewal/css/ReMyPageStyld";
import { statementOfAccountsText } from "../text/ConfirmationText";

import PdfViewer from "../../../util/PdfViewer";
import { PdfDownloadRepo } from "../repo/PdfDownloadRepo";
import Swal from "sweetalert2";
import { awaitRePlayText } from "../../../common/publicText/PublicText";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const ConfirmationTable = ({ data }) => {
  const { t } = useTranslation();
  const languageData = useSelector((state) => state.languageType.type);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedPath, setSelectedPath] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const openModal = (rowId, path, date) => {
    setSelectedRowId(rowId);
    setSelectedPath(path);
    setSelectedDate(date);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  const openModal2 = (rowId, path, date) => {
    setSelectedRowId(rowId);
    setSelectedPath(path);
    setSelectedDate(date);
    setIsModalOpen2(true);
  };
  const closeModal2 = () => setIsModalOpen2(false);

  const handleDownload = async (id, path, date) => {
    try {
      const resp = await PdfDownloadRepo(id, path);
      if (resp.msg === "Success") {
        const dateParts = date.split(".");
        const yearMonth = `${dateParts[0]}.${dateParts[1]}`;
        const pdfBlob = new Blob([resp.data.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${yearMonth}. ${statementOfAccountsText}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // 메모리 해제
        return;
      }

      if (resp.msg === "Fail") {
        console.log("다운로드 실패", resp);
        Swal.fire({
          title: `${awaitRePlayText}`,
          icon: "error",
        });
        return;
      }
    } catch (error) {}
  };

  return (
    <div className="table ">
      <div className="table-inner ">
        <table style={{ width: `100%` }}>
          {data.length === 0 ? (
            <div>
              <div
                style={{
                  display: `flex`,
                  width: `100%`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  marginTop: `20px`,
                }}
              >
                {languageData === "ko" ? (
                  <img src="LogoBlack.svg" alt="" />
                ) : (
                  <img src="GroupEn.svg" alt="" width={300} />
                )}
              </div>
              <div
                style={{
                  display: `flex`,
                  width: `100%`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  marginTop: `20px`,
                  fontSize: `18px`,
                  fontWeight: `700`,
                  marginBottom: `30px`,
                }}
              >
                {t("settlementDetailsNotExistText")}
              </div>
            </div>
          ) : (
            <>
              <caption className="sr-only "></caption>
              <colgroup>
                <col style={{ width: "160px" }} />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr style={{ textAlign: `center` }}>
                  <th scope="col">{t("defaultMonthText")}</th>
                  <th scope="col">{t("dateOfCreationText")}</th>
                  <th scope="col">{t("supplierText")}</th>
                  <th scope="col">{t("statementOfAccountsText")}</th>
                  <th scope="col">{t("detailsText")}</th>
                  <th scope="col">{t("confirmationStatusText")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <MyPageMoneyTableTableRowStyled key={row.id}>
                    <th scope="row">{row.date}</th>
                    <MyPageMoneyTableTableCellStyled>
                      {row.writeDate}
                    </MyPageMoneyTableTableCellStyled>
                    <MyPageMoneyTableTableCellStyled>
                      {row.producer}
                    </MyPageMoneyTableTableCellStyled>
                    <MyPageMoneyTableTableCellStyled>
                      {row.title}
                    </MyPageMoneyTableTableCellStyled>
                    <MyPageMoneyTableTableCellStyled>
                      <button
                        style={{ color: `#357fed` }}
                        onClick={() =>
                          handleDownload(row.id, row.filePath, row.date)
                        }
                      >
                        {t("downLoadText")}
                      </button>
                    </MyPageMoneyTableTableCellStyled>
                    <MyPageMoneyTableTableCellStyled>
                      {row.isCheck === false ? (
                        <button
                          style={{ color: `#357fed` }}
                          onClick={() =>
                            openModal(row.id, row.filePath, row.writeDate)
                          }
                        >
                          {t("eyeCheckText")}
                        </button>
                      ) : (
                        <button
                          style={{ color: `#357fed`, fontWeight: `800` }}
                          onClick={() =>
                            openModal2(row.id, row.filePath, row.writeDate)
                          }
                        >
                          {t("eyeCheckSuccess")}
                        </button>
                      )}
                    </MyPageMoneyTableTableCellStyled>
                    {/* 이미 체크했을경우 */}
                  </MyPageMoneyTableTableRowStyled>
                ))}
              </tbody>
            </>
          )}
        </table>
        {isModalOpen2 && (
          <PdfViewer
            isOpen={isModalOpen2}
            onClose={closeModal2}
            check={true}
            id={selectedRowId}
            path={selectedPath}
            date={selectedDate}
          />
        )}

        {/* 체크를 하지 않은경우 */}
        {isModalOpen && (
          <PdfViewer
            isOpen={isModalOpen}
            onClose={closeModal}
            id={selectedRowId}
            path={selectedPath}
            date={selectedDate}
          />
        )}
      </div>
    </div>
  );
};
