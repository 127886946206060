import api from "../../../Repository/Interceptor";

export const PdfDownloadRepo = async (id, path) => {
  try {
    const resp = await api.post(
      "/soa/download",
      {
        id: id,
        filePath: path,
      },
      {
        responseType: "blob",
      }
    );

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    return {
      data: error,
      msg: "Fail",
    };
  }
};
