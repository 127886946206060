import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setPagination } from "../../../../common/redux/action/PaginationAction";

export const ReMyPagination = () => {
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state?.paginationState);

  const [current, setcurrent] = useState(null);
  const [total, setTotal] = useState(null);

  const itemsPerPage = 10;

  useEffect(() => {
    setcurrent(pageData?.current);
    setTotal(pageData?.total);
  }, [pageData]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= total) {
      dispatch(setPagination(total, page));
      setcurrent(page);
    }
  };

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handlePrevPage = () => {
    if (current > 1) {
      handlePageChange(current - 1);
    }
  };

  const handleNextPage = () => {
    if (current < total) {
      handlePageChange(current + 1);
    }
  };

  const handleLastPage = () => {
    handlePageChange(total);
  };

  const getPageNumbers = () => {
    const start = Math.floor((current - 1) / 5) * 5 + 1;
    return Array.from({ length: 5 }, (_, i) => start + i).filter(
      (page) => page <= total
    );
  };
  return (
    <div className="pagination">
      {total !== 0 ? (
        <>
          <Link
            href="#"
            className="page-link"
            style={{ borderColor: `transparent` }}
            onClick={handleFirstPage}
          >
            {"<<"}
          </Link>
          <Link
            href="#"
            className="page-link"
            style={{ borderColor: `transparent` }}
            onClick={handlePrevPage}
          >
            {"<"}
          </Link>
          {getPageNumbers().map((page) =>
            page === current ? (
              <strong key={page}>{page}</strong>
            ) : (
              <Link
                to="#"
                key={page}
                className="page-link"
                style={{ borderColor: `transparent` }}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </Link>
            )
          )}

          <Link
            href="#"
            className="page-link"
            style={{ borderColor: `transparent` }}
            onClick={handleNextPage}
          >
            {">"}
          </Link>
          <Link
            href="#"
            className="page-link"
            style={{ borderColor: `transparent` }}
            onClick={handleLastPage}
          >
            {">>"}
          </Link>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
