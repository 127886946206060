import api from "../../../Repository/Interceptor";

export const postDownloadFile = async (id, path, type) => {
  try {
    const resp = await api.post(
      "/register/download",
      {
        fileId: id,
        filePath: path,
        type: type,
      },
      {
        headers: {
          "API-Key": "COFFEE1234!!!",
        },
        responseType: "blob",
      }
    );

    return {
      data: resp.data,
      msg: "Success",
    };
  } catch (error) {
    console.log("다운로드 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
