import api from "../../../Repository/Interceptor";

export const getSoa = async (start, end) => {
  try {
    const resp = await api.get("/soa", {
      params: {
        start_date: start,
        end_date: end,
      },
    });

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("정산확인 정보 호출 에러", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
