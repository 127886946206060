import { useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { BsCalendarDate } from "react-icons/bs";
import { ko, enUS } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";
import { PiWaveSineBold } from "react-icons/pi";
import { BoxStyled, DayBoxStyled, OneDayBoxStyled } from "../css/SearchStyled";
import { useDispatch, useSelector } from "react-redux";
import {
  setLastMonth,
  setToday,
} from "../../../../common/redux/action/ToDayAction";
import {
  getCurrentDate,
  getLastDayDate,
  getLastMonthDate,
  getLastThreeMonthsDate,
  getLastWeekDate,
  getLastYearDate,
} from "../../../../util/DateCalculation";
import {
  setActionSearchText,
  setSearchValueSelected,
} from "../../../../common/redux/action/SearchTextAction";

import { useTranslation } from "react-i18next";

registerLocale("ko", ko);

const SearchCard = ({ onSearch }) => {
  const { t, i18n } = useTranslation();

  const locale = i18n.language === "ko" ? "ko" : "enUS";

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedBox, setSelectedBox] = useState("oneMonth");
  const [currentDate, setCurrentDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState("이름");
  const [isSearchText, setSearchText] = useState("");

  const handleInputText = (event) => {
    setSearchText(event.target.value);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);

    if (value === "이름") {
      dispatch(setSearchValueSelected(1));
      return 1;
    } else if (value === "내용") {
      dispatch(setSearchValueSelected(2));
      return 2;
    }
  };

  useEffect(() => {
    /// 오늘날짜
    const toCurrentDate = getCurrentDate();
    const startCurrentDate = getLastMonthDate();
    const formattedDate = new Date(
      toCurrentDate.year,
      toCurrentDate.month - 1,
      toCurrentDate.day,
      0,
      0,
      0
    );

    const formattedStartDate = new Date(
      startCurrentDate.year,
      startCurrentDate.month - 1,
      startCurrentDate.day,
      0,
      0,
      0
    );

    setEndDate(formattedDate);
    setStartDate(formattedStartDate);
    /// 한달전 날짜
    const newToCurrentDate = new Date(
      `${toCurrentDate.year}-${toCurrentDate.month}-${toCurrentDate.day}`
    );
    setCurrentDate(newToCurrentDate);
  }, []);

  const handleReSet = () => {
    const current = getCurrentDate();
    const currentOneMonth = getLastMonthDate();

    const newCurrentDate = new Date(
      `${current.year}-${current.month}-${current.day}`
    );

    const newDate = new Date(
      `${currentOneMonth.year}-${currentOneMonth.month}-${currentOneMonth.day}`
    );
    dispatch(setToday(current.year, current.month, current.day));
    dispatch(
      setLastMonth(
        currentOneMonth.year,
        currentOneMonth.month,
        currentOneMonth.day
      )
    );
    setSelectedBox("oneMonth");
    setStartDate(newDate);
    setEndDate(newCurrentDate);
    setSelectedValue("이름");
    setSearchText("");
    dispatch(setActionSearchText(""));
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setSelectedBox(null);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setSelectedBox(null);
  };

  const handleSelect = (box) => {
    /// 현재 날짜
    const currentDay = getCurrentDate();
    const newCurrentDate = new Date(
      `${currentDay.year}-${currentDay.month}-${currentDay.day}`
    );
    setSelectedBox(box);
    if (box === "oneDay") {
      const lastDay = getLastDayDate();

      const newDate = new Date(
        `${lastDay.year}-${lastDay.month}-${lastDay.day}`
      );

      setStartDate(newDate);
      setEndDate(newCurrentDate);
    } else if (box === "oneWeek") {
      const lastDay = getLastWeekDate();

      const newDate = new Date(
        `${lastDay.year}-${lastDay.month}-${lastDay.day}`
      );
      setStartDate(newDate);
      setEndDate(newCurrentDate);
    } else if (box === "oneMonth") {
      const lastDay = getLastMonthDate();

      const newDate = new Date(
        `${lastDay.year}-${lastDay.month}-${lastDay.day}`
      );
      setStartDate(newDate);
      setEndDate(newCurrentDate);
    } else if (box === "threeMonths") {
      const lastDay = getLastThreeMonthsDate();
      const newDate = new Date(
        `${lastDay.year}-${lastDay.month}-${lastDay.day}`
      );
      setStartDate(newDate);
      setEndDate(newCurrentDate);
    } else if (box === "oneYear") {
      const lastDay = getLastYearDate();
      const newDate = new Date(
        `${lastDay.year}-${lastDay.month}-${lastDay.day}`
      );
      setStartDate(newDate);
      setEndDate(newCurrentDate);
    }
  };

  const handleSearch = () => {
    const date = new Date(startDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const end = new Date(endDate);
    const endYear = end.getFullYear();
    const endMonth = String(end.getMonth() + 1).padStart(2, "0");
    const endDay = String(end.getDate()).padStart(2, "0");

    dispatch(setLastMonth(year, month, day));
    dispatch(setToday(endYear, endMonth, endDay));
    dispatch(setActionSearchText(isSearchText));
    onSearch();
  };

  return (
    <>
      <div className="" style={{ marginBottom: `10px` }}>
        <div className="search-form" onsubmit="return false">
          <div className=" table-form-basic">
            <div className="table-inner">
              <table>
                <caption className="sr-only">
                  {t("enterSearchConditionsText")}
                </caption>
                <tbody>
                  <tr>
                    <th scope="row">
                      <span>{t("inquiryPeriodText")}</span>
                    </th>
                    <td>
                      <div className="input-group input-group-date-range">
                        <div
                          style={{
                            display: `flex`,
                            justifyContent: `space-between`,
                            width: `100%`,
                          }}
                        >
                          <div style={{ display: `flex` }}>
                            <OneDayBoxStyled
                              selected={selectedBox === "oneDay"}
                              onClick={() => handleSelect("oneDay")}
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {t("dayBeforeText")}
                            </OneDayBoxStyled>
                            <DayBoxStyled
                              selected={selectedBox === "oneWeek"}
                              onClick={() => handleSelect("oneWeek")}
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {t("aWeekText")}
                            </DayBoxStyled>
                            <DayBoxStyled
                              selected={selectedBox === "oneMonth"}
                              onClick={() => handleSelect("oneMonth")}
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {t("oneMonthText")}
                            </DayBoxStyled>
                            <DayBoxStyled
                              selected={selectedBox === "threeMonths"}
                              onClick={() => handleSelect("threeMonths")}
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {t("threeMonthsText")}
                            </DayBoxStyled>
                            <BoxStyled
                              selected={selectedBox === "oneYear"}
                              onClick={() => handleSelect("oneYear")}
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {t("oneYearText")}
                            </BoxStyled>
                          </div>
                          <div
                            style={{
                              display: `flex`,
                              justifyContent: `flex-end`,
                              // width: `67.5%`,
                              alignItems: `center`,
                            }}
                          >
                            <BsCalendarDate
                              style={{
                                // marginLeft: `20px`,

                                width: `3rem`,
                                height: `25px`,
                              }}
                            />
                            <ReactDatePicker
                              selected={startDate}
                              onChange={(date) => handleStartDate(date)}
                              className="form-date"
                              dateFormat="yyyy.MM.dd"
                              style={{ textAlign: `center` }}
                              placeholderText="YYYY.MM.DD"
                              locale={locale}
                              maxDate={endDate}
                            />
                            <div
                              style={{
                                width: `6%`,
                                justifyContent: `center`,
                                display: `flex`,
                              }}
                            >
                              <PiWaveSineBold />
                            </div>
                            <ReactDatePicker
                              selected={endDate}
                              onChange={(date) => handleEndDate(date)}
                              className="form-date"
                              dateFormat="yyyy.MM.dd"
                              style={{ textAlign: `center` }}
                              placeholderText="YYYY.MM.DD"
                              locale={locale}
                              minDate={startDate}
                              maxDate={currentDate}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span>{t("searchText")}</span>
                    </th>
                    <td>
                      <div style={{ display: `flex`, width: `100%` }}>
                        <div style={{ marginRight: `20px` }}>
                          <select
                            className="form-control"
                            title={t("siteNameSelectionText")}
                            value={selectedValue}
                            onChange={handleSelectChange}
                          >
                            <option value="이름">{t("nameText")}</option>
                            <option value="내용">{t("contentText")}</option>
                          </select>
                        </div>
                        <input
                          style={{ textAlign: "left" }}
                          type="text"
                          className="form-control"
                          value={isSearchText}
                          onChange={handleInputText}
                        ></input>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="btn-group-center">
            <button
              type="reset"
              className="btn btn-md btn-outline-gray"
              onClick={() => handleReSet()}
            >
              <span className="text">
                <strong>{t("resetText")}</strong>
              </span>
            </button>
            <button
              type="submit"
              className="btn btn-md btn-primary"
              onClick={() => handleSearch()}
            >
              <span className="text">
                <strong>{t("inquiryText")}</strong>
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCard;
