import React from "react";
import { useTranslation } from "react-i18next";
import { CustomSVG } from "../../../home/DonationPage/css/Tolk";

const EmailButton = () => {
  const { t } = useTranslation();

  return (
    <div className="item-body" style={{ width: `100%`, cursor: `pointer` }}>
      <dl
        style={{
          backgroundColor: "#FFD400",
          justifyContent: `center`,
          alignItems: `center`,
          display: `flex`,
          height: `3.5rem`,
          borderRadius: `8px`,
        }}
      >
        <CustomSVG />
        <strong
          style={{
            fontSize: "15px",
            marginLeft: `5px`,
          }}
        >
          {t("contactUs")}
        </strong>
      </dl>
    </div>
  );
};

export default EmailButton;
