import styled from "styled-components";

export const MonthBoxStyled = styled.div`
  border: 1px solid #d1d5db;
  padding: 4px 2px 4px 2px;
  border-radius: 8px 0 0 8px;
  width: 3.5rem;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  color: #374151;
  background-color: ${(props) => (props.selected ? "#3b82f6" : "white")};
  color: ${(props) => (props.selected ? "white" : "#374151")};

  cursor: pointer;
`;

export const DayBoxStyled = styled.div`
  border: 1px solid #d1d5db;
  padding: 4px 2px 4px 2px;
  border-radius: 8px 8px 8px 8px;
  width: 3.5rem;
  margin-left: 10px;
  font-size: 15px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  color: #374151;
  background-color: ${(props) => (props.selected ? "#3b82f6" : "white")};
  color: ${(props) => (props.selected ? "white" : "#374151")};
  cursor: pointer;
`;

export const StyledDiv = styled.div`
  flex: 1 1 auto;
  max-width: 100%;
  white-space: nowrap;
  max-height: 1.3em;
  line-height: 1.3em;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
`;

export const GoldenCircle = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background-color: rgba(255, 215, 0, 0.7); // 황금색에 40% 투명도
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  margin-right: 10px;
`;
// radio-btn-group
export const SilverCircle = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background-color: rgba(192, 192, 192, 0.7); // 은색에 40% 투명도
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  margin-right: 10px;
`;

export const CopperCircle = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background-color: rgba(184, 115, 51, 0.7); // 구리색에 40% 투명도
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  margin-right: 10px;
`;
export const GrayCircle = styled.div`
  min-width: 1.2rem;
  min-height: 1.2rem;
  width: 1.2rem;
  height: 1.2rem;
  background-color: rgba(128, 128, 128, 0.1);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  margin-right: 10px;
`;

export const SelectMonthStyle = styled.select`
  width: 100%;
  height: 40px;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 0 10px;
  background-color: white;
  color: black;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: darkgray;
  }
`;

export const TodayHitStyled = styled.dl`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 25px;
  border: 1px solid #f3f4f6;
  border-radius: 16px;
  background: #f9fafb;
  width: 50%;
  text-align: center;
`;

export const TodayReportStyled = styled.dl`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  padding: 20px 20px 25px;
  border: 1px solid #f3f4f6;
  border-radius: 16px;
  background: #f9fafb;
  width: 100%;
  text-align: center;
`;

export const TableTableStyled = styled.div`
  width: 100%;
  height: 139px;
  border-spacing: 0;
  border-collapse: collapse;
  border-bottom: 1px solid #e5e7eb;
  table-layout: fixed;
`;

export const MyPageMoneyTableTableRowStyled = styled.tr`
  text-align: center;
`;
export const MyPageMoneyTableTableCellStyled = styled.td`
  text-align: center;
`;
