import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { BsPersonAdd } from "react-icons/bs";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { postProfileImgUpload } from "../repo/ChangeProfileImg";
import { setReProfileImg } from "../../../../common/redux/action/ProfileAction";
import { API_URL } from "../../../../Repository/URL";
import { profileUpdateFailText } from "../text/ReModifyText";

export const ProfileImageUpload = (profileImg) => {
  const dispatch = useDispatch();
  const url = API_URL;
  const [profileImage, setProfileImage] = useState(profileImg.profileImg);
  const [imgError, setImgError] = useState(false);

  const handleImgError = () => {
    setImgError(true);
  };
  // useEffect(() => {}, [profileImage]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const response = await postProfileImgUpload(file);

        if (response.msg === "Success") {
          setProfileImage("/" + response.data.data);
          dispatch(setReProfileImg("/" + response.data.data));
        }

        if (response.msg === "Fail") {
          console.log("프로필 사진 업로드 실패");
          return;
        }
      } catch (error) {
        console.log("프로필 사진 업로드 실패", error);
        Swal.fire({
          title: `${profileUpdateFailText}`,
          icon: `error`,
        });
        return;
      }

      return;
      // dispatch(setProfileImg(reader.result));
    };

    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <div
      className="profile"
      style={{
        height: "10rem",
        width: "10rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {profileImage && !imgError ? (
        <img
          src={profileImage}
          alt="Profile"
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
          onError={handleImgError}
        />
      ) : (
        <div>
          <BsPersonAdd style={{ height: `6rem`, width: `6rem` }} />
        </div>
      )}
    </div>
  );
};
