import { useEffect, useRef, useState } from "react";
import { BankModal } from "./BankModal";
import { IoClose } from "react-icons/io5";
import MyDropzone from "./Dropzone";
import { AddressModal } from "./AddressNModal";
import { handleCheckRegistrationNumber } from "../repo/CheckRegistrationNumber";
import { SignUpCheckBoxStyled } from "../../SignUp/Renewal/css/ReSignUpStyled";
import { FlexDivText, TextTitleStyled } from "../css/FlexDiv";
import { TextModal } from "./TextModal";
import { SettlementConditions } from "../data/SettlementConditions";
import { ScrollableDiv, ScrollableMiniDiv } from "../css/ScrillableDiv";
import { InformedConsent } from "../data/InformedConsent";
import { ConsentToUse } from "../data/ConsentToUse";
import { Confirmation } from "../data/Confirmation";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  isValidEmail,
  numericRegex,
  validateName,
  validateNumber,
  validatePhonNumber,
} from "../../../util/const";
import { BusinessSave, IndividualSave } from "../repo/IndividualSave";
import { getCalculateData } from "../repo/GetCalculateData";
import { useNavigate } from "react-router";
import {
  resetCalculate,
  setCalculate,
} from "../../../common/redux/action/CalculateAction";
import { setSideBar } from "../../../common/redux/action/SidbarIndexAction";
import { handleDown } from "../../../util/DownHandle";
import { modificationInformationText } from "../data/CalculateText";
import { useTranslation } from "react-i18next";
import { SaveCheckModal } from "./SaveCheckModal";
import { ConfirmationEn } from "../data/ConfirmationEn";
import { ConsentToUseEn } from "../data/ConsentToUseEn";
import { InformedConsentEn } from "../data/InformedConsentEn";
import { SettlementConditionsEn } from "../data/SettlementConditionsEn";
import {
  RegistrationButton,
  RequiredInputBox,
} from "../css/CorporateBusinessStyled";

export const PrivateBusinessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.languageType.type);

  const calculateData = useSelector((state) => state.calculateState?.data);
  const { t } = useTranslation();

  /// ref
  const registrationNumberRef = useRef(null);
  const bankNameRef = useRef(null);
  const accountNumberRef = useRef(null);
  const nameRef = useRef(null);
  const companyNameRef = useRef(null);
  const ownerNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const dStatusRef = useRef(null);
  const bTypeRef = useRef(null);
  const emailRef = useRef(null);
  const addressRef = useRef(null);
  const detailAddressRef = useRef(null);
  const platFormRef = useRef(null);
  const filesRef = useRef(null);
  const allCheckRef = useRef(null);
  /// ErrorStateMessage
  const [isRegistrationNumberTrim, setRegistrationNumberTrim] = useState(false);
  const [isCheckRegistrationNumber, setCheckRegistrationNumber] =
    useState(false);
  const [isRegistrationNumberCheck, setRegistrationNumberCheck] =
    useState(false);

  const [isBankNameTrim, setBankNameTrim] = useState(false);
  const [isAccountTrim, setAccountTrim] = useState(false);
  const [isAccountValueCheck, setAccountValueCheck] = useState(false);
  const [isNameTrim, setNameTrim] = useState(false);
  const [isCompanyNameTrim, setCompanyNameTrim] = useState(false);
  const [isOwnerNameTrim, setOwnerNameTrim] = useState(false);
  const [isOwnerNameValueCheck, setOwnerNameValueCheck] = useState(false);
  const [isPhoneNumberTrim, setPhonNumberTrim] = useState(false);
  const [isPhonNumberCheck, setPhonNumberCheck] = useState(false);
  const [isDstatusTrim, setDstatusTrim] = useState(false);
  const [isBtypeTrim, setBtypeTrim] = useState(false);
  const [isEmailTrim, setEmailTrim] = useState(false);
  const [isEmailValueCheck, setEmailValueCheck] = useState(false);
  const [isAddressTrim, setAddressTrim] = useState(false);
  const [isDetailAddressTrim, setDetailAddressTrim] = useState(false);
  const [isPlatTrim, setPlatTrim] = useState(false);
  const [isFilesTrim, setFilesTrim] = useState(false);
  const [isAllCheckTrim, setAllCheckTrim] = useState(false);
  /// 전체약관 동의
  const [allChecked, setAllChecked] = useState(false);
  const [oneChecked, setOneChecked] = useState(false);
  const [twoChecked, setTwoChecked] = useState(false);
  const [threeChecked, setThreeChecked] = useState(false);
  const [fourChecked, setFourChecked] = useState(false);
  const [isDisableCon, setDisableCon] = useState(false);

  /// 통신할 데이터의 상테
  const [isAccount, setAccount] = useState(calculateData?.bankCode || "");
  const handleAccount = (e) => {
    setAccount(e.target.value);
  };
  const [isName, setName] = useState(calculateData?.name || "");
  const handleName = (e) => {
    setName(e.target.value);
  };
  const [isComponyName, setComponyName] = useState(
    calculateData?.businessName || ""
  );
  const handleComponyName = (e) => {
    setComponyName(e.target.value);
  };
  const [isOwnerName, setOwnerName] = useState(
    calculateData?.bankOwnerName || ""
  );
  const handleOwnerName = (e) => {
    setOwnerName(e.target.value);
  };
  const [isPhoneNumber, setPhoneNumber] = useState(calculateData?.phone || "");
  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };
  const [isState, setState] = useState(calculateData?.status || "");
  const handleState = (e) => {
    setState(e.target.value);
  };
  const [isBtype, setBtype] = useState(calculateData?.bType || "");
  const handleBtype = (e) => {
    setBtype(e.target.value);
  };
  const [isEmail, setEmail] = useState(calculateData?.email || "");
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const [isDetailAddress, setDetailAddress] = useState("");
  const handleDetailAddress = (e) => {
    setDetailAddress(e.target.value);
  };
  const [isPlatfrom, setPlatfrom] = useState(calculateData?.channel || "");
  const handlePlatFrom = (e) => {
    setPlatfrom(e.target.value);
  };
  const handleReStartSave = () => {
    dispatch(resetCalculate());
    setFiles([]);

    navigate("/CalculatePage");
  };

  /// En 상태추가
  const [isPayPalName, setPayPalName] = useState(
    calculateData?.paypalName || ""
  );
  const [isPayPalEmail, setPayPalEmail] = useState(
    calculateData?.paypalEmail || ""
  );
  const [isPayPalPhone, setPayPalPhone] = useState(
    calculateData?.paypalPhone || ""
  );
  const [isEnAddress, setEnAddress] = useState(calculateData?.address || "");

  const handlePayPalName = (e) => {
    setPayPalName(e.target.value);
  };
  const handlePayPalEmail = (e) => {
    setPayPalEmail(e.target.value);
  };
  const handlePayPalPhone = (e) => {
    setPayPalPhone(e.target.value);
  };
  const handleEnAddress = (e) => {
    setEnAddress(e.target.value);
  };

  ///

  useEffect(() => {
    if (calculateData?.data) {
      setAccount(calculateData?.bankCode || "");
      setName(calculateData?.name || "");
      setComponyName(calculateData?.businessName || "");
      setOwnerName(calculateData?.bankOwnerName || "");
      setPhoneNumber(calculateData?.phone || "");
      setState(calculateData?.status || "");
      setBtype(calculateData?.bType || "");
      setEmail(calculateData?.email || "");
      setPlatfrom(calculateData?.channel || "");
      setRegistrationNumber(calculateData?.code || "");
      setPayPalName(calculateData?.paypalName || "");
      setPayPalEmail(calculateData?.paypalEmail || "");
      setPayPalPhone(calculateData?.paypalPhone || "");
      setEnAddress(calculateData?.address || "");
    }
  }, [calculateData]);

  /// 이용약관 전체동의 핸들러
  const handleAllChecked = (e) => {
    const isChecked = e.target.checked;
    setAllChecked(isChecked);
    setOneChecked(isChecked);
    setTwoChecked(isChecked);
    setThreeChecked(isChecked);
    setFourChecked(isChecked);
  };

  const handleIndividualCheck = (setter) => (e) => {
    setter(e.target.checked);
    if (!e.target.checked) {
      setAllChecked(false);
    }
  };

  const [isAddressCode, addressCode] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedBank, setSelectedBank] = useState(
    calculateData?.bankName || ""
  );

  const [isCheckRegistration, setCheckRegistration] = useState(null);

  const [isRegistrationNumber, setRegistrationNumber] = useState(
    calculateData?.code || ""
  );

  const handleRegistration = (e) => {
    setRegistrationNumber(e.target.value);
  };

  const handleCheckReset = () => {
    setCheckRegistration(null);
  };

  const handleCheckNumber = async (req) => {
    const resp = await handleCheckRegistrationNumber(req);

    if (
      resp?.match_cnt === undefined ||
      (typeof resp.match_cnt === "string" && resp.match_cnt.trim() === "")
    ) {
      /// 사업자 등록번호 조회 없음
      setCheckRegistration(false);
    } else if (resp !== null) {
      /// 사업자 등록번호 조회 있음
      setCheckRegistration(true);
    }
  };

  const handleSelectBank = (bankName) => {
    setSelectedBank(bankName);
  };

  const handleChange = (event) => {
    setSelectedBank(event.target.value);
  };

  const handleComplete = (data) => {
    addressCode(data.address + " " + `(${data.zonecode})`);
  };

  function handleRemoveFile(fileToRemove) {
    setFiles((prevFiles) =>
      prevFiles.filter(
        (file) =>
          file.name + file.lastModified !==
          fileToRemove.name + fileToRemove.lastModified
      )
    );
  }

  useEffect(() => {
    if (calculateData?.address) {
      const address = calculateData?.address;
      const splitIndex = address.indexOf(")");
      if (splitIndex !== -1) {
        const formattedInAddress = address.substring(splitIndex + 1).trim();
        const formattedRemainingAddress = address
          .substring(0, splitIndex + 1)
          .trim();
        setDetailAddress(formattedInAddress);
        addressCode(formattedRemainingAddress);
      }
    }

    if (calculateData?.files) {
      const formattedFiles = calculateData.files.map((file) => {
        return {
          path: file.fileOriName,
          preview: URL.createObjectURL(new Blob()),
          lastModified: Date.now(),
          lastModifiedDate: new Date(),
          name: file.fileOriName,
          size: "",
          type: "image/jpeg",
          webkitRelativePath: "",
          orId: file.fileId,
          path: file.filePath,
        };
      });
      setFiles(formattedFiles);
    }

    if (calculateData === "nodata" || calculateData === null) {
      setDisableCon(false);
    } else {
      setDisableCon(true);
    }
  }, [calculateData]);

  /// 신청하기 버튼
  const handleSave = async (
    files,
    isRegistrationNumber,
    bank,
    account,
    name,
    companyName,
    ownerName,
    phone,
    dStatus,
    bType,
    email,
    address,
    detailAddress,
    plat,
    one,
    two,
    three,
    four,
    isCheckRegistration,
    paypalName,
    paypalEmail,
    paypalPhone,
    enAddress
  ) => {
    let resp;
    let fullAddress;
    /// 유효성 검증
    /// 1. 사업자등록번호가 빈값일경우
    if (!isRegistrationNumber || isRegistrationNumber.trim() === "") {
      Swal.fire({
        title: `${t("businessNumberTrimCheckText")}`,
        icon: `error`,
      });
      return;
    }

    if (languageData === "ko") {
      if (!isCheckRegistration) {
        Swal.fire({ title: `${t("businessNumberCheckText")}`, icon: `error` });
        return;
      }

      if (!numericRegex.test(isRegistrationNumber)) {
        Swal.fire({
          title: `${t("businessRegistrationText")}`,
          icon: `error`,
        });
        return;
      }
    }
    /// paypal관련 검증로직
    if (languageData !== "ko") {
      if (!paypalName || paypalName.trim() === "") {
        Swal.fire({
          title: "Please enter your PayPal username.",
          icon: `error`,
        });
      }

      if (!paypalEmail || paypalEmail.trim() === "") {
        Swal.fire({
          title: "Please enter your PayPal email.",
          icon: `error`,
        });
      }

      if (!isValidEmail(paypalEmail)) {
        Swal.fire({
          title: "Please enter a valid PayPal email format.",
          icon: `error`,
        });
        return;
      }

      if (!paypalPhone || paypalPhone.trim() === "") {
        Swal.fire({
          title: "Please enter your PayPal phone number.",
          icon: `error`,
        });
      }
    }

    /// 2. 은행명이 빈값일 경우
    if (languageData === "ko") {
      if (!bank || bank.trim() === "") {
        Swal.fire({ title: `${t("pleaseBankNameInputText")}`, icon: `error` });
        return;
      }

      /// 3. 계좌번호가 빈값일 경우
      if (!account || account.trim() === "") {
        Swal.fire({
          title: `${t("trimAccountNumberText")}`,
          icon: `error`,
        });
        return;
      }

      if (!validateNumber(account)) {
        Swal.fire({
          title: `${t("checkAccountText")}`,
          icon: `error`,
        });
        return;
      }

      /// 4. 예금주 명이 빈값일경우
      if (languageData === "ko") {
        if (!name || name.trim() === "") {
          Swal.fire({
            title: `${t("accountMasterHumanNameText")}`,
            icon: `error`,
          });
          return;
        }
      }
    }

    /// 5. 상호명이 빈값일경우
    if (!companyName || companyName.trim() === "") {
      Swal.fire({ title: `${t("companyNameText")}`, icon: `error` });
      return;
    }

    /// 6. 대표자 성명 빈값일경우
    if (!ownerName || ownerName.trim() === "") {
      Swal.fire({ title: `${t("companyMasterNameText")}`, icon: `error` });
      return;
    }

    if (languageData === "ko") {
      if (!validateName(ownerName)) {
        Swal.fire({
          title: `${t("ownerNameValidateCheckText")}`,
          icon: `error`,
        });
        return;
      }
    }

    /// 7. 전화번호가 빈값일경우
    if (languageData === "ko") {
      if (!phone || phone.trim() === "" || !numericRegex.test(phone)) {
        Swal.fire({
          title: `${t("phoneNumberPleaseText")}`,
          icon: `error`,
        });
        return;
      }

      if (!validatePhonNumber(phone)) {
        Swal.fire({
          title: `${t("phoneNumberCheckPleaseText")}`,
          icon: `error`,
        });
        return;
      }
    }

    /// 8. 업태가 빈값일경우
    if (!dStatus || dStatus.trim() === "") {
      Swal.fire({ title: `${t("businessStyleText")}`, icon: `error` });
      return;
    }

    /// 9. 업종이 빈값일경우
    if (!bType || bType.trim() === "") {
      Swal.fire({ title: `${t("typeOfBusinessText")}`, icon: `error` });
      return;
    }

    /// 10. 이메일이 빈값일경우
    if (!email || email.trim() === "") {
      Swal.fire({ title: `${t("emailInputText")}`, icon: `error` });
      return;
    }

    if (!isValidEmail(email)) {
      Swal.fire({
        title: `${t("noEmailStateText")}`,
        icon: `error`,
      });
      return;
    }

    /// 11. 주소가 빈값인경우
    if (languageData === "ko") {
      if (!address || address.trim() === "") {
        Swal.fire({ title: `${t("searchAddressInputText")}`, icon: `error` });
        return;
      }
    }

    /// 12. 상새주소가 빈값인경우
    if (!detailAddress || detailAddress.trim() === "") {
      Swal.fire({ title: `${t("detailAddressInputText")}`, icon: `error` });
      return;
    }

    /// 13. 플렛폼이 빈값인경우
    if (!plat || plat.trim() === "") {
      Swal.fire({ title: `${t("pletformInputText")}`, icon: `error` });
      return;
    }

    /// 14. 첨부파일이 하나도 없을 경우
    if (files.length === 0) {
      Swal.fire({ title: `${t("fileUploadText")}`, icon: `error` });
      return;
    }
    /// 15. 이용약관 동의가 없을경우
    if (!one || !two || !three || !four) {
      Swal.fire({ title: `${t("termsAndConditionsText")}`, icon: `error` });
      return;
    }

    if (languageData === "ko") {
      fullAddress = `${address} ${detailAddress}`;
    } else {
      if (enAddress && enAddress.trim() !== "") {
        fullAddress = `(${enAddress})${detailAddress}`;
      } else {
        fullAddress = `${enAddress}${detailAddress}`;
      }
    }

    if (languageData === "ko") {
      resp = await BusinessSave(
        name,
        isRegistrationNumber,
        phone,
        fullAddress,
        plat,
        bank,
        account,
        files,
        ownerName,
        dStatus,
        bType,
        email,
        false,
        companyName,
        " ",
        " ",
        " "
      );
    } else {
      resp = await BusinessSave(
        " ",
        isRegistrationNumber,
        " ",
        fullAddress,
        plat,
        " ",
        " ",
        files,
        ownerName,
        dStatus,
        bType,
        email,
        false,
        companyName,
        paypalName,
        paypalEmail,
        paypalPhone
      );
    }

    if (resp?.msg === "Success") {
      Swal.fire({
        title: `${t("completedText")}`,
        icon: `success`,
        confirmButtonText: `${t("checkText")}`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resp = await getCalculateData();

          if (resp.msg === "Success") {
            dispatch(setCalculate(resp.data.data));

            dispatch(setSideBar(3));
            navigate("/ReMyPage");
          }

          if (resp.msg === "Fail") {
            if (resp.data.response.status === 403) {
              navigate("/renewalLogin");
              return;
            }
            if (resp.data.response.status === 500) {
              navigate("/renewalLogin");
              return;
            }
            if (resp.data.response.data.message === "등록한정보가 없음") {
              dispatch(setCalculate("nodata"));
              return;
            }
            navigate("/renewalLogin");
            console.log("실패", resp);
            return;
          }
        }
      });
      return;
    }

    if (resp.msg === "Fail") {
      if (resp.data.response.status === 500) {
        navigate("/renewalLogin");
        return;
      }
      if (resp.data.response.status === 403) {
        navigate("/renewalLogin");
        return;
      }
      return;
    }
  };

  const handleReSave = () => {
    Swal.fire({
      title: `${modificationInformationText}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("fixingText")}`,
      cancelButtonText: `${t("cancellationText")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(resetCalculate());
        navigate("/CalculatePage");
        window.location.reload(); // 페이지 새로고침
      }
    });
  };

  return (
    <div className="card">
      <div className="table table-row">
        <div className="table-inner">
          <table>
            <tbody>
              <tr>
                <th scope="row">
                  <span>{t("businessNumberText")}</span>
                </th>
                <td>
                  <div
                    style={{
                      display: `flex`,
                      width: `100%`,
                      alignItems: `center`,
                    }}
                  >
                    <input
                      style={{ textAlign: "left" }}
                      className="form-control"
                      placeholder={t("businessRegistrationText")}
                      disabled={isDisableCon || isCheckRegistration}
                      value={isRegistrationNumber}
                      onChange={handleRegistration}
                    ></input>
                    {isCheckRegistration ? (
                      <RegistrationButton
                        className="btn btn-sm btn-primary"
                        onClick={() => handleCheckReset()}
                      >
                        {t("registrationNumberText")}
                      </RegistrationButton>
                    ) : (
                      <>
                        {languageData === "en" ? (
                          <></>
                        ) : (
                          <RegistrationButton
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                              handleCheckNumber(isRegistrationNumber)
                            }
                          >
                            {t("businessIdentificationText")}
                          </RegistrationButton>
                        )}
                      </>
                    )}
                  </div>
                  {isCheckRegistration === false && (
                    <div style={{ color: `red` }}>
                      {t("businessReCheckNumberText")}
                    </div>
                  )}
                  {isCheckRegistration === true && (
                    <div style={{ color: `#357fed` }}>
                      {t("businessNumberCheckSuccessText")}
                    </div>
                  )}
                </td>
                <tr></tr>
                {languageData === "en" ? (
                  <>
                    <th scope="row">PayPal UserName</th>
                    <td>
                      <input
                        style={{ textAlign: "left" }}
                        className="form-control"
                        value={isPayPalName}
                        disabled={isDisableCon}
                        onChange={handlePayPalName}
                        placeholder="Please enter your PayPal username."
                      ></input>
                    </td>
                  </>
                ) : (
                  <>
                    <th scope="row" style={{ backgroundColor: `#fff` }}></th>
                    <td>
                      {/* <input
                    style={{ textAlign: "left" }}
                    class="form-control"
                    placeholder="대표자 성명을 입력해주세요."
                  ></input> */}
                    </td>
                  </>
                )}
              </tr>
              {languageData === "en" ? (
                <tr>
                  <th scope="row">
                    PayPal
                    <br />
                    PhoneNumber
                  </th>
                  <td>
                    <div style={{ display: `flex`, width: `100%` }}>
                      <input
                        style={{ textAlign: "left" }}
                        className="form-control"
                        placeholder="Please enter your PayPal phone number."
                        value={isPayPalPhone}
                        onChange={handlePayPalPhone}
                        disabled={isDisableCon}
                      ></input>
                    </div>
                  </td>

                  <th scope="row">PayPal Email</th>
                  <td>
                    <input
                      style={{ textAlign: "left" }}
                      className="form-control"
                      placeholder="Please enter your PayPal email."
                      value={isPayPalEmail}
                      onChange={handlePayPalEmail}
                      disabled={isDisableCon}
                    ></input>
                  </td>
                </tr>
              ) : (
                <tr>
                  <th scope="row">{t("bankNameText")}</th>
                  <td>
                    <div style={{ display: `flex`, width: `100%` }}>
                      <input
                        style={{ textAlign: "left" }}
                        className="form-control"
                        placeholder={t("pleaseBankNameInputText")}
                        value={selectedBank}
                        onChange={handleChange}
                        disabled={true}
                      ></input>
                      <BankModal onSelectBank={handleSelectBank} />
                    </div>
                  </td>

                  <th scope="row">{t("accountNumberText")}</th>
                  <td>
                    <input
                      style={{ textAlign: "left" }}
                      className="form-control"
                      placeholder={t("pleaseAccountNumberText")}
                      value={isAccount}
                      onChange={handleAccount}
                      disabled={isDisableCon}
                    ></input>
                  </td>
                </tr>
              )}

              <tr>
                {languageData === "ko" && (
                  <>
                    <th scope="row">{t("accountMasterHumanText")}</th>
                    <td>
                      <input
                        className="form-control"
                        placeholder={t("accountMasterHumanNameText")}
                        value={isName}
                        onChange={handleName}
                        disabled={isDisableCon}
                      ></input>
                    </td>
                  </>
                )}

                {languageData === "en" ? (
                  <>
                    <th scope="row">
                      {t("wordBusinessStyleText")} /{" "}
                      {t("wordTypeOfBusinessText")}
                    </th>
                    <td>
                      <div style={{ display: `flex`, width: `100%` }}>
                        <input
                          style={{ textAlign: "left" }}
                          className="form-control"
                          placeholder={t("businessStyleText")}
                          value={isState}
                          onChange={handleState}
                          disabled={isDisableCon}
                        ></input>
                        <div
                          style={{ marginLeft: `10px`, marginRight: `10px` }}
                        ></div>
                        <input
                          style={{ textAlign: "left" }}
                          className="form-control"
                          placeholder={t("typeOfBusinessText")}
                          value={isBtype}
                          onChange={handleBtype}
                          disabled={isDisableCon}
                        ></input>
                      </div>
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <th scope="row">{t("companyFullNameText")}</th>
                <td>
                  <input
                    style={{ textAlign: "left" }}
                    className="form-control"
                    placeholder={t("companyNameText")}
                    value={isComponyName}
                    onChange={handleComponyName}
                    disabled={isDisableCon}
                  ></input>
                </td>
                <th scope="row">{t("companyCEONameText")}</th>
                <td>
                  <input
                    style={{ textAlign: "left" }}
                    className="form-control"
                    placeholder={t("companyMasterNameText")}
                    value={isOwnerName}
                    onChange={handleOwnerName}
                    disabled={isDisableCon}
                  ></input>
                </td>
              </tr>
              <tr>
                {languageData === "en" ? (
                  <></>
                ) : (
                  <>
                    <th scope="row">{t("phoneNumberText")}</th>
                    <td>
                      {" "}
                      <input
                        style={{ textAlign: "left" }}
                        className="form-control"
                        placeholder={t("phoneNumberMasterInputText")}
                        value={isPhoneNumber}
                        disabled={isDisableCon}
                        onChange={handlePhoneNumber}
                        maxLength={11}
                      ></input>
                    </td>
                  </>
                )}
                {languageData === "en" ? (
                  <></>
                ) : (
                  <>
                    <th scope="row">
                      {t("wordBusinessStyleText")} /{" "}
                      {t("wordTypeOfBusinessText")}
                    </th>
                    <td>
                      <div style={{ display: `flex`, width: `100%` }}>
                        <input
                          style={{ textAlign: "left" }}
                          className="form-control"
                          placeholder={t("businessStyleText")}
                          value={isState}
                          onChange={handleState}
                          disabled={isDisableCon}
                        ></input>
                        <div
                          style={{ marginLeft: `10px`, marginRight: `10px` }}
                        ></div>
                        <input
                          style={{ textAlign: "left" }}
                          className="form-control"
                          placeholder={t("typeOfBusinessText")}
                          value={isBtype}
                          onChange={handleBtype}
                          disabled={isDisableCon}
                        ></input>
                      </div>
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <th scope="row">
                  <span>{t("wordEmailText")}</span>
                </th>
                <td>
                  <input
                    style={{ textAlign: "left" }}
                    className="form-control"
                    placeholder={t("emailInputText")}
                    value={isEmail}
                    onChange={handleEmail}
                    disabled={isDisableCon}
                  ></input>
                </td>
                <th scope="row">{t("wordAddressText")}</th>
                <td>
                  {languageData === "en" ? (
                    <></>
                  ) : (
                    <div style={{ display: `flex`, width: `100%` }}>
                      <input
                        className="form-control"
                        value={isAddressCode}
                        disabled={true}
                        placeholder={t("searchAddressInputText")}
                      ></input>
                      <AddressModal onAddressPost={handleComplete} />
                    </div>
                  )}
                  {isDisableCon === false ? (
                    <div style={{ display: `flex`, width: `100%` }}>
                      {languageData !== "ko" && (
                        <>
                          <input
                            className="form-control"
                            value={isEnAddress}
                            onChange={handleEnAddress}
                            placeholder={t("searchAddressInputText")}
                          ></input>
                          <div style={{ width: `15px` }}></div>
                        </>
                      )}

                      <input
                        className="form-control"
                        placeholder={t("detailAddressInputText")}
                        value={isDetailAddress}
                        onChange={handleDetailAddress}
                        disabled={isDisableCon}
                      ></input>
                    </div>
                  ) : (
                    <div style={{ display: `flex`, width: `100%` }}>
                      <input
                        className="form-control"
                        value={
                          languageData !== "ko" ? isEnAddress : isDetailAddress
                        }
                        disabled={true}
                        onChange={handleEnAddress}
                        placeholder={t("searchAddressInputText")}
                      ></input>
                      {/* <div style={{ width: `15px` }}></div> */}
                      {/* <input
                        className="form-control"
                        placeholder={t("detailAddressInputText")}
                        value={isDetailAddress}
                        onChange={handleDetailAddress}
                        disabled={isDisableCon}
                      ></input> */}
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <span>{t("serviceUtilizationPlatformText")}</span>
                </th>
                <td>
                  <input
                    className="form-control"
                    placeholder={t("myServicePlatformURLText")}
                    value={isPlatfrom}
                    onChange={handlePlatFrom}
                    disabled={isDisableCon}
                  ></input>
                </td>
              </tr>
              <tr>
                <th scope="row">{t("applicationDocumentsText")}</th>
                <td>
                  <div style={{ display: `flex`, width: `100%` }}>
                    <div className="file-add-area">
                      <div className="file-list" style={{}}>
                        <div
                          style={{
                            display: `flex`,
                            flexDirection: `column`,
                          }}
                        >
                          {files.map((file) => (
                            <div
                              className="file-item"
                              style={{ marginBottom: `0.5rem` }}
                              key={file.name + file.lastModified}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div style={{ display: `flex` }}>
                                  <div style={{ fontWeight: `800` }}>
                                    {t("fileNameText")} :{" "}
                                  </div>
                                  {calculateData !== null ? (
                                    <div
                                      style={{
                                        marginLeft: `10px`,
                                        cursor: `pointer`,
                                        color: `#357fed`,
                                      }}
                                      onClick={() => handleDown(file)}
                                    >
                                      {file.name}
                                    </div>
                                  ) : (
                                    <div style={{ marginLeft: `10px` }}>
                                      {file.name}
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <div>{(file.size / 1024).toFixed(2)} KB</div> */}
                                  {isDisableCon ? (
                                    <></>
                                  ) : (
                                    <button
                                      style={{
                                        height: `1.5rem`,
                                        alignItems: `center`,
                                        justifyContent: `center`,
                                        display: `flex`,
                                        borderRadius: `8px`,
                                        width: `1.5rem`,
                                        backgroundColor: `#FB122F`,
                                        color: `#fff`,
                                        marginLeft: `10px`,
                                      }}
                                      onClick={() => handleRemoveFile(file)}
                                      disabled={isDisableCon}
                                      className="bg"
                                    >
                                      <IoClose style={{ height: `3rem` }} />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {isDisableCon ? (
                      <div></div>
                    ) : (
                      <MyDropzone setFiles={setFiles} />
                    )}
                  </div>
                </td>
              </tr>

              <RequiredInputBox>
                <i className="req">{t("requiredInputText")}</i>
                <div>
                  {languageData === "en" ? (
                    <strong>
                      {" "}
                      Please attach a copy of your business registration.
                    </strong>
                  ) : (
                    <strong>{t("attachedDocumentsText")}</strong>
                  )}
                </div>
              </RequiredInputBox>
              {languageData === "en" ? (
                <></>
              ) : (
                <RequiredInputBox>
                  <i className="req">{t("requiredInputText")}</i>
                  <div>
                    <strong>{t("allInputRequiredText")}</strong>
                  </div>
                </RequiredInputBox>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isDisableCon ? (
        <></>
      ) : (
        <div>
          <SignUpCheckBoxStyled>
            <input
              type="checkBox"
              className="input-checkBox"
              checked={allChecked}
              onChange={handleAllChecked}
            />
            <TextTitleStyled>{t("allSuccessText")}</TextTitleStyled>
          </SignUpCheckBoxStyled>
          <FlexDivText style={{ alignItems: `center` }}>
            <SignUpCheckBoxStyled>
              <input
                type="checkBox"
                className="input-checkBox"
                checked={oneChecked}
                onChange={handleIndividualCheck(setOneChecked)}
              />
              <TextTitleStyled>{t("platFromConditionsText")}</TextTitleStyled>
            </SignUpCheckBoxStyled>
            <TextModal>
              {languageData === "en" ? (
                <SettlementConditionsEn />
              ) : (
                <SettlementConditions />
              )}
            </TextModal>
          </FlexDivText>
          <ScrollableDiv>
            {languageData === "en" ? (
              <SettlementConditionsEn />
            ) : (
              <SettlementConditions />
            )}
          </ScrollableDiv>
          <FlexDivText style={{ alignItems: `center` }}>
            <SignUpCheckBoxStyled>
              <input
                type="checkBox"
                className="input-checkBox"
                checked={twoChecked}
                onChange={handleIndividualCheck(setTwoChecked)}
              />
              <TextTitleStyled>
                {t("platFromNoticeConsentText")}
              </TextTitleStyled>{" "}
            </SignUpCheckBoxStyled>

            <TextModal>
              {languageData === "en" ? (
                <InformedConsentEn />
              ) : (
                <InformedConsent />
              )}{" "}
            </TextModal>
          </FlexDivText>
          <ScrollableDiv>
            {languageData === "en" ? (
              <InformedConsentEn />
            ) : (
              <InformedConsent />
            )}{" "}
          </ScrollableDiv>
          <FlexDivText style={{ alignItems: `center` }}>
            <SignUpCheckBoxStyled>
              <input
                type="checkBox"
                className="input-checkBox"
                checked={threeChecked}
                onChange={handleIndividualCheck(setThreeChecked)}
              />
              <TextTitleStyled>{t("personalInformationText")}</TextTitleStyled>
            </SignUpCheckBoxStyled>

            <TextModal>
              {languageData === "en" ? <ConsentToUseEn /> : <ConsentToUse />}
            </TextModal>
          </FlexDivText>
          <ScrollableDiv>
            {languageData === "en" ? <ConsentToUseEn /> : <ConsentToUse />}
          </ScrollableDiv>
          <FlexDivText style={{ alignItems: `center` }}>
            <SignUpCheckBoxStyled>
              <input
                type="checkBox"
                className="input-checkBox"
                checked={fourChecked}
                onChange={handleIndividualCheck(setFourChecked)}
              />
              <TextTitleStyled>
                {t("confirmationApplicationText")}
              </TextTitleStyled>
            </SignUpCheckBoxStyled>
            <TextModal>
              {languageData === "en" ? <ConfirmationEn /> : <Confirmation />}
            </TextModal>
          </FlexDivText>

          <ScrollableMiniDiv>
            {languageData === "en" ? <ConfirmationEn /> : <Confirmation />}
          </ScrollableMiniDiv>
        </div>
      )}

      <div style={{ display: `flex`, justifyContent: `flex-end` }}>
        {calculateData === "nodata" || calculateData === null ? (
          <button
            className="btn btn-primary"
            style={{
              height: `3rem`,
              width: `6rem`,
              marginTop: `1rem`,
              fontSize: `18px`,
              marginRight: `1.5rem`,
            }}
            onClick={() =>
              handleSave(
                files,
                isRegistrationNumber,
                selectedBank,
                isAccount,
                isName,
                isComponyName,
                isOwnerName,
                isPhoneNumber,
                isState,
                isBtype,
                isEmail,
                isAddressCode,
                isDetailAddress,
                isPlatfrom,
                oneChecked,
                twoChecked,
                threeChecked,
                fourChecked,
                isCheckRegistration,
                isPayPalName,
                isPayPalEmail,
                isPayPalPhone,
                isEnAddress
              )
            }
          >
            {t("applicationCompletedText")}
          </button>
        ) : (
          <>
            <SaveCheckModal handleReStartSave={handleReStartSave} />
          </>
          // <button
          //   className="btn btn-primary"
          //   style={{
          //     height: `3rem`,
          //     width: `6rem`,
          //     marginTop: `1rem`,
          //     fontSize: `18px`,
          //     marginRight: `1.5rem`,
          //   }}
          //   onClick={() => handleReSave()}
          // >
          //   수정하기
          // </button>
        )}
      </div>
    </div>
  );
};
