import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

export const ToggleContainer = styled.div`
  position: relative;
  margin-top: 0.9rem;
  left: 10%;
  align-items: center;
  cursor: pointer;

  > .toggle-container {
    width: 50px;
    height: 24px;
    border-radius: 30px;
    background-color: rgb(233, 233, 234);
    background-color: rgb(173, 216, 230); /* 여기서 회색을 파란색으로 변경 */
  }
  //.toggle--checked 클래스가 활성화 되었을 경우의 CSS를 구현
  > .toggle--checked {
    background-color: rgb(0, 200, 102);
    transition: 0.5s;
  }

  > .toggle-circle {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: rgb(255, 254, 255);
    transition: 0.5s;
    //.toggle--checked 클래스가 활성화 되었을 경우의 CSS를 구현
  }
  > .toggle--checked {
    left: 27px;
    transition: 0.5s;
  }
`;

const Desc = styled.div`
  margin-top: 1.1rem;
  align-items: center;
  text-align: center;
  font-weight: 700;
  /* margin: 20px; */
`;

export const Toggle = ({ onChange }) => {
  const languageData = useSelector((state) => state.languageType.type);

  const [isOn, setisOn] = useState(languageData !== "ko");

  const toggleHandler = () => {
    const newIsOn = !isOn;

    setisOn(!isOn);
    if (onChange) {
      onChange(newIsOn);
    }
  };

  return (
    <>
      <div
        style={{
          display: `flex`,
          justifyContent: `end`,
          alignContent: `center`,
        }}
      >
        {isOn === false ? (
          <Desc>Change to English</Desc>
        ) : (
          <Desc>한국어로 변경</Desc>
        )}

        <ToggleContainer onClick={toggleHandler}>
          <div
            className={`toggle-container ${isOn ? "toggle--checked" : null}`}
          />
          <div className={`toggle-circle ${isOn ? "toggle--checked" : null}`} />
        </ToggleContainer>
      </div>
    </>
  );
};
