import styled from "styled-components";

export const ScrollableDiv = styled.div`
  height: 200px; /* 원하는 높이 지정 */
  overflow-y: auto; /* 세로 스크롤 가능하도록 설정 */
  padding: 15px; /* 선택사항: 내부 여백 설정 */
  border: 1px solid #ccc; /* 선택사항: 테두리 설정 */
  border-radius: 16px;
  margin-top: 12px;
  /* margin-left: 20px; */
  margin-right: 20px;
`;

export const ScrollTextTitle = styled.p`
  font-size: 20px;
  font-weight: 800;
`;

export const ScrollTextSubTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const ScrollableMiniDiv = styled.div`
  height: 100px; /* 원하는 높이 지정 */
  overflow-y: auto; /* 세로 스크롤 가능하도록 설정 */
  padding: 15px; /* 선택사항: 내부 여백 설정 */
  border: 1px solid #ccc; /* 선택사항: 테두리 설정 */
  border-radius: 16px;
  margin-top: 12px;
  /* margin-left: 20px; */
  margin-right: 20px;
`;
