import { ScrollTextTitle } from "../css/ScrillableDiv";

export const InformedConsent = () => {
  return (
    <div>
      <ScrollTextTitle className="mb-3">
        후원한잔 서비스 사용에 관한 고지 및 동의
      </ScrollTextTitle>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0">
         후원한잔 서비스 이용 과정에서 아래 해당되는 경우 회사의 사전통보 없이
        후원한잔 서비스 이용이 불가할 수 있습니다.;
      </p>
      <p className="mb-0" style={{ marginTop: `10px`, marginLeft: `20px` }}>
         ① 후원한잔을 사용중인 크리에이터가 이용약관에 위배되거나 불법적인
        컨텐츠를 통해 활동하는 것이 적발 또는 확인되는 경우;
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ② 정보통신법을 비롯한 현행 법령에 위배되는 행위를 한 것이 적발될 경우;
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ③ 음란물, 사회적 물의를 일으킬 만한 컨텐츠, 인격모독 등 불법적인 행위를
        동반하여 후원한잔 서비스 사용에 대한 품위 또는 품질을 저해하는 컨텐츠가
        적발될 경우;
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ④ 후원한잔이 지원하는 타 플랫폼의 약관을 위배하는 경우;
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ⑤ 기타 위 항에 언급되지 않은 법적 문제가 발생하거나 후원한잔 서비스에
        위해가 가는 행위를 했다고 판단되는 경우;
      </p>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0">
         후원한잔 서비스 사용 과정에서 위의 경우에 해당될 경우 서비스 이용이
        중단될 수 있으며 명백히 현행 법령에 위배되는 행위를 하거나 불법적인
        사용을 통한 경우 정산처리가 보류 혹은 이루어지지 않을 수 있습니다.;
      </p>
    </div>
  );
};
