export const SET_INTL_TEL_NUMBER = "SET_INTL_TEL_NUMBER";
export const TEL_RESET = "TEL_RESET";

export const setIntlTelNumber = (number) => ({
  type: SET_INTL_TEL_NUMBER,
  payload: number,
});

export const telResetNumber = () => ({
  type: TEL_RESET,
});
