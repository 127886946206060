import { useEffect, useState } from "react";
import styled from "styled-components";
import MyDropzone from "./Dropzone";
import { IoClose } from "react-icons/io5";
import { BankModal } from "./BankModal";
import { AddressModal } from "./AddressNModal";
import { ScrollableDiv, ScrollableMiniDiv } from "../css/ScrillableDiv";
import { SettlementConditions } from "../data/SettlementConditions";
import { InformedConsent } from "../data/InformedConsent";
import { ConsentToUse } from "../data/ConsentToUse";
import { Confirmation } from "../data/Confirmation";
import { SignUpCheckBoxStyled } from "../../SignUp/Renewal/css/ReSignUpStyled";
import { TextModal } from "./TextModal";
import { FlexDivText, TextTitleStyled } from "../css/FlexDiv";
import { IndividualSave } from "../repo/IndividualSave";
import Swal from "sweetalert2";
import {
  isValidEmail,
  numericRegex,
  validateCode,
  validateName,
  validateNumber,
  validatePhonNumber,
} from "../../../util/const";
import { useNavigate } from "react-router";
import { setSideBar } from "../../../common/redux/action/SidbarIndexAction";
import { useDispatch, useSelector } from "react-redux";
import { postDownloadFile } from "../repo/DownloadFile";
import {
  resetCalculate,
  setCalculate,
} from "../../../common/redux/action/CalculateAction";
import { getCalculateData } from "../repo/GetCalculateData";
import { useTranslation } from "react-i18next";
import { SettlementConditionsEn } from "../data/SettlementConditionsEn";
import { InformedConsentEn } from "../data/InformedConsentEn";
import { ConsentToUseEn } from "../data/ConsentToUseEn";
import { ConfirmationEn } from "../data/ConfirmationEn";
import { SaveCheckModal } from "./SaveCheckModal";

export const customButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  transition-duration: 0.2s;
  transition-property: background-color, box-shadow;
  will-change: background-color, box-shadow;
  border: 1px solid transparent;
  max-width: 100%;
`;

export const IndividualPage = () => {
  const { t } = useTranslation();
  const languageData = useSelector((state) => state.languageType.type);
  const calculateData = useSelector((state) => state.calculateState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAddressCode, addressCode] = useState("");
  const [files, setFiles] = useState([]); // 파일 정보를 저장할 상태
  const [selectedBank, setSelectedBank] = useState(
    calculateData?.data?.bankName || ""
  );

  /// 전체약관 동의
  const [allChecked, setAllChecked] = useState(false);
  const [oneChecked, setOneChecked] = useState(false);
  const [twoChecked, setTwoChecked] = useState(false);
  const [threeChecked, setThreeChecked] = useState(false);
  const [fourChecked, setFourChecked] = useState(false);
  const [isDisableCon, setDisableCon] = useState(false);
  /// 상태를 정의
  const [inUserName, setInUserName] = useState(calculateData?.data?.name || "");
  const [inRegistration, setRegistration] = useState(
    calculateData?.data?.code || ""
  );
  const [inPhonNumber, setPhoneNumber] = useState(
    calculateData?.data?.phone || ""
  );
  const [inAddress, setAddress] = useState("");
  const [inPlatform, setPlatform] = useState(
    calculateData?.data?.channel || ""
  );
  const [inAccountNumber, setAccountNumber] = useState(
    calculateData?.data?.bankCode || ""
  );

  const [isEmailState, setEmailState] = useState(
    calculateData?.data?.email || ""
  );
  /// En시 상태추가
  const [isEnAddress, setEnAddress] = useState("");
  const [isPayPalName, setPayPalName] = useState("");
  const [isPayPalPhoneNumber, setPayPalPhoneNumber] = useState("");
  const [isPayPalEmail, setPayPalEmail] = useState("");
  const [isIdCardType, setIdCardType] = useState("");
  const handleIdCardType = (e) => {
    setIdCardType(e.target.value);
  };
  const handleEnAddress = (e) => {
    setEnAddress(e.target.value);
  };
  const handlePayPalName = (e) => {
    setPayPalName(e.target.value);
  };
  const handlePayPalEmail = (e) => {
    setPayPalEmail(e.target.value);
  };
  const handlePayPalPhoneNumber = (e) => {
    setPayPalPhoneNumber(e.target.value);
  };
  ///

  useEffect(() => {
    if (calculateData?.data) {
      setInUserName(calculateData.data.name || "");
      setRegistration(calculateData.data.code || "");
      setPhoneNumber(calculateData.data.phone || "");
      setSelectedBank(calculateData.data.bankName || "");
      setAddress(""); // 이 값은 계속 빈 문자열로 설정되는 것으로 보임
      setPlatform(calculateData.data.channel || "");
      setAccountNumber(calculateData.data.bankCode || "");
      setEmailState(calculateData?.data?.email || "");
      if (languageData !== "ko") {
        setEnAddress(calculateData?.data?.address || "");
      }
      setPayPalName(calculateData?.data?.paypalName || "");
      setPayPalPhoneNumber(calculateData?.data?.paypalPhone || "");
      setPayPalEmail(calculateData?.data?.paypalEmail || "");
      setIdCardType(calculateData?.data?.idCardType || "");
    }
  }, [calculateData]);

  const handleEmail = (e) => {
    setEmailState(e.target.value);
  };

  /// 상태 onchange 핸들러
  const handleInName = (e) => {
    setInUserName(e.target.value);
  };
  const handleRegistration = (e) => {
    setRegistration(e.target.value);
  };
  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handlePlatform = (e) => {
    setPlatform(e.target.value);
  };

  useEffect(() => {
    if (calculateData?.data?.address) {
      const address = calculateData.data.address;
      const splitIndex = address.indexOf(")");
      if (splitIndex !== -1) {
        const formattedInAddress = address.substring(splitIndex + 1).trim();
        const formattedRemainingAddress = address
          .substring(0, splitIndex + 1)
          .trim();
        setAddress(formattedInAddress);
        addressCode(formattedRemainingAddress);
      }
    }

    if (calculateData?.data?.files) {
      const formattedFiles = calculateData.data.files.map((file) => {
        return {
          path: file.fileOriName,
          preview: URL.createObjectURL(new Blob()),
          lastModified: Date.now(),
          lastModifiedDate: new Date(),
          name: file.fileOriName,
          size: "",
          type: "image/jpeg",
          webkitRelativePath: "",
          orId: file.fileId,
          path: file.filePath,
        };
      });
      setFiles(formattedFiles);
    }

    if (calculateData.data === "nodata" || calculateData.data === null) {
      setDisableCon(false);
    } else {
      setDisableCon(true);
    }
  }, [calculateData]);
  const handleAccountNumber = (e) => {
    setAccountNumber(e.target.value);
  };

  /// 이용약관 전체동의 핸들러
  const handleAllChecked = (e) => {
    const isChecked = e.target.checked;
    setAllChecked(isChecked);
    setOneChecked(isChecked);
    setTwoChecked(isChecked);
    setThreeChecked(isChecked);
    setFourChecked(isChecked);
  };

  const handleIndividualCheck = (setter) => (e) => {
    setter(e.target.checked);
    if (!e.target.checked) {
      setAllChecked(false);
    }
  };

  const handleSelectBank = (bankName) => {
    setSelectedBank(bankName);
  };

  const handleChange = (event) => {
    setSelectedBank(event.target.value);
  };

  function handleRemoveFile(fileToRemove) {
    setFiles((prevFiles) =>
      prevFiles.filter(
        (file) =>
          file.name + file.lastModified !==
          fileToRemove.name + fileToRemove.lastModified
      )
    );
  }

  const handleComplete = (data) => {
    addressCode(data.address + " " + `(${data.zonecode})`);
  };

  /// 신청하기 버튼
  const handleSave = async (
    files,
    name,
    code,
    phone,
    address,
    detailAddress,
    email,
    plat,
    bank,
    account,
    one,
    two,
    three,
    four,
    enAddress,
    paypalName,
    paypalPhone,
    paypalEmail,
    idCardType
  ) => {
    let fullAddress;
    let resp;
    /// 유효성 검증
    /// 1. 이름이 빈값일경우
    if (!name || name.trim() === "") {
      Swal.fire({ title: `${t("pleaseInputNameText")}`, icon: `error` });
      return;
    }

    if (languageData === "ko") {
      if (!validateName(name)) {
        Swal.fire({
          title: `${t("nameValidateCheckText")}`,
          icon: `error`,
        });
        return;
      }
    }

    /// 2. 주민등록번호가 빈값일경우
    if (!validateNumber(code)) {
      Swal.fire({
        title: `${t("pleaseInputResidentNumberNoMinersText")}`,
        icon: `error`,
      });
      return;
    }

    if (!code || code.trim() === "") {
      Swal.fire({
        title: `${t("pleaseInputResidentNumberNoMinersText")}`,
        icon: `error`,
      });
      return;
    }
    /// 한국만 정규식확인
    if (languageData === "ko") {
      if (!numericRegex.test(code)) {
        Swal.fire({
          title: `${t("pleaseInputResidentNumberNoMinersText")}`,
          icon: `error`,
        });
        return;
      }
      if (!validateCode(code)) {
        Swal.fire({
          title: `${t("checkIsNumberText")}`,
          icon: "error",
        });
        return;
      }
    }

    /// 3. 전화번호가 빈값일 경우
    if (!phone || !phone.trim()) {
      Swal.fire({ title: `${t("pleaseInputPhoneNumber")}`, icon: `error` });
      return;
    }

    /// 한국만 휴대폰 정규식확인
    if (languageData === "ko") {
      if (!validatePhonNumber(phone)) {
        Swal.fire({
          title: `${t("phoneNumberCheckPleaseText")}`,
          icon: `error`,
        });
        return;
      }
    }
    /// 외국인 ID카드 타입을 입력하지 않은 경우
    if (languageData !== "ko") {
      if (!idCardType || idCardType.trim() === "") {
        Swal.fire({
          title: "Please enter your ID type.",
          icon: `error`,
        });
        return;
      }
    }

    /// 5-1. 이메일 주소가 없거나 잘못된경우
    if (!email || email.trim() === "") {
      Swal.fire({
        title: `${t("emailInputText")}`,
        icon: `error`,
      });
      return;
    }

    if (!isValidEmail(email)) {
      Swal.fire({
        title: `${t("noEmailStateText")}`,
        icon: `error`,
      });
      return;
    }
    /// 4. 주소가 빈값일 경우
    if (languageData === "ko") {
      if (!address || address.trim() === "") {
        Swal.fire({ title: `${t("addressInputText")}`, icon: `error` });
        return;
      }
    }

    /// 5. 상세주소가 빈값일 경우
    if (!detailAddress || detailAddress.trim() === "") {
      Swal.fire({ title: `${t("detailAddressInputText")}`, icon: `error` });
      return;
    }
    /// 6. 플랫폼이 빈값일 경우
    if (!plat || plat.trim() === "") {
      Swal.fire({
        title: `${t("serviceUtilizationPlatformInputText")}`,
        icon: `error`,
      });
      return;
    }

    /// 7. 은행명이 빈값일 경우
    /// 한국만 Input칸 존재함
    if (languageData === "ko") {
      if (!bank || bank.trim() === "") {
        Swal.fire({ title: `${t("trimBankNameText")}`, icon: `error` });
        return;
      }
      /// 8. 계좌번호가 빈값일 경우
      if (!account || account.trim() === "") {
        Swal.fire({ title: `${t("pleaseAccountNumberText")}`, icon: `error` });
        return;
      }

      if (!validateNumber(account)) {
        Swal.fire({
          title: `${t("checkAccountText")}`,
          icon: `error`,
        });
        return;
      }
    }

    /// 외국의 경우 PayPal정보 3가지 검증로직

    if (languageData !== "ko") {
      if (!paypalName || paypalName.trim() === "") {
        Swal.fire({
          title: "Please enter your PayPal username.",
          icon: `error`,
        });
        return;
      }

      if (!paypalPhone || paypalPhone.trim() === "") {
        Swal.fire({
          title: "Please enter your PayPal phone number.",
          icon: `error`,
        });
        return;
      }
      if (!paypalEmail || paypalEmail.trim() === "") {
        Swal.fire({
          title: "Please enter your PayPal email.",
          icon: `error`,
        });
        return;
      }

      if (!isValidEmail(paypalEmail)) {
        Swal.fire({
          title: `${t("noEmailStateText")}`,
          icon: `error`,
        });
        return;
      }
    }

    /// 9. 첨부파일이 하나도 없을 경우
    if (files.length === 0) {
      Swal.fire({ title: `${t("fileUploadText")}`, icon: `error` });
      return;
    }
    /// 10. 이용약관 동의가 없을경우
    if (!one || !two || !three || !four) {
      Swal.fire({ title: `${t("termsAndConditionsText")}`, icon: `error` });
      return;
    }

    if (languageData === "ko") {
      fullAddress = `${address} ${detailAddress}`;
    } else {
      if (enAddress && enAddress.trim() !== "") {
        fullAddress = `(${enAddress})${detailAddress}`;
      } else {
        fullAddress = `${enAddress}${detailAddress}`;
      }
    }

    if (languageData === "ko") {
      resp = await IndividualSave(
        name,
        code,
        phone,
        fullAddress,
        email,
        plat,
        bank,
        account,
        files,
        " ",
        " ",
        " ",
        " "
      );
    } else {
      resp = await IndividualSave(
        name,
        code,
        phone,
        fullAddress,
        email,
        plat,
        " ",
        " ",
        files,
        paypalName,
        paypalEmail,
        paypalPhone,
        idCardType
      );
    }

    if (resp?.msg === "Success") {
      Swal.fire({
        title: `${t("completedText")}`,
        icon: `success`,
        confirmButtonText: `${t("checkText")}`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resp = await getCalculateData();

          if (resp.msg === "Success") {
            dispatch(setCalculate(resp.data.data));

            dispatch(setSideBar(3));
            navigate("/ReMyPage");
          }

          if (resp.msg === "Fail") {
            if (resp.data.response.status === 403) {
              navigate("/renewalLogin");
              return;
            }
            if (resp.data.response.status === 500) {
              navigate("/renewalLogin");
              return;
            }
            if (resp.data.response.data.message === "등록한정보가 없음") {
              dispatch(setCalculate("nodata"));
              return;
            }
            navigate("/renewalLogin");
            console.log("실패", resp);
            return;
          }
        }
      });
      return;
    }

    if (resp.msg === "Fail") {
      if (resp.data.response.status === 500) {
        navigate("/renewalLogin");
        return;
      }
      if (resp.data.response.status === 403) {
        navigate("/renewalLogin");
        return;
      }
      return;
    }
  };

  const handleDown = async (file) => {
    const fileId = file.orId;
    const filePath = file.path;

    const fileName = file.name;
    const fileType = file.type;

    const resp = await postDownloadFile(fileId, filePath, 1);

    if (resp.msg === "Success") {
      const blob = new Blob([resp.data], { type: { fileType } });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName.split("/").pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      return;
    }

    if (resp.msg === "Fail") {
      return;
    }
    return;
  };
  const handleReSave = () => {
    dispatch(resetCalculate());
    setFiles([]);
    navigate("/CalculatePage");
    // Swal.fire({
    //   title: `${modificationInformationText}`,
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: `${fixingText}`,
    //   cancelButtonText: `${cancellationText}`,
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    //     dispatch(resetCalculate());
    //     setFiles([]);
    //     navigate("/CalculatePage", { replace: true });
    //     setTimeout(() => {
    //       window.scrollTo(0, 0);
    //     }, 0);
    //     // window.location.reload();
    //   }
    // });
  };

  const handleReStartSave = () => {
    dispatch(resetCalculate());
    setFiles([]);
    setInUserName("");
    setRegistration("");
    setPhoneNumber("");
    setSelectedBank("");
    setAddress(""); // 이 값은 계속 빈 문자열로 설정되는 것으로 보임
    setPlatform("");
    setAccountNumber("");
    setEmailState("");
    addressCode("");
    navigate("/CalculatePage");
    setEnAddress("");
    setPayPalName("");
    setPayPalPhoneNumber("");
    setPayPalEmail("");
    setIdCardType("");
  };
  return (
    <div className="card">
      <div className="table table-row">
        <div className="table-inner">
          <table>
            <tbody>
              <tr>
                <th scope="row">
                  <span>{t("RealNameText")}</span>
                </th>
                <td>
                  <input
                    style={{ textAlign: "left" }}
                    className="form-control"
                    placeholder={t("pleaseSameNameInputText")}
                    value={inUserName}
                    onChange={handleInName}
                    disabled={isDisableCon}
                  ></input>
                </td>
                <th scope="row">
                  <span>{t("residentNumberText")}</span>
                </th>

                <td>
                  <input
                    className="form-control"
                    placeholder={t("pleaseInputResidentNumberNoMinersText")}
                    value={inRegistration}
                    onChange={handleRegistration}
                    disabled={isDisableCon}
                    maxLength={languageData === "en" ? undefined : 13}
                  ></input>
                </td>
              </tr>
              <tr>
                <th scope="row">{t("phoneNumberText")}</th>
                <td>
                  <input
                    className="form-control"
                    placeholder={t("yourCellPhoneNumberText")}
                    value={inPhonNumber}
                    onChange={handlePhoneNumber}
                    disabled={isDisableCon}
                    maxLength={11}
                  ></input>
                </td>
                {languageData === "en" ? (
                  <>
                    <th scope="row">ID Type</th>
                    <td>
                      <input
                        className="form-control"
                        placeholder="Please enter your ID type."
                        value={isIdCardType}
                        onChange={handleIdCardType}
                        disabled={isDisableCon}
                      ></input>
                    </td>
                  </>
                ) : (
                  <>
                    <th scope="row">{t("wordEmailText")}</th>
                    <td>
                      <input
                        className="form-control"
                        placeholder={t("emailInputText")}
                        value={isEmailState}
                        onChange={handleEmail}
                        disabled={isDisableCon}
                      ></input>
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <th scope="row">{t("wordAddressText")}</th>
                <td>
                  {languageData === "en" ? (
                    <></>
                  ) : (
                    <div style={{ display: `flex`, width: `100%` }}>
                      <input
                        className="form-control"
                        value={isAddressCode}
                        disabled={true}
                        placeholder={t("searchAddressInputText")}
                      ></input>

                      <AddressModal onAddressPost={handleComplete} />
                    </div>
                  )}
                  {isDisableCon === false ? (
                    <div style={{ display: `flex`, width: `100%` }}>
                      {languageData !== "ko" && (
                        <>
                          <input
                            className="form-control"
                            value={isEnAddress}
                            // disabled={isDisableCon}
                            onChange={handleEnAddress}
                            placeholder={t("searchAddressInputText")}
                          ></input>
                          <div style={{ width: `15px` }}></div>
                        </>
                      )}

                      <input
                        className="form-control"
                        placeholder={t("detailAddressInputText")}
                        value={inAddress}
                        onChange={handleAddress}
                        disabled={isDisableCon}
                      ></input>
                    </div>
                  ) : (
                    <div style={{ display: `flex`, width: `100%` }}>
                      <input
                        className="form-control"
                        value={languageData !== "ko" ? isEnAddress : inAddress}
                        disabled={isDisableCon}
                        onChange={handleEnAddress}
                        placeholder={t("searchAddressInputText")}
                      ></input>
                      {/* <div style={{ width: `15px` }}></div>
                      <input
                        className="form-control"
                        placeholder={t("detailAddressInputText")}
                        value={inAddress}
                        onChange={handleAddress}
                        disabled={isDisableCon}
                      ></input> */}
                    </div>
                  )}
                </td>
                <th scope="row">{t("serviceUtilizationPlatformText")}</th>
                <td>
                  <input
                    className="form-control"
                    placeholder={t("myServicePlatformURLText")}
                    value={inPlatform}
                    onChange={handlePlatform}
                    disabled={isDisableCon}
                  ></input>
                </td>
              </tr>
              {languageData === "en" ? (
                <tr>
                  <th scope="row">PayPal UserName</th>
                  <td>
                    <input
                      className="form-control"
                      placeholder="Please enter your PayPal username."
                      value={isPayPalName}
                      onChange={handlePayPalName}
                      disabled={isDisableCon}
                    ></input>
                  </td>

                  <th scope="row">PayPal Email</th>
                  <td>
                    <input
                      className="form-control"
                      placeholder="Please enter your PayPal email."
                      value={isPayPalEmail}
                      onChange={handlePayPalEmail}
                      disabled={isDisableCon}
                    ></input>
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {languageData === "en" ? (
                <>
                  <tr>
                    <th scope="row">
                      PayPal <br></br>PhoneNumber
                    </th>
                    <td>
                      <input
                        className="form-control"
                        placeholder="Please enter your PayPal phone number."
                        value={isPayPalPhoneNumber}
                        onChange={handlePayPalPhoneNumber}
                        disabled={isDisableCon}
                      ></input>
                    </td>
                    <th scope="row">{t("wordEmailText")}</th>
                    <td>
                      <input
                        className="form-control"
                        placeholder={t("emailInputText")}
                        value={isEmailState}
                        onChange={handleEmail}
                        disabled={isDisableCon}
                      ></input>
                    </td>
                  </tr>
                </>
              ) : (
                <></>
              )}

              {languageData === "en" ? (
                <></>
              ) : (
                <tr>
                  <th scope="row">{t("bankNameText")}</th>
                  <td>
                    <div style={{ display: `flex`, width: `100%` }}>
                      <input
                        className="form-control"
                        placeholder={t("pleaseBankNameInputText")}
                        value={selectedBank}
                        onChange={handleChange}
                        disabled={true}
                        // isDisableCon
                      ></input>
                      <BankModal onSelectBank={handleSelectBank} />
                    </div>
                  </td>
                  <th scope="row">{t("accountNumberText")}</th>
                  <td>
                    <input
                      className="form-control"
                      placeholder={t("pleaseAccountNumberText")}
                      value={inAccountNumber}
                      onChange={handleAccountNumber}
                      disabled={isDisableCon}
                    ></input>
                  </td>
                </tr>
              )}

              {/* <tr>
                <th scope="row">
                  <span>후원한잔 사용 채널</span>
                </th>
                <td>
                  <input className="form-control"></input>
                </td>
              </tr> */}
              <tr>
                <th scope="row">{t("applicationDocumentsText")}</th>
                <td>
                  <div style={{ display: `flex`, width: `100%` }}>
                    <div className="file-add-area">
                      <div className="file-list" style={{}}>
                        <div
                          style={{
                            display: `flex`,
                            flexDirection: `column`,
                          }}
                        >
                          {files?.map((file) => (
                            <div
                              className="file-item"
                              style={{ marginBottom: `0.5rem` }}
                              key={file.name + file.lastModified}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div style={{ display: `flex` }}>
                                  <div style={{ fontWeight: `800` }}>
                                    {t("fileNameText")} :{" "}
                                  </div>
                                  {calculateData?.data !== null ? (
                                    <div
                                      style={{
                                        marginLeft: `10px`,
                                        cursor: `pointer`,
                                        color: `#357fed`,
                                      }}
                                      onClick={() => handleDown(file)}
                                    >
                                      {file.name}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        marginLeft: `10px`,
                                      }}
                                    >
                                      {file.name}
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {isDisableCon ? (
                                    <></>
                                  ) : (
                                    <button
                                      style={{
                                        height: `1.5rem`,
                                        alignItems: `center`,
                                        justifyContent: `center`,
                                        display: `flex`,
                                        borderRadius: `8px`,
                                        width: `1.5rem`,
                                        backgroundColor: `#FB122F`,
                                        color: `#fff`,
                                        marginLeft: `10px`,
                                      }}
                                      onClick={() => handleRemoveFile(file)}
                                      disabled={isDisableCon}
                                      className="bg"
                                    >
                                      <IoClose style={{ height: `3rem` }} />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {isDisableCon ? (
                      <div></div>
                    ) : (
                      <MyDropzone setFiles={setFiles} />
                    )}
                  </div>
                </td>
              </tr>

              <div
                style={{
                  display: `flex`,
                  justifyContent: `flex-end`,
                  marginTop: `1rem`,
                  marginBottom: `1rem`,
                }}
              >
                <i class="req">{t("requiredInputText")}</i>
                <div>
                  {languageData === "en" ? (
                    <strong>Please attach a copy of your ID card.</strong>
                  ) : (
                    <strong>{t("companyAttachedDocumentsText")}</strong>
                  )}
                </div>
              </div>
              {languageData === "en" ? (
                <></>
              ) : (
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `flex-end`,
                    marginTop: `1rem`,
                    marginBottom: `rem`,
                  }}
                >
                  <i class="req">{t("requiredInputText")}</i>
                  <div>
                    <strong>{t("allInputRequiredText")}</strong>
                  </div>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isDisableCon ? (
        <></>
      ) : (
        <div>
          <SignUpCheckBoxStyled>
            <input
              type="checkBox"
              className="input-checkBox"
              checked={allChecked}
              onChange={handleAllChecked}
            />
            <TextTitleStyled>{t("allSuccessText")}</TextTitleStyled>
          </SignUpCheckBoxStyled>
          <FlexDivText style={{ alignItems: `center` }}>
            <SignUpCheckBoxStyled>
              <input
                type="checkBox"
                className="input-checkBox"
                checked={oneChecked}
                onChange={handleIndividualCheck(setOneChecked)}
              />
              <TextTitleStyled>{t("platFromConditionsText")}</TextTitleStyled>
            </SignUpCheckBoxStyled>
            <TextModal>
              {languageData === "en" ? (
                <SettlementConditionsEn />
              ) : (
                <SettlementConditions />
              )}
            </TextModal>
          </FlexDivText>
          <ScrollableDiv>
            {languageData === "en" ? (
              <SettlementConditionsEn />
            ) : (
              <SettlementConditions />
            )}
          </ScrollableDiv>
          <FlexDivText style={{ alignItems: `center` }}>
            <SignUpCheckBoxStyled>
              <input
                type="checkBox"
                className="input-checkBox"
                checked={twoChecked}
                onChange={handleIndividualCheck(setTwoChecked)}
              />
              <TextTitleStyled>
                {t("platFromNoticeConsentText")}
              </TextTitleStyled>{" "}
            </SignUpCheckBoxStyled>

            <TextModal>
              {languageData === "en" ? (
                <InformedConsentEn />
              ) : (
                <InformedConsent />
              )}
            </TextModal>
          </FlexDivText>
          <ScrollableDiv>
            {languageData === "en" ? (
              <InformedConsentEn />
            ) : (
              <InformedConsent />
            )}
          </ScrollableDiv>
          <FlexDivText style={{ alignItems: `center` }}>
            <SignUpCheckBoxStyled>
              <input
                type="checkBox"
                className="input-checkBox"
                checked={threeChecked}
                onChange={handleIndividualCheck(setThreeChecked)}
              />
              <TextTitleStyled>{t("personalInformationText")}</TextTitleStyled>
            </SignUpCheckBoxStyled>

            <TextModal>
              {languageData === "en" ? <ConsentToUseEn /> : <ConsentToUse />}
            </TextModal>
          </FlexDivText>
          <ScrollableDiv>
            {languageData === "en" ? <ConsentToUseEn /> : <ConsentToUse />}
          </ScrollableDiv>
          <FlexDivText style={{ alignItems: `center` }}>
            <SignUpCheckBoxStyled>
              <input
                type="checkBox"
                className="input-checkBox"
                checked={fourChecked}
                onChange={handleIndividualCheck(setFourChecked)}
              />
              <TextTitleStyled>
                {t("confirmationApplicationText")}
              </TextTitleStyled>
            </SignUpCheckBoxStyled>
            <TextModal>
              {languageData === "en" ? <ConfirmationEn /> : <Confirmation />}
            </TextModal>
          </FlexDivText>

          <ScrollableMiniDiv>
            {languageData === "en" ? <ConfirmationEn /> : <Confirmation />}
          </ScrollableMiniDiv>
        </div>
      )}

      <div
        style={{
          display: `flex`,
          justifyContent: `flex-end`,
          marginRight: `1.5rem`,
        }}
      >
        {/* <button className="btn btn-primary">수정하기</button> */}
        {calculateData.data === "nodata" || calculateData.data === null ? (
          <button
            className="btn btn-primary"
            style={{
              height: `3rem`,
              width: `6rem`,
              marginTop: `1rem`,
              fontSize: `18px`,
            }}
            onClick={() =>
              handleSave(
                files,
                inUserName,
                inRegistration,
                inPhonNumber,
                isAddressCode,
                inAddress,
                isEmailState,
                inPlatform,
                selectedBank,
                inAccountNumber,
                oneChecked,
                twoChecked,
                threeChecked,
                fourChecked,
                isEnAddress,
                isPayPalName,
                isPayPalPhoneNumber,
                isPayPalEmail,
                isIdCardType
              )
            }
          >
            {t("applicationCompletedText")}
          </button>
        ) : (
          <>
            <SaveCheckModal handleReStartSave={handleReStartSave} />
          </>
          // <button
          //   className="btn btn-primary"
          //   style={{
          //     height: `3rem`,
          //     width: `6rem`,
          //     marginTop: `1rem`,
          //     fontSize: `18px`,
          //   }}
          //   onClick={handleReSave}
          // >
          //   {fixingText}
          // </button>
        )}
      </div>
    </div>
  );
};
