import { useEffect, useState } from "react";
import {
  SigUpH6Styled,
  SignUpCallNumberStyled,
  SignUpCheckNumberButtonStyled,
  SignUpCheckNumberStyledInput,
  SignUpContainerDivStyled,
  SignUpContentDivStyled,
  SignUpEmailStyledInput,
  SignUpEyeDivStyled,
  SignUpH2TitleStyled,
  SignUpImgLogoStyled,
  SignUpLinkStyled,
  SignUpMarginBottomDivStyled,
  SignUpMarginTopDivStyled,
  SignUpOverlapButtonStyled,
  SignUpPasswordDivStyled,
  SignUpPasswordInputStyled,
  SignUpStyledFaRegEyeIcon,
  SignUpStyledFaRegEyeSlashIcon,
  SignUpStyledIcon,
  SignUpSuccessButtonStyled,
} from "../../SignUp/Renewal/css/ReSignUpStyled";
import {
  getEmailCheck,
  getSendAuthCode,
  getVerifyAuthCode,
} from "../../SignUp/Renewal/repo/EmailCheck";
import Swal from "sweetalert2";
import { emailRegex } from "../../../util/const";
import { SendAuthCodeEmail } from "../repo/SendauthCodeEmail";
import { EmailVerifyAuthCode } from "../repo/PasswordVerifyauth";
import { validatePassword } from "../../../common/utils/validator";
import { ChangePassword } from "../repo/PostChangePassword";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

export const FindingPasswordPage = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isEyeState, setEyeState] = useState(false);
  const [isCheckEyeState, setCheckEyeState] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [isCheckEmail, setCheckEmail] = useState(false);
  const [isInputPasswordState, setInputPasswordState] = useState(false);
  const [isCheckPasswordState, setCheckPasswordState] = useState(false);
  const [isCrossCheckPasswordState, setCrossCheckPasswordState] =
    useState(false);
  const [isEvId, setEvId] = useState("");
  /// 이메일

  const [isCurrentEmail, setCurrentEmail] = useState("");

  const handleChangeEmail = (e) => {
    setCurrentEmail(e.target.value);
  };
  useEffect(() => {
    if (inputPassword.trim() === "" || checkPassword.trim() === "") {
      setCrossCheckPasswordState(false);
    } else if (inputPassword === checkPassword) {
      setCrossCheckPasswordState(true);
    } else {
      setCrossCheckPasswordState(false);
    }
  }, [inputPassword, checkPassword]);

  useEffect(() => {
    const Re = validatePassword(inputPassword);
    setInputPasswordState(Re);
  }, [inputPassword]);

  useEffect(() => {
    const Re = validatePassword(checkPassword);
    setCheckPasswordState(Re);
  }, [checkPassword]);

  const handleInputChange = (event) => {
    setInputPassword(event.target.value);
  };
  const handleEyeClick = () => {
    setEyeState(!isEyeState);
  };

  const handleCheckInputChange = (event) => {
    setCheckPassword(event.target.value);
  };

  const handleCheckEyeClick = () => {
    setCheckEyeState(!isCheckEyeState);
  };
  /// 이메일 상태 MSG
  const [isCode, setCode] = useState(false);
  const [isNoEmail, setNoEmail] = useState(false);
  const [whatError, setWhatError] = useState(false);

  /// 이메일 검증 상태
  // const [isSuccessPostEmail, setSuccessPostEmail] = useState(false);

  /// 이메일 인증요청

  useEffect(() => {
    if (!emailRegex.test(isCurrentEmail)) {
      setNoEmail(false);
    } else {
      setNoEmail(true);
    }
  }, [isCurrentEmail]);

  const handleEmailCheck = async (email) => {
    try {
      setCheckEmail(true);

      const resp = await getEmailCheck(email);

      if (resp.msg === "Success") {
        const codeResp = await SendAuthCodeEmail(email);

        if (codeResp.msg === "Fail") {
          console.log("이메일 인증코드 전송 실패", codeResp);
          setCheckEmail(false);
          return;
        }
      }

      if (resp.msg === "Fail") {
        Swal.fire({
          title: t("emailVerifyFailMessage"),
          icon: `error`,
        });
        setCheckEmail(false);
      }
    } catch (error) {
      Swal.fire({
        title: t("emailVerifyFailMessage"),
        icon: `error`,
      });
      console.log("이메일 인증 에러", error);
      setCheckEmail(false);
    }
  };

  /// 이메일 재전송
  const handleEmailCheckNumberRe = async (email) => {
    try {
      const codeResp = await SendAuthCodeEmail(email);

      if (codeResp.msg === "Fail") {
        console.log("이메일 인증코드 전송 실패", codeResp);
        setCheckEmail(false);
        return;
      }
    } catch (error) {
      console.log("재전송중 문제가 생김", error);
      setCheckEmail(false);
    }
  };

  /// 변경처리

  const handleEmailChange = async (code, pw, email, ev) => {
    const resp = await ChangePassword(code, pw, email, ev);

    if (resp.msg === "Success") {
      Swal.fire({
        title: t("passwordChangeMessage"),
        icon: "success",
        confirmButtonText: t("checkText"),
      }).then(() => {
        navigate("/renewalLogin");
      });
    }

    if (resp.msg === "Fail") {
      console.log("변경실패", resp);

      if (
        resp.data.response.data.message ===
        "기존의 비밀번호와 지금의 비빌번호가 일치함"
      ) {
        Swal.fire({
          title: t("passwordSameCheck"),
          icon: "error",
        });
      } else if (
        resp.data.response.data.message === "카카오로그인을 이용하세요"
      ) {
        Swal.fire({
          title: t("kakaoEmailLoginMessage"),
          icon: "error",
        });
      } else {
        Swal.fire({
          title: t("passwordChangeFailMessage"),
          icon: "error",
        });
      }

      return;
    }
  };
  /// 다시 확인 필요

  const [isCheckNumber, setCheckNumber] = useState(false);

  /// 인증번호 상태
  const [isCurrentCertifiedNumber, setCurrentCertifiedNumber] = useState("");

  useEffect(() => {}, [isCurrentCertifiedNumber]);

  const handleCertifiedNumber = (e) => {
    setCurrentCertifiedNumber(e.target.value);
  };

  const handleCheckNumber = async (email, code) => {
    const resp = await EmailVerifyAuthCode(email, code);

    if (resp.msg === "Success") {
      setEvId(resp.data.data.data);
      setCheckNumber(true);
      setWhatError(false);
      setCode(true);
    }

    if (resp.msg === "Fail") {
      if (resp.data.response.data.message === "잘못된 이메일 혹은 인증번호") {
        setCheckNumber(false);
        setWhatError(true);
        console.log("인증번호 잘못됨");
        return;
      }
    }

    return;
  };

  return (
    <SignUpContentDivStyled className="content">
      <SignUpContainerDivStyled className="container ">
        <div>
          <SignUpMarginTopDivStyled>
            <img src="/group.svg" alt="icon" />
            <SignUpImgLogoStyled src="/LogoBlack.svg" alt="" />
          </SignUpMarginTopDivStyled>
        </div>
        <SignUpLinkStyled to="/renewalLogin">
          <SignUpStyledIcon />
          {t("loginPageText")}
        </SignUpLinkStyled>
        <SignUpMarginBottomDivStyled className="card">
          <div className="card-body">
            <SignUpH2TitleStyled className="title1">
              {t("changePasswordText")}
            </SignUpH2TitleStyled>
            <br />
            <SigUpH6Styled>{t("wordEmailText")}</SigUpH6Styled>
            <SignUpCallNumberStyled>
              <SignUpEmailStyledInput
                placeholder="xrai@xrai.com"
                value={isCurrentEmail}
                onChange={handleChangeEmail}
                disabled={isCheckEmail}
              ></SignUpEmailStyledInput>
              {isCheckEmail ? (
                <SignUpOverlapButtonStyled
                  className="btn btn-lg btn-gray"
                  onClick={() => handleEmailCheckNumberRe(isCurrentEmail)}
                >
                  {t("retransmissionText")}
                </SignUpOverlapButtonStyled>
              ) : (
                <SignUpOverlapButtonStyled
                  disabled={!isNoEmail}
                  className="btn btn-lg btn-primary"
                  onClick={() => handleEmailCheck(isCurrentEmail)}
                >
                  {t("emailCertifiedText")}
                </SignUpOverlapButtonStyled>
              )}
            </SignUpCallNumberStyled>
            <SignUpCallNumberStyled style={{ marginTop: `10px` }}>
              <SignUpCheckNumberStyledInput
                placeholder={t("pleaseEnterNumberText")}
                value={isCurrentCertifiedNumber}
                onChange={handleCertifiedNumber}
                disabled={isCheckNumber}
              ></SignUpCheckNumberStyledInput>

              <SignUpCheckNumberButtonStyled
                className="btn btn-lg btn-primary"
                onClick={() =>
                  handleCheckNumber(isCurrentEmail, isCurrentCertifiedNumber)
                }
                disabled={isCode}
              >
                {t("checkEnterNumberText")}
              </SignUpCheckNumberButtonStyled>
            </SignUpCallNumberStyled>
            {whatError && (
              <div
                style={{
                  marginTop: `10px`,
                  color: `red`,
                }}
              >
                {t("invalidNumberText")}
              </div>
            )}

            <SigUpH6Styled>{t("passwordText")}</SigUpH6Styled>
            <SignUpPasswordDivStyled>
              <SignUpPasswordInputStyled
                placeholder={t("passwordGuideText")}
                type={isEyeState ? "text" : "password"}
                value={inputPassword}
                onChange={handleInputChange}
              ></SignUpPasswordInputStyled>
              <SignUpEyeDivStyled onClick={() => handleEyeClick()}>
                {isEyeState === true && <SignUpStyledFaRegEyeIcon />}

                {isEyeState === false && <SignUpStyledFaRegEyeSlashIcon />}
              </SignUpEyeDivStyled>
            </SignUpPasswordDivStyled>
            {isInputPasswordState || inputPassword.trim() === "" ? (
              <div></div>
            ) : (
              <div
                style={{
                  marginTop: `10px`,
                  color: `red`,
                }}
              >
                {t("passwordGuidingText")}
              </div>
            )}
            {/* {isCrossCheckPasswordState === false && (
              <div>비밀번호가 동일한지 확인</div>
            )} */}
            <SigUpH6Styled>{t("finalCheckPasswordText")}</SigUpH6Styled>
            <SignUpPasswordDivStyled>
              <SignUpPasswordInputStyled
                placeholder={t("passwordGuideText")}
                type={isCheckEyeState ? "text" : "password"}
                value={checkPassword}
                onChange={handleCheckInputChange}
              ></SignUpPasswordInputStyled>
              <SignUpEyeDivStyled onClick={() => handleCheckEyeClick()}>
                {isCheckEyeState === true && <SignUpStyledFaRegEyeIcon />}

                {isCheckEyeState === false && <SignUpStyledFaRegEyeSlashIcon />}
              </SignUpEyeDivStyled>
            </SignUpPasswordDivStyled>

            {checkPassword.trim() !== "" &&
            isCrossCheckPasswordState === false ? (
              <div
                style={{
                  marginTop: `10px`,
                  color: `red`,
                }}
              >
                {t("noSamePasswordText")}
              </div>
            ) : (
              <div></div>
            )}

            {/* {checkPassword.trim() === "" ? (
              isCheckPasswordState &&
              (isCrossCheckPasswordState === false ? (
                <div
                  style={{
                    marginTop: `10px`,
                    color: `red`,
                  }}
                >
                  {t("noSamePasswordText")}
                </div>
              ) : (
                <div></div>
              ))
            ) : (
              <div></div>
            )} */}

            {/* {checkPassword.trim() === "" ? (
              <div></div>
            ) : isCheckPasswordState ? (
              isCrossCheckPasswordState === false ? (
                <div
                  style={{
                    marginTop: `10px`,
                    color: `red`,
                  }}
                >
                  {t("noSamePasswordText")}
                </div>
              ) : (
                <div></div>
              )
            ) : (
              <div
                style={{
                  marginTop: `10px`,
                  color: `red`,
                }}
              >
                {t("passwordGuidingText")}
              </div>
            )} */}

            <SignUpSuccessButtonStyled
              disabled={!isCheckNumber || !isCrossCheckPasswordState}
              className="btn btn-lg btn-primary"
              onClick={() =>
                handleEmailChange(
                  isCurrentCertifiedNumber,
                  checkPassword,
                  isCurrentEmail,
                  isEvId
                )
              }
            >
              {t("changePasswordText")}
            </SignUpSuccessButtonStyled>
          </div>
        </SignUpMarginBottomDivStyled>
      </SignUpContainerDivStyled>
    </SignUpContentDivStyled>
  );
};
