import { error } from "jquery";
import api from "../../../../Repository/Interceptor";

export const postProfileImgUpload = async (file) => {
  try {
    const formData = new FormData();
    formData.append("profileimg", file);

    const resp = await api.post("/mypage/change/profileImgUpload", formData, {
      headers: {
        "API-Key": "COFFEE1234!!!",
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("프로필 사진 업로드 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
