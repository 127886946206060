import React from "react";
import { goHomeFoundText } from "../text/notFoundText";
import { useTranslation } from "react-i18next";

const GoHomeButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="center-button">
      <button className="orange-button" onClick={onClick}>
        {t("goHomeFoundText")}
      </button>
    </div>
  );
};

export default GoHomeButton;
