import api from "../../../../Repository/Interceptor";

export const getEmailCheck = async (email, join) => {
  try {
    const resp = await api.get("/user/email/find", {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
      params: {
        email: email,
        isJoin: join,
      },
    });
    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("이메일 중복확인 오류", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};

export const getSendAuthCode = async (email, join) => {
  try {
    const resp = await api.get("/user/email/sendauthcode", {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
      params: {
        email: email,
        isJoin: join,
      },
    });

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("인증코드 보내기 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};

export const getVerifyAuthCode = async (email, code) => {
  try {
    const resp = await api.get("/user/email/verifyauthcode", {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
      params: {
        email: email,
        authCode: code,
      },
    });
    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("인증번호 검증 통신 에러", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
