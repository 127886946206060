import api from "../../../Repository/Interceptor";

export const ChangePassword = async (code, pw, email, ev) => {
  try {
    const resp = await api.post(
      "/auth/change/password",
      {
        authCode: code,
        email: email,
        evId: ev,
        password: pw,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("에러다", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
