import { useState } from "react";
import wooriBank from "../img/Woori.png";
import kbBank from "../img/Kookmin.jpeg";
import nhBank from "../img/Nonghyup.png";
import sinhanBank from "../img/Shinhan.jpg";
import kebBank from "../img/Hana.jpeg";
import ibkBank from "../img/Industrial.jpeg";
import dagu from "../img/Daegu.png";
import busan from "../img/Busan.jpg";
import gwan from "../img/Gwangju.jpeg";
import gyeon from "../img/Kyungnam.jpeg";
import jeju from "../img/Jeju.png";
import su from "../img/Suhyup.png";
import sc from "../img/First.png";
import jeon from "../img/Jeonbuk.jpeg";
import kdb from "../img/Industry.png";
import post from "../img/post.png";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  closeText,
  searchBankName,
} from "../../../common/publicText/PublicText";

export const BankModal = ({ onSelectBank }) => {
  const { t } = useTranslation();

  const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 3개의 열을 균등하게 설정
    gap: 50px; // 그리드 간격 설정
    padding: 40px; // 여백 추가
  `;

  const GridItem = styled.div`
    position: relative;
    width: 100%; // 적절한 크기 지정
    height: 100px; // 적절한 높이 지정
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `;
  const ImageIcon = styled.img`
    width: 100%;
    height: auto;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  `;

  const BankBox = styled.div`
    height: 2.5rem;
    margin-left: 1rem;
    width: 5.8rem;
  `;

  const [showModal2, setShowModal2] = useState(false);

  const toggleModal = () => setShowModal2(!showModal2);

  const banks = [
    { src: wooriBank, alt: "우리은행" },
    { src: kbBank, alt: "국민은행" },
    { src: nhBank, alt: "농협은행" },
    { src: sinhanBank, alt: "신한은행" },
    { src: kebBank, alt: "하나은행" },
    { src: ibkBank, alt: "기업은행" },
    { src: dagu, alt: "대구은행" },
    { src: busan, alt: "부산은행" },
    { src: gyeon, alt: "경남은행" },
    { src: gwan, alt: "광주은행" },
    { src: jeju, alt: "제주은행" },
    { src: su, alt: "수협은행" },
    { src: sc, alt: "제일은행" },
    { src: jeon, alt: "전북은행" },
    { src: kdb, alt: "산업은행" },
    { src: post, alt: "우체국" },
  ];

  return (
    <div>
      <BankBox
        href="#"
        className="btn btn-lg btn-primary"
        onClick={toggleModal}
      >
        {t("searchBankName")}
      </BankBox>
      {showModal2 && (
        <div className="modal-popup modal-sm" id="layer-sample">
          <div className="popup-inner">
            <div className="popup-header">
              <h2 className="popup-title"> {t("searchBankName")}</h2>
            </div>
            <div className="popup-body">
              <GridContainer>
                {banks.map((bank) => (
                  <GridItem
                    key={bank.alt}
                    onClick={() => {
                      onSelectBank(bank.alt);
                      toggleModal();
                    }}
                  >
                    <ImageIcon src={bank.src} alt={bank.alt} />
                  </GridItem>
                ))}
              </GridContainer>
            </div>
            <div className="popup-footer">
              <div className="btn-group-center">
                <button
                  type="button"
                  className="btn btn-lg btn-gray"
                  data-modal="close"
                  onClick={toggleModal}
                >
                  {closeText}
                </button>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-close-popup"
              data-modal="close"
              onClick={toggleModal}
            >
              {closeText}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
