import axios from "axios";
import api, { axiosInstance } from "../../../../Repository/Interceptor";
import { setCookie } from "../../../../common/Cookies/user_cookies";
import { API_URL } from "../../../../Repository/URL";
import { cookieAge, tokenName } from "../../../../util/const";

export const emailLogin = async (id, password) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("password", password);
  formData.append("isReact", true);

  try {
    const response = await api.post("/auth/login", formData, {
      headers: {
        // "API-Key": "COFFEE1234!!!",
      },
    });

    const data = response.data;
    if (data && data.accessToken) {
      await setCookie(tokenName, data.accessToken, {
        path: "/",
        maxAge: cookieAge,
      });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("로그인 오류", error);
    return error.response.data;
  }
};

export default emailLogin;
