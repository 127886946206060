import api from "../../../Repository/Interceptor";

export const ConfirmationCheck = async (id) => {
  try {
    const resp = await api.post("/soa/check", null, {
      params: {
        soa_id: id,
      },
    });

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("정산확인 동의 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
