import React, { useEffect, useRef, useState } from "react";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setIntlTelNumber } from "../../../common/redux/action/IntlTelNumberAction";

const SignPhoneUpStyledInput = styled.input`
  text-align: left;
  width: 155%;
  appearance: none;
  text-align: center !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;

  &:disabled {
    background: #d1d5db; /* 원하는 색상으로 변경 */
  }
`;

const PhoneInput = () => {
  const inputRef = useRef(null);
  const [countryCode, setCountryCode] = useState("1"); // 선택된 국가 코드를 저장할 상태
  const [isTelNumber, setTelNumber] = useState("");
  const telNum = useSelector((state) => state.intlReducerState.tel);
  console.log("정보보자", telNum);
  const dispatch = useDispatch();
  useEffect(() => {
    const input = inputRef.current;
    const iti = intlTelInput(input, {
      initialCountry: "us", // 초기 국가를 미국으로 설정
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js", // 선택 사항, 포맷팅/유효성 검사를 위해 필요
      separateDialCode: true, // 전화 코드와 전화번호를 분리
      autoHideDialCode: false, // 국가 코드를 항상 표시
      dropdownContainer: document.body, // 드롭다운을 body에 추가하여 스타일 조정 가능
    });

    const handleCountryChange = () => {
      const countryCode = iti.getSelectedCountryData().dialCode;
      setCountryCode(countryCode);
    };

    const handleInputChange = () => {
      const phoneNumber = input.value;
      setTelNumber(phoneNumber);
    };

    input.addEventListener("countrychange", handleCountryChange);
    input.addEventListener("input", handleInputChange);

    // 컴포넌트 언마운트 시 또는 다시 생성될 때 iti를 정리하고 이벤트 리스너를 제거
    return () => {
      iti.destroy();
      input.removeEventListener("countrychange", handleCountryChange);
      input.removeEventListener("input", handleInputChange);
    };
  }, []);

  // useEffect(() => {
  //   console.log("국가코드", countryCode);
  //   console.log("이거맞지?", isTelNumber);
  //   console.log("이건?", telNum);
  // }, [countryCode, isTelNumber, telNum]);

  // 입력 지연 처리
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setIntlTelNumber("+" + countryCode + " " + isTelNumber));
    }, 200); // 2초 지연

    return () => clearTimeout(timer);
  }, [isTelNumber, dispatch, countryCode]);

  return (
    <>
      <SignPhoneUpStyledInput
        type="tel"
        ref={inputRef}
        value={isTelNumber}
        onChange={() => {}}
      />
    </>
  );
};

export default PhoneInput;
