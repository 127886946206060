import { ScrollTextTitle } from "../css/ScrillableDiv";

export const ConfirmationEn = () => {
  return (
    <div>
      <ScrollTextTitle className="mb-3">
        Confirmation of submission of application for settlement registration
      </ScrollTextTitle>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0">
        As a registered member and creator using the Hanzan service, I confirm
        that all information provided above is accurate. I acknowledge that
        future payments to the reported account or payment method are considered
        correct and appropriate, and I agree not to raise any objections or
        claim compensation for any losses incurred in this regard."
      </p>
    </div>
  );
};
