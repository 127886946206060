import { SET_INTL_TEL_NUMBER, TEL_RESET } from "../action/IntlTelNumberAction";

const initialState = {
  tel: "",
};

const intlReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTL_TEL_NUMBER:
      return {
        tel: action.payload,
      };
    case TEL_RESET:
      return initialState;
    default:
      return state;
  }
};

export default intlReducer;
