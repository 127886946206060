import styled from "styled-components";

const StyledTitleText = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 25px;
  font-weight: 700;
`;

const TitleText = ({ text }) => {
  return <StyledTitleText>{text}</StyledTitleText>;
};

export default TitleText;
