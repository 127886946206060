import { Loading01 } from "../../../../util/spinner";

export const MonthsTableLoading = () => {
  return (
    <div
      style={{
        height: `139px`,
        width: `100%`,
        justifyContent: `center`,
        display: `flex`,
        alignItems: `center`,
      }}
    >
      <Loading01 />
    </div>
  );
};
