export const SET_TODAY_ACTION = "SET_TODAY_ACTION";
export const SET_LAST_MONTH_ACTION = "SET_LAST_MONTH_ACTION";

export const setToday = (year, month, day) => ({
  type: SET_TODAY_ACTION,
  payload: { year, month, day },
});

export const setLastMonth = (year, month, day) => ({
  type: SET_LAST_MONTH_ACTION,
  payload: { year, month, day },
});
