import styled from "styled-components";
import React from "react";
export const OneDayBoxStyled = styled.div`
  border: 1px solid #d1d5db;
  padding: 8px 5px 8px 5px;
  border-radius: 8px 0 0 8px;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  color: #374151;
  background-color: ${(props) => (props.selected ? "#3b82f6" : "white")};
  color: ${(props) => (props.selected ? "white" : "#374151")};
  cursor: pointer;
`;

export const DayBoxStyled = styled.div`
  border: 1px solid #d1d5db;
  padding: 8px 5px 8px 5px;
  border-radius: 0px 0 0 0px;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  color: #374151;
  background-color: ${(props) => (props.selected ? "#3b82f6" : "white")};
  color: ${(props) => (props.selected ? "white" : "#374151")};
  cursor: pointer;
`;

export const BoxStyled = styled.div`
  border: 1px solid #d1d5db;
  padding: 8px 5px 8px 5px;
  border-radius: 0px 8px 8px 0px;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  color: #374151;
  background-color: ${(props) => (props.selected ? "#3b82f6" : "white")};
  color: ${(props) => (props.selected ? "white" : "#374151")};
  cursor: pointer;
`;
