import { STORE_RESET } from "../action/SidbarIndexAction";
import { SET_LAST_MONTH_ACTION, SET_TODAY_ACTION } from "../action/ToDayAction";

const initialState = {
  data: [],
};

const toDayReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TODAY_ACTION:
      return {
        ...state,
        data: [state.data, action.payload],
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default toDayReducer;

const lastInitialState = {
  data: [],
};

export const lastMonthReducer = (state = lastInitialState, action) => {
  switch (action.type) {
    case SET_LAST_MONTH_ACTION:
      return {
        ...state,
        data: [state.data, action.payload],
      };
    case STORE_RESET:
      return lastInitialState;
    default:
      return state;
  }
};
