export const SET_CALCULATE_DATA = "SET_CALCULATE_DATA";
export const RESET_CALCULATE_DATA = "RESET_CALCULATE_DATA";

export const setCalculate = (data) => ({
  type: SET_CALCULATE_DATA,
  payload: data,
});

export const resetCalculate = () => ({
  type: RESET_CALCULATE_DATA,
});
