import styled, { css } from "styled-components";
import React, { useRef, useEffect, useState } from "react";
const StyledImage = styled.img`
  margin: 10px;
  cursor: pointer;

  ${(props) =>
    props.sizetype === "401" &&
    css`
      width: 400px;
      height: 220px;
    `}
  ${(props) =>
    props.sizetype === "400" &&
    css`
      width: 400px;
      height: 160px;
    `}

    
  ${(props) =>
    props.sizetype === "170" &&
    css`
      width: 10rem;
      height: 5rem;
    `}
`;

const StyledDiv = styled.div`
  display: inline-block;
  margin: 5px;
`;
const StyledCheckbox = styled.input`
  appearance: none; // 기본 체크박스 스타일 제거
  background-color: #fff; // 배경색
  border: 2px solid #ccc; // 테두리 색
  padding: 10px; // 패딩
  border-radius: 50%; // 원형으로 만들기
  width: 20px; // 너비
  height: 20px; // 높이
  cursor: pointer; // 마우스 오버 시 커서 모양 변경
  position: relative; // 가상 요소의 위치 기준점 설정

  &:checked {
    &::before {
      content: ""; // 가상 요소의 내용 비우기
      position: absolute; // 부모 요소 대비 절대 위치
      top: 50%; // 상단에서 50% 위치
      left: 50%; // 좌측에서 50% 위치
      transform: translate(-50%, -50%) scale(0.8); // 위치와 크기 조정
      width: 100%; // 부모 요소와 같은 너비
      height: 100%; // 부모 요소와 같은 높이
      background-color: #007bff; // 배경색
      border-radius: 50%; // 원형
    }
  }
`;

const ButtonBox = ({ src, sizetype, isSelected, onClick }) => {
  return (
    <StyledDiv onClick={onClick}>
      <StyledImage src={src} sizetype={sizetype} />
      <div>
        <StyledCheckbox
          type="checkbox"
          checked={isSelected}
          readOnly
          // onClick={handleImageClick}
          // checked={isChecked}
        ></StyledCheckbox>
      </div>
    </StyledDiv>
  );
};

export default ButtonBox;
