import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { t } from "i18next";

export function MyDropzone({ setFiles }) {
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        {...getRootProps()}
        className="btn btn-lg btn-primary"
        style={{
          height: `2.8rem`,
          marginLeft: `1rem`,
          width: `1rem`,
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>{t("fileAttachmentText")}</div>
        ) : (
          <div>{t("fileAttachmentText")}</div>
        )}
      </div>
    </div>
  );
}

export default MyDropzone;
