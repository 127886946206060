import styled, { css } from "styled-components";

const StyledButtonContainer = styled.div`
  margin-left: 0rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0rem;
`;
const StyledButtonImg = styled.button`
  border: none;
  background: none;
`;

const ButtonContainer = ({ children }) => {
  return (
    <StyledButtonContainer>
      <StyledButtonImg>{children}</StyledButtonImg>
    </StyledButtonContainer>
  );
};

export default ButtonContainer;
