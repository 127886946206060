import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { API_URL } from "../../../Repository/URL";
import { getProfileData } from "../../Modifying/Renewal/repo/Profile";
import { setProfileData } from "../../../common/redux/action/ProfileAction";
import { setCookie } from "../../../common/Cookies/user_cookies";
import { cookieAge, tokenName } from "../../../util/const";
import { setLoginType } from "../../../common/redux/action/LoginTypeAction";
import { getReUrlData } from "../../CreateButton/Renewal/repo/UrlData";
import { setButtonUrl } from "../../../common/redux/action/UrlButtonAction";
import {
  setLastMonth,
  setToday,
} from "../../../common/redux/action/ToDayAction";
import {
  getCurrentDate,
  getLastMonthDate,
} from "../../../util/DateCalculation";
import { handleProfileData } from "./LoginCommon";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const KakaoRedurectURL = () => {
  const { t, i18n } = useTranslation();
  const languageData = useSelector((state) => state.languageType.type);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAuthData = async () => {
      setIsLoading(true);
      const currentDay = getCurrentDate();
      const lastDay = getLastMonthDate();
      try {
        const code = new URL(window.location.href).searchParams.get("code");
        const state = new URL(window.location.href).searchParams.get("state");
        const resp = await axios.get(
          `${API_URL}/auth/kakao/login?code=${code}&state=${state}`
        );
        const data = resp.data;
        if (resp.status == 200) {
          setCookie(tokenName, data.access_token, {
            path: "/",
            maxAge: cookieAge,
          });
          const buttonURL = await getReUrlData();
          dispatch(setButtonUrl(buttonURL.data));
          dispatch(setLastMonth(lastDay.year, lastDay.month, lastDay.day));
          dispatch(setToday(currentDay.year, currentDay.month, currentDay.day));
          dispatch(setLoginType("Kakao"));
          await handleProfileData(dispatch, navigate);
        } else {
          Swal.fire({
            title: `${t("whiteReClickText")}`,
            icon: `error`,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
          return;
        }
      } catch (error) {
        Swal.fire({
          title: `${t("whiteReClickText")}`,
          icon: `error`,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
        return;
      }
    };
    fetchAuthData();
  }, []);
  return <div></div>;
};
