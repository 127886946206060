import { postDownloadFile } from "../home/Calculate/repo/DownloadFile";

export const handleDown = async (file) => {
  const fileId = file.orId;
  const filePath = file.path;

  const fileName = file.name;
  const fileType = file.type;

  const resp = await postDownloadFile(fileId, filePath, 1);

  if (resp.msg === "Success") {
    const blob = new Blob([resp.data], { type: { fileType } });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName.split("/").pop();
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    return;
  }

  if (resp.msg === "Fail") {
    return;
  }
  return;
};
