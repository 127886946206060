import api from "../../../../Repository/Interceptor";

export const postContentChange = async (content) => {
  try {
    const resp = await api.post(
      "/mypage/change/content",
      {
        content: content,
      },
      {
        headers: {
          "API-Key": "COFFEE1234!!!",
        },
      }
    );

    return resp;
  } catch (error) {
    console.log("프로필 수정 실패", error);
    return "Fail";
  }
};
