export function validatePassword(password) {
    // 길이 검증
    if (password.length < 8 || password.length > 16) {
      return false;
    }
  
    // 조건 카운트
    let conditionCount = 0;
  
    // 영문 대문자 포함 검사
    if (/[A-Z]/.test(password)) {
      conditionCount++;
    }
  
    // 영문 소문자 포함 검사
    if (/[a-z]/.test(password)) {
      conditionCount++;
    }
  
    // 숫자 포함 검사
    if (/\d/.test(password)) {
      conditionCount++;
    }
  
    // 특수문자 포함 검사
    if (/[\W_]/.test(password)) {
      conditionCount++;
    }
  
    // 3가지 이상 조건 충족 확인
    return conditionCount >= 3;
  }