import { useTranslation } from "react-i18next";
import { ScrollTextTitle } from "../css/ScrillableDiv";

export const ConsentToUseEn = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ScrollTextTitle className="mb-3">
        Consent to Collection and Use of Personal Information for Settlement
        Registration
      </ScrollTextTitle>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0">
         To ensure fair settlement of earnings derived from the use of the
        Sponsored Cup service to members or affiliates, XRAI Co., Ltd., the
        operator of the Sponsored Cup service, collects additional personal and
        account information from businesses and individuals as follows
      </p>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ① Company Information Collected (for corporate entities): Name of
        corporate representative, Resident registration number of corporate
        representative, Business registration certificate
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ② Personal Information Collected (for individuals): Resident
        registration number included in a copy of ID card, Alien registration
        number and passport number if the individual is a foreigner
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ③ Settlement Manager Personal Information (common): Settlement
        manager's name, Phone number, Email address
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ④ Deposit Account Information (common): Bank name, Account number, Name
        of depositor, Copy of bankbook in person’s or corporation’s name
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ⑤ Purpose of Collection and Use: Payment processing and tax reporting,
        Confirmation of payment transactions, Establishing communication
        channels for smooth business operations
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ⑥ Retention and Use Period: Information will be retained until the
        completion of payment transactions or until the information subject
        requests deletion. However, if specific information retention is
        required by relevant laws and regulations, those laws and regulations
        will apply.
      </p>
    </div>
  );
};
