export const yearText = "년";
export const monthText = "월";
export const backWard = "뒤로가기";
export const copyText = "복사";

export const dashBoardText = "대시보드";
export const wonText = "원";
export const checkText = "확인";
export const closeText = "닫기";
export const nameText = "이름";
export const searchText = "검색";
export const contentText = "내용";
export const resetText = "초기화";
export const inquiryText = "조회";
export const sponsorShipStatusText = "후원상태";
export const searchBankName = "은행검색";
export const allSuccessText = "전체동의";
export const platFromConditionsText = "후원한잔 이용약관";
export const platFromNoticeConsentText =
  "후원한잔 서비스 사용에 관한 고지 및 동의";
export const personalInformationText =
  "정산등록에 따른 개인정보 수집 및 이용 동의";

export const cancellationText = "취소";
export const residentNumberText = "주민등록번호";
export const noEmailStateText = "올바른 이메일 형식을 입력해주세요.";
export const inDetailText = "자세히";
export const settlementText = "정산등록";
export const awaitRePlayText = "잠시 후 다시 시도해주세요.";
export const downLoadText = "다운로드";
export const eyeCheckText = "확인하기";
export const eyeCheckSuccess = "확인완료";
export const errorUnknownText = "잠시 후 다시 시도해주세요.";
export const acoffeeText = "후원한잔";
export const loginText = "로그인";
export const singUpText = "회원가입";
export const idText = "아이디";
export const idInputPleaseText = "아이디를 입력해주세요.";
export const passwordText = "비밀번호";
export const passwordInputPleaseText = "비밀번호를 입력해주세요.";
export const modifyingText = "수정하기";
export const modifyingWordText = "수정";
export const retransmissionText = "재전송";
export const titleNickName = "닉네임";
export const persnolText = "개인정보 처리방침";
