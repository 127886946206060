import api from "../../../Repository/Interceptor";

export const IndividualSave = async (
  name,
  code,
  phone,
  address,
  email,
  channel,
  bankName,
  bankCode,
  files,
  paypalName,
  paypalEmail,
  paypalPhone,
  idCardType
) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("code", code);
  formData.append("phone", phone);
  formData.append("address", address);
  formData.append("email", email);
  formData.append("channel", channel);
  formData.append("bankName", bankName);
  formData.append("bankCode", bankCode);
  formData.append("paypalName", paypalName);
  formData.append("paypalEmail", paypalEmail);
  formData.append("paypalPhone", paypalPhone);
  formData.append("idCardType", idCardType);
  files.forEach((file, index) => {
    formData.append(`files`, file);
  });
  try {
    const response = await api.post("/register/add/personal", formData, {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
    });

    return {
      data: response,
      msg: "Success",
    };
  } catch (error) {
    console.log("개인 정보 저장 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};

export const BusinessSave = async (
  name,
  code,
  phone,
  address,
  channel,
  bankName,
  bankCode,
  files,
  bankOwnerName,
  dStatus,
  type,
  email,
  copration,
  companyName,
  paypalName,
  paypalEmail,
  paypalPhone
) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("code", code);
  formData.append("phone", phone);
  formData.append("address", address);
  formData.append("channel", channel);
  formData.append("bankName", bankName);
  formData.append("bankCode", bankCode);
  files.forEach((file, index) => {
    formData.append(`files`, file);
  });
  formData.append("bankOwnerName", bankOwnerName);
  formData.append("status", dStatus);
  formData.append("type", type);
  formData.append("email", email);
  formData.append("copration", copration);
  formData.append("businessName", companyName);
  formData.append("paypalName", paypalName);
  formData.append("paypalEmail", paypalEmail);
  formData.append("paypalPhone", paypalPhone);

  try {
    const response = await api.post("/register/add/business", formData, {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
    });

    return {
      data: response,
      msg: "Success",
    };
  } catch (error) {
    console.log("개인 정보 저장 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
