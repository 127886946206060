import api from "../../../Repository/Interceptor";

export const getCalculateData = async () => {
  try {
    const resp = await api.get("/register", {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
    });

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("정산정보 가져오기 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
