import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { resetAuthError } from "../redux/action/AuthErrorAction";
import { resetStore } from "../redux/action/SidbarIndexAction";
import { Cookies } from "react-cookie";
import { getCookie, removeCookie } from "../Cookies/user_cookies";
import { tokenName } from "../../util/const";

export const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authError = useSelector((state) => state.authState.error);
  useEffect(() => {
    if (authError === 401 || authError === 403) {
      dispatch(resetAuthError());
      dispatch(resetStore());
      removeCookie(tokenName);
      navigate("/renewalLogin");
    }
  }, [authError, dispatch, navigate]);

  return children;
};
