import api from "../../../../Repository/Interceptor";

export const getDashBoard = async (
  page,
  searchRange,
  searchType,
  textSearch,
  start,
  end
) => {
  try {
    const resp = await api.get("/dashboard", {
      headers: {
        "API-Key": "COFFEE1234!!!",
      },
      params: {
        page: page,
        search_range: null,
        search_type: searchType,
        search: textSearch,
        start_date: start,

        end_date: end,
      },
    });
    return resp;
  } catch (error) {
    console.log("데시보드 정보 호출 에러", error);

    return {
      msg: "Fail",
      er: error,
    };
  }
};
