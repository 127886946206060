import React from "react";
import { Link } from "react-router-dom";
import "../css/povisionsDon.css";
import Footer from "../../Footer/screen/Footer";

const PersonalInfoCrEn = () => {
  return (
    <div>
      <div className="provisionsText">
        <p className="mb-3 Pr-Title">Privacy Notice</p>
        <p className="mb-3">
          XRAI Co., Ltd. (hereinafter referred to as “the Company”), which
          operates Hanzan, handles the personal information of its members with
          utmost care.
        </p>
        <p className="mb-3">
          The company complies with the personal information protection
          regulations of relevant laws and regulations that it must adhere to,
          including the Act on Promotion of Information and Communications
          Network Utilization and Information Protection, etc. The company
          endeavors to protect your personal information through a privacy
          notice in accordance with applicable laws and regulations. Through
          this privacy notice and personal information protection policy, we
          inform you of the purpose and methods for which users' personal
          information is utilized, and how we manage and protect personal
          information
        </p>
        <p className="mb-0 provisSubText">
          1. Personal Information Items Collected and Collection Methods
        </p>
        <p className="mb-0">
          When registering for membership or in the process of using the
          service, we collect the following minimum personal information to
          provide the service through the homepage, etc.
        </p>
        <p className="mb-0"> 1) Required Items (Creator)</p>
        <p className="mb-0">
                    ① ID and password required for home page access
        </p>
        <p className="mb-0">
                    ② Individual’s name, phone number, address, resident
          registration number, copy of ID card, bank name, account number,
          account holder, copy of bankbook, legal representative information
          (limited to minors under 19 years of age)
        </p>
        <p className="mb-0">
                    ③ Business name/corporation name, phone number, address,
          business registration number, industry and business type, name of
          representative, name of person in charge, contact information of
          person in charge, copy of business registration certificate, bank
          name, account number, account holder, copy of bankbook
        </p>
        <p className="mb-0"> 2) Optional Items (Creator)</p>
        <p className="mb-0">
                    ① Provided voluntarily for marketing, analysis, and
          promotions
        </p>
        <p className="mb-0"> 3) When Making Donation Payment (Donator)</p>
        <p className="mb-0">
                    ① Credit card payment: card company name, payment
          authorization number, etc.
        </p>
        <p className="mb-0">
                    ② Toss payment: Payment approval number, part of mobile
          phone number, etc.
        </p>{" "}
        <p className="mb-0">
                    ③ Kakao Pay payment: Payment authorization number (general),
          card company name (when paying by card), etc.           ④ Naver Pay
          payment: Payment approval number, name of payer (partial), payment
          method          ⑤ Paypal payment: payment authorization number, payer
          name (partial), payment method, card details, etc.
        </p>
        <p className="mb-0">
           4) During Identity and Legal Representative Verification Process
          (Common)
        </p>
        <p className="mb-0">
          Name, date of birth, gender, mobile phone information, mobile carrier
          information, unique ID provided by the agency, etc.
        </p>
        <p className="mb-0">
           5) The following information may be automatically generated and
          collected based on the member's service usage process:
        </p>
        <p className="mb-0">
          IP address, cookies, visit date and time, service usage history,
          sanctions for incorrect service use, terminal model name, terminal OS
          information, terminal unique number, terminal firmware version,
          telecommunication company information, network-based location
          information, terminal unique token value
        </p>
        <p className="mb-0">
           6) The company collects the above personal information through the
          following methods
        </p>
        <p className="mb-0">
                    ① Collection through user input: membership registration,
          email, event application, mobile app, phone, customer center, written
          forms
        </p>
        <p className="mb-3">
                    ② Collection through user consent: provided by
          cooperation/affiliate/agency, collected through information gathering
          tools
        </p>
        <p className="mb-3">
          Certain personal information, classified as unique identification
          information under Article 24, Paragraph 1 of the Personal Information
          Protection Act and Article 19 of its Enforcement Decree (such as
          resident registration number, passport number, and driver's license
          number), is encrypted and securely managed. It will not be used or
          provided for purposes other than those notified or prescribed by law.
        </p>
        <p className="mb-0 provisSubText">
          2. Purpose of Using Collected Personal Information{" "}
        </p>
        <p className="mb-0"> 1) Membership Registration and Management </p>
        <p className="mb-0">
           Confirmation of intention to become a member, identification and
          authentication of individuals for the provision of membership
          services, maintenance and management of membership, prevention of
          illegal service use, sanctions against members for improper service
          use, confirmation of legal representative’s consent, etc.
        </p>
        <p className="mb-0"> 2) Provision of Goods or Services</p>
        <p className="mb-0">
          Fulfillment of service contract, settlement of fees, provision of
          content according to service provision, purchase and payment of fees,
          delivery of goods or issuance of invoices, identity verification, fee
          collection, income reporting to the National Tax Service or issuance
          of tax invoices, court confirmation of agent’s consent, etc.
        </p>
        <p className="mb-0"> 3) Grievance Handling</p>
        <p className="mb-3">
          Ensuring smooth communication channels, such as verifying the identity
          of complainants, confirming grievances, contacting for factual
          investigations, notifying or reporting processing results, preserving
          records for dispute mediation, delivering notices, confirming the
          intentions of individuals, and processing complaints.
        </p>
        <p className="mb-0 provisSubText">
          3. Personal Information Processing and Retention Period
        </p>
        <p className="mb-0">
           The company processes and retains personal information collected from
          members for the duration of their membership, and promptly destroys
          personal information once the purpose of processing personal
          information is achieved. However, if prior consent has been obtained
          from the member or there is a need to retain it in accordance with
          relevant laws and regulations, the information will be retained for a
          certain period of time. The retention periods according to current
          laws are as follows:
        </p>
        <p className="mb-0">
           1) Act on Consumer Protection in Electronic Commerce, Etc.
        </p>
        <p className="mb-0">
                    ① Records of contract or subscription withdrawal (5 years)
        </p>
        <p className="mb-0">
                    ② Records of payment and supply of goods, etc. (5 years)
        </p>
        <p className="mb-0">
                    ③Records of consumer complaints or dispute resolution (3
          years)
        </p>
        <p className="mb-0">
                    ④ Records of labeling/advertising (6 months){" "}
        </p>
        <p className="mb-0"> 2) Electronic Financial Transactions Act</p>
        <p className="mb-0">
                    ① Records of electronic financial transactions (5 years)
        </p>
        <p className="mb-0"> 3) Communications Secret Protection Act</p>
        <p className="mb-0">          ① Login records (3 months)</p>
        <p className="mb-0"> 4) With Member Consent</p>
        <p className="mb-0">          ① Until the period of member consent</p>
        <p className="mb-0">
           5) Regardless of Member Consent or Relevant Laws and Regulations,
          Personal Information May Be Retained Until the Following Reasons
          Expire
        </p>{" "}
        <p className="mb-0">
                    ① If an investigation or inquiry is ongoing due to a
          violation of relevant laws and regulations, until the investigation or
          inquiry is completed.
        </p>
        <p className="mb-3">
                    ② If there are outstanding claims or debts resulting from
          the use of the company’s services, until such claims or debts are
          settled.
        </p>
        <p className="mb-0 provisSubText">
          4. Provision of Personal Information to Third Parties
        </p>
        <p className="mb-0">
          The company processes members' personal information strictly within
          the scope specified in the processing purposes. However, personal
          information may be provided to third parties in the following cases
        </p>
        <p className="mb-0">
           1) When necessary for processing settlements according to the
          member’s use of the service.
        </p>
        <p className="mb-0"> 2) With separate consent from the member.</p>
        <p className="mb-0">
           3) When there are special provisions in laws, etc.
        </p>
        <p className="mb-0">
           4) When there is an urgent risk to a person's life or body and
          obtaining consent from the data subject is not feasible.
        </p>
        <p className="mb-3">
           5) When necessary for purposes such as statistical compilation and
          academic research, personal information is provided in a form that
          does not identify a specific individual.
        </p>
        <p className="mb-3">
          When providing or sharing a member's personal information with the
          aforementioned third parties, prior consent from the member is
          obtained. The provided personal information includes details such as
          the recipient of the personal information, purpose of use, types of
          personal information provided, retention and usage period of the
          personal information, and information regarding the member's right to
          refuse consent and the potential consequences of refusal.
        </p>
        <p className="mb-0 provisSubText">
          5. Entrustment of Personal Information Processing
        </p>
        <p className="mb-3">
          In order to facilitate business operations, the company entrusts the
          processing of personal information to professional companies as
          follows, for purposes agreed upon by members or for purposes such as
          contract conclusion, maintenance, performance, and management
        </p>
        <table className="mb-3">
          <tr>
            <th>Trustee</th>
            <th>Details of entrusted work </th>
          </tr>
          <tr>
            <td>Payletter Co., Ltd.</td>
            <td>
              {" "}
              Credit card payment approval and purchase agency, account
              transfer, simple payment (Naver Pay/Kakao Pay/Toss Pay), identity
              verification
            </td>
          </tr>
          <tr>
            <td>KakaoPay Co., Ltd.</td>
            <td>
              Kakao Pay payment agency and cash receipt application entrustment,
              identity verification
            </td>
          </tr>
          <tr>
            <td>Viva Republica Co., Ltd.</td>
            <td>
              Toss payment agency and cash receipt application entrustment,
              identity verification
            </td>
          </tr>
          <tr>
            <td>PayPal</td>
            <td>PayPal payment agency and identity verification</td>
          </tr>
        </table>
        <p className="mb-3">
          When entrusting personal information, the company ensures that the
          entrusted companies securely process personal information. Any changes
          in the details of the entrusted work or entrusted companies are
          promptly corrected and disclosed.
        </p>
        <p className="mb-0 provisSubText">
          6. Procedures and Methods for Destroying Personal Information
        </p>
        <p className="mb-0">
          The company promptly destroys personal information when it is no
          longer needed, such as when the retention period has expired or the
          purpose of processing has been achieved. However, in cases where
          personal information must be retained despite the expiration of the
          agreed retention period or achievement of the processing purpose, due
          to other legal obligations or reasons, the personal information may be
          transferred to a separate database or physically stored in different
          storage locations.
        </p>
        <p className="mb-0">
           1) When the company needs to retain personal information due to
          ongoing civil or criminal liabilities or the statute of limitations,
          or as evidence for a dispute.
        </p>
        <p className="mb-0">
           2) Cases where retention is required under laws such as Article 33 of
          the Commercial Act.
        </p>
        <p className="mb-3"> 3) Other similar justifiable reasons.</p>
        <p className="mb-3">
          Destruction Method   Personal information recorded and stored in
          electronic file format is destroyed so that the records cannot be
          reproduced. Personal information recorded and stored in paper
          documents is destroyed through shredding or incineration.
        </p>
        <p className="mb-0 provisSubText">
          7. Rights and Exercise Methods of Members and Legal Representatives
        </p>
        <p className="mb-0">
          Members and their legal representatives (for members under 14 years of
          age) may exercise the following rights related to personal information
          protection against the company at any time
        </p>
        <p className="mb-0"> 1) Request to view personal information.</p>
        <p className="mb-0">
           2) Request for correction in case of errors, etc.
        </p>
        <p className="mb-0">
           3) Request for deletion (However, deletion may not be possible if the
          personal information is specified as a collection object under other
          laws and regulations).
        </p>
        <p className="mb-3">
           4) Request to withdraw or stop processing personal information (Note
          that discontinuing some or all services may be difficult).
        </p>
        <p className="mb-3">
          Registered personal information can be viewed and corrected after
          verifying your identity (or verifying your legal representative)
          according to procedures established by the company. To cancel your
          membership, please contact the company's customer service center.
        </p>
        <p className="mb-0 provisSubText">
          8. Matters related to the Installation, Operation, and Refusal of
          Automatic Personal Information Collection Devices
        </p>
        <p className="mb-0">
          The company installs and operates devices or technologies that
          automatically collect personal information, such as cookies, which
          store and retrieve member information periodically. A cookie is a
          small piece of text sent by the server operating the company's website
          to the member's web browser and stored on the member's computer.
        </p>
        <p className="mb-0">
            The company uses cookies, etc., for the following purposes
        </p>
        <p className="mb-0">
           1) Authentication of products, content, and payments provided by the
          company.
        </p>
        <p className="mb-0">
           2) Information on usage periods when using paid services.
        </p>
        <p className="mb-0">
           3) Monitoring activity details such as event and survey
          participation.
        </p>
        <p className="mb-0">
           4) Providing tailored information based on user interests.
        </p>
        <p className="mb-3">
           5) Utilization in service enhancement through analysis of user usage
          patterns.
        </p>
        <p className="mb-0">
          However, you have the option to accept or reject the installation and
          use of cookies. By adjusting settings in your web browser, you can
          either allow all cookies according to your browser's capabilities, be
          prompted each time a cookie is saved, or refuse to store all cookies.
        </p>
        <p className="mb-3">
          Note that refusing to accept cookies may prevent you from using
          services that require login.
        </p>
        <p className="mb-0 provisSubText">
          9. Technical and Administrative Measures for Personal Information
          Protection
        </p>
        <p className="mb-0">
          When handling members' personal information, the company implements
          the following technical, administrative, and physical safeguards to
          prevent loss, theft, leakage, alteration, or damage of personal
          information
        </p>
        <p className="mb-0"> 1) Access control and authorization management </p>
        <p className="mb-0">
          Access to personal information is limited to the minimum necessary for
          performing duties. Each responsible person is provided with a unique
          account with differentiated access rights. Access rights are adjusted
          or revoked when responsibilities change. Secure access methods such as
          VPNs and secure connections are used when accessing personal
          information remotely. Systems are in place to prevent unauthorized
          access and actions on personal PCs by external parties.
        </p>
        <p className="mb-0">
           2) Encryption of personal information:       All personal information
          transmitted/received through information and communication networks or
          auxiliary storage media is encrypted. Personal information is
          preferably not stored on work PCs; if unavoidable, it is securely
          encrypted and stored. Unique identification numbers (such as resident
          registration numbers), passwords, and sensitive personal information
          are encrypted using secure algorithms, in compliance with the Personal
          Information Protection Act.
        </p>
        <p className="mb-3">
           3) Storage and prevention of forgery, alteration, and physical
          measures:       Records of personal information processing by handlers
          are kept on the system for at least three months to prevent forgery,
          falsification, theft, loss, or deletion. Physical storage locations
          such as servers are designated and operated as restricted areas.
          Documents, electronic files, auxiliary storage media, etc., containing
          personal information are safeguarded with physical security measures
          such as locks.
        </p>
        <p className="mb-0 provisSubText">10. Personal Information Manager</p>
        <p className="mb-0">
          To protect members' personal information and handle inquiries or
          complaints related to personal information, the company appoints a
          personal information protection officer as follows. For inquiries
          regarding personal information, such as requests to view or correct
          personal information, please contact the designated person below:
        </p>
        <p className="mb-3">
           1) Personal Information Protection Officer<br></br>Name: Seungseok
          Lee
          <br></br> Position: Director
          <br></br> Company: XRAI Co., Ltd.
        </p>
        <p className="mb-3">
           2) Contact Information       Email: dltmdtjr9683@xrai-api.com
        </p>
        <p className="mb-0 provisSubText">
          11. Scope of Application of This Personal Information Processing
          Policy
        </p>
        <p className="mb-0">
          This personal information processing policy applies to Sponsored Cups,
          one of the company's services, and all related services. Separate
          personal information processing policies may apply to services
          provided under other brands. Additionally, if personal information is
          collected from another company's website/app linked to the Sponsored
          Cups service, the personal information processing policy of that
          website/app will apply.
        </p>
        <p className="mb-0 provisSubText">12. Changes to the Privacy Policy</p>
        <p className="mb-3">
           If there are any additions, deletions, or changes to the contents of
          the Privacy Policy, we will notify you at least 7 days in advance, and
          if there is a significant change in the rights of members, such as
          changes in the items of personal information collected or the purpose
          of use, we will notify you at least 30 days in advance.
        </p>
        <p className="mb-0 provisSubText">13. Provisions by country</p>
        <p className="mb-3">
           These privacy notices may be translated into one or more other
          languages. This Privacy Notice will take precedence over the English
          version
        </p>
        <p className="mb-0">&lt;Addendum&gt;</p>
        <p className="mb-0">
          This Privacy Policy is effective as of December 8, 2023.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PersonalInfoCrEn;
