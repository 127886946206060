import { useTranslation } from "react-i18next";
import { ScrollTextTitle } from "../css/ScrillableDiv";

export const ConsentToUse = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ScrollTextTitle className="mb-3">
        정산등록에 따른 개인정보 수집 및 이용 동의
      </ScrollTextTitle>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0">
         후원한잔 서비스 이용을 통해 얻은 수익금을 회원 혹은 제휴사에게 공정하게
        정산하기 위해 후원한잔 서비스의 운영주체인 ㈜엑스알에이아이는 사업자,
        개인을 대상으로 아래와 같이 개인정보 및 계좌정보를 추가로 수집합니다.;
      </p>
      <div style={{ marginTop: `10px` }}></div>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ① 수집 회사 정보 (법인사업자의 경우): 법인 대표자 이름, 법인 대표자
        주민등록번호, 사업자등록증;
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ② 수집 개인정보 (개인의 경우): 신분증 사본에 포함된 주민번호, 외국인일
        경우 외국인 번호 및 여권번호;
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ③ 정산담당자 개인 정보 (공통): 정산담당자 이름, 전화번호, 이메일 주소;
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ④ 입금계좌정보 (공통): 은행 이름, 계좌번호, 예금주 이름, 본인명의 혹은
        법인명의 통장사본;
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ⑤ 수집 및 이용목적: 대금지급과 세무신고 및 이를 위한 확인, 원활한
        업무처리를 위한 의사소통 경로 확보;
      </p>
      <p className="mb-0" style={{ marginLeft: `20px` }}>
         ⑥ 보유 및 이용기간: 대금지급 거래의 유지 시점 또는 정보주체의 삭제
        요청시까지 (단, 관계법령이 특정 정보의 보존을 정하고 있는 경우 해당
        법령에 따릅니다.);
      </p>
    </div>
  );
};
