import React, { useEffect, useState } from "react";
import { ReSidBar } from "../../ReSidBar/ReSidBar";
import ReFooter from "../../../common/Footer/screen/ReFooter";
import { IndividualPage } from "../components/Individual";
import { CorporateBusinessPage } from "../components/CorporateBusiness";
import { PrivateBusinessPage } from "../components/PrivateBusiness";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  BusinessButton,
  QuickLinkButtonSize,
} from "../css/CorporateBusinessStyled";

export const CalculatePage = () => {
  const { t } = useTranslation();
  const calculateData = useSelector((state) => state.calculateState);
  const languageData = useSelector((state) => state.languageType.type);

  const [selectedPage, setSelectedPage] = useState("individual");
  const [inDividualDisabled, setIndividualDisabled] = useState(false);
  const [inPrivateBusinessDisabled, setPrivateBusinessDisabled] =
    useState(false);
  const [inCorporateBusinessDisabled, setCorporateBusinessDisabled] =
    useState(false);

  const handleIndividualClick = () => {
    setSelectedPage("individual");
  };

  const handlePrivateBusinessClick = () => {
    setSelectedPage("privateBusiness");
  };

  const handleCorporateBusinessClick = () => {
    setSelectedPage("corporateBusiness");
  };

  useEffect(() => {
    if (calculateData?.data === "nodata") {
      setIndividualDisabled(false);
      setPrivateBusinessDisabled(false);
      setCorporateBusinessDisabled(false);
      setSelectedPage("individual");
    }

    if (calculateData?.data === null) {
      setIndividualDisabled(false);
      setPrivateBusinessDisabled(false);
      setCorporateBusinessDisabled(false);
      setSelectedPage("individual");
    }

    if (calculateData?.data?.type === 1) {
      setIndividualDisabled(false);
      setPrivateBusinessDisabled(true);
      setCorporateBusinessDisabled(true);
      setSelectedPage("individual");
    }

    if (calculateData?.data?.type === 2) {
      setIndividualDisabled(true);
      setPrivateBusinessDisabled(false);
      setCorporateBusinessDisabled(true);
      setSelectedPage("privateBusiness");
    }

    if (calculateData?.data?.type === 3) {
      setIndividualDisabled(true);
      setPrivateBusinessDisabled(true);
      setCorporateBusinessDisabled(false);
      setSelectedPage("corporateBusiness");
    }
  }, [calculateData]);

  const getLiStyle = (pageName, disabled) => {
    if (disabled) {
      return {
        backgroundColor: "#d3d3d3",
        color: "#a9a9a9",
        cursor: "not-allowed",
      };
    }
    return {
      backgroundColor: selectedPage === pageName ? "#2563eb" : "#fff",
      color: selectedPage === pageName ? "#fff" : undefined,

      cursor: "pointer",
    };
  };

  return (
    <div className="layout-type-main">
      <div className="wrapper nav-mode-1">
        <div id="container">
          <ReSidBar />
          <div className="content-body">
            <div id="content">
              <section className="section section-guide">
                <ul className="quick-link">
                  <QuickLinkButtonSize
                    onClick={handleIndividualClick}
                    disabled={inDividualDisabled}
                  >
                    <div
                      className="card"
                      style={getLiStyle("individual", inDividualDisabled)}
                    >
                      <div
                        style={{ display: `flex`, justifyContent: `center` }}
                      >
                        <strong style={{ fontSize: `20px` }}>
                          {t("individualText")}
                        </strong>
                      </div>
                    </div>
                  </QuickLinkButtonSize>
                  <button
                    style={{ marginTop: `3%`, width: `100%` }}
                    onClick={handlePrivateBusinessClick}
                    disabled={inPrivateBusinessDisabled}
                  >
                    <div
                      className="card"
                      style={getLiStyle(
                        "privateBusiness",
                        inPrivateBusinessDisabled
                      )}
                    >
                      <div
                        style={{ display: `flex`, justifyContent: `center` }}
                      >
                        <strong style={{ fontSize: `20px` }}>
                          {t("privateBusinessText")}
                        </strong>
                      </div>
                    </div>
                  </button>
                  <BusinessButton
                    onClick={handleCorporateBusinessClick}
                    disabled={inCorporateBusinessDisabled}
                  >
                    <div
                      className="card"
                      style={getLiStyle(
                        "corporateBusiness",
                        inCorporateBusinessDisabled
                      )}
                    >
                      <div
                        style={{ display: `flex`, justifyContent: `center` }}
                      >
                        <strong style={{ fontSize: `20px` }}>
                          {t("corporateBusinessText")}
                        </strong>
                      </div>
                    </div>
                  </BusinessButton>
                </ul>
              </section>

              <div
                className="card"
                style={{ marginLeft: `2%`, marginRight: `2%` }}
              >
                <h3
                  className="content-title"
                  style={{ marginRight: `1rem`, marginBottom: `1rem` }}
                >
                  {t("settlementText")}
                </h3>

                {selectedPage === "individual" && <IndividualPage />}
                {selectedPage === "privateBusiness" && <PrivateBusinessPage />}
                {selectedPage === "corporateBusiness" && (
                  <CorporateBusinessPage />
                )}
              </div>
              <br></br>
              <div style={{ marginLeft: `2%`, marginRight: `2%` }}>
                {languageData === "en" ? (
                  <ReFooter
                    termsLink="/termosfuseCrEn"
                    personalInfoLink="/personalInfoEn"
                  />
                ) : (
                  <ReFooter
                    termsLink="/termosfuseCr"
                    personalInfoLink="/personalInfo"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
