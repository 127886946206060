import { SET_LOGIN_TYPE } from "../action/LoginTypeAction";
import { STORE_RESET } from "../action/SidbarIndexAction";

const initialState = {
  type: "email",
};

const loginTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_TYPE:
      return {
        type: action.payload,
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default loginTypeReducer;
