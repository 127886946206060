import { Link } from "react-router-dom";
import styled from "styled-components";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
export const SignUpStyledInput = styled.input`
  text-align: left;
  width: 100%;
  max-width: 100%;
  appearance: none;
  text-align: center !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
`;

export const SignUpStyledSelect = styled.select`
  text-align: left;
  width: 100%;
  max-width: 100%;
  appearance: none;
  text-align: center !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  /* padding: 10px 16px; */
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
`;

export const SignPhoneUpStyledInput = styled.input`
  text-align: left;
  width: 100%;
  max-width: 100%;
  appearance: none;
  text-align: center !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;

  &:disabled {
    background: #d1d5db; /* 원하는 색상으로 변경 */
  }
`;

export const SignUpCheckNumberStyledInput = styled.input`
  text-align: left;
  width: 100%;
  max-width: 100%;
  appearance: none;
  text-align: center !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
  &:disabled {
    background: #d1d5db; /* 원하는 색상으로 변경 */
  }
`;

export const SignUpEmailStyledInput = styled.input`
  text-align: left;
  width: 100%;
  max-width: 100%;
  appearance: none;
  text-align: center !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;

  &:disabled {
    background: #d1d5db; /* 원하는 색상으로 변경 */
  }
`;

export const SignUpStyledHorizontalLine = styled.div`
  height: 1px;
  background-color: #d1d5db;
  margin: 36px 0px 36px;
`;

export const SignUpContentDivStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignUpContentBetweenDivStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SignUpContainerDivStyled = styled.div`
  max-width: 470px;
  width: 470px;
  display: block;
`;

export const SignUpMarginTopDivStyled = styled.div`
  margin-top: 5rem;
`;

export const SignUpMarginBottomDivStyled = styled.div`
  margin-bottom: 5rem;
`;

export const SignUpImgLogoStyled = styled.img`
  height: 35px;
  margin-left: 10px;
`;

export const SignUpLinkStyled = styled(Link)`
  font-size: 20px;
  font-weight: 800;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 160px;
`;

export const SignUpStyledIcon = styled(IoIosArrowBack)`
  height: 30px;
  width: 30px;
`;

export const SignUpStyledFaRegEyeIcon = styled(FaRegEye)`
  height: 25px;
  width: 25px;
  margin-left: 10px;
`;

export const SignUpStyledFaRegEyeSlashIcon = styled(FaRegEyeSlash)`
  height: 25px;
  width: 25px;
  margin-left: 10px;
`;

export const SignUpH2TitleStyled = styled.h2`
  font-size: 25px;
`;

export const SigUpH6Styled = styled.h6`
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 36px;
`;

export const SignUpPasswordDivStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const SignUpPasswordInputStyled = styled.input`
  text-align: left;
  width: 100%;
  max-width: 100%;
  appearance: none;
  text-align: center !important;
  border: 1px solid #d1d5db;
  color: #374151;
  background: #fff;
  vertical-align: middle;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  padding: 10px 16px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
`;

export const SignUpEyeDivStyled = styled.div`
  cursor: pointer;
`;

export const SignUpCallNumberStyled = styled.div`
  display: flex;
`;

export const SignUpOverlapButtonStyled = styled.button`
  width: 200px;
  height: 40px;
  margin-left: 10px;
`;

export const SignUpCheckNumberButtonStyled = styled.button`
  height: 40px;
  width: 200px;
  margin-left: 10px;
`;

export const SignUpCheckBoxStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const SignUpSuccessButtonStyled = styled.button`
  margin-top: 36px;
  width: 100%;
`;

export const SignUpDivClickStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #007bff; /* 텍스트 색상을 원하는 색상으로 변경하세요 */
  }
`;
