import React from "react";
import axios from "axios";
import { API_URL } from "./URL";
import { getCookie, removeCookie } from "../common/Cookies/user_cookies";
import { Cookies } from "react-cookie";
import { error } from "jquery";
import { reject } from "lodash";
import { useNavigate } from "react-router";
import { useNavigation } from "../util/NavigateionProvider";
import { useDispatch } from "react-redux";
import { resetStore } from "../common/redux/action/SidbarIndexAction";
import { store } from "../common/redux/Store";
import { authError } from "../common/redux/action/AuthErrorAction";

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  (config) => {
    config.headers["API-Key"] = "COFFEE1234!!!";

    if (config.headers.requiresAuth !== false) {
      const token = getCookie("accessToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    delete config.headers.requiresAuth;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error.response && [401, 403].includes(error.response.status)) {
      // const dispatch = useDispatch();
      // removeCookie("accessToken");
      // const navigate = useNavigation();
      // if (navigate) {
      //   dispatch(resetStore());
      //   navigate("/renewalLogin");
      // }

      store.dispatch(authError(error.response.status));
    }
    return Promise.reject(error);
  }
);

export default api;
