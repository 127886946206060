import styled from "styled-components";

export const CardMarginRLBox = styled.div`
  margin-left: 2%;
  margin-right: 2%;
`;

export const ConfirmationDivBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const ConfirmationDivBox2 = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-right: 20px;
`;

export const InquiryTextBox = styled.button`
  height: 2.5rem;
  width: 4rem;
`;

export const ConfirmationTableBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
