import styled from "styled-components";

export const LoginTextSizeStyled = styled.strong`
  font-size: 15px;
`;

export const LoginTitleImgStyled = styled.img`
  height: 35px;
  margin-left: 10px;
`;

export const LoginButtonStyled = styled.button`
  font-size: 18px;
  color: #1364ff;
  margin-top: 20px;
`;

export const LoginDivStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const LoginLogoDivStyled = styled.div`
  display: flex;
  width: 200px;
`;

export const LoginLogoImgStyled = styled.img`
  width: 70%;
  height: 55px;
`;

export const LoginKaKaoButtonStyled = styled.button`
  background-color: #f9e000;
`;

export const LoginKaKaoWingStyled = styled.img`
  height: 18px;
  margin-right: 10px;
`;

export const LoginButtonIntervalStyled = styled.div`
  margin-right: 10px;
  margin-left: 10px;
`;

export const LoginButtonBoxStyled = styled.div`
  display: flex;
`;
