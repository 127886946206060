import Swal from "sweetalert2";
import { setProfileData } from "../../../common/redux/action/ProfileAction";
import {
  setLastMonth,
  setToday,
} from "../../../common/redux/action/ToDayAction";
import { setButtonUrl } from "../../../common/redux/action/UrlButtonAction";
import {
  getCurrentDate,
  getLastMonthDate,
} from "../../../util/DateCalculation";
import formatPhoneNumber from "../../../util/FormatPhoneNumber";
import { getReUrlData } from "../../CreateButton/Renewal/repo/UrlData";
import { getProfileData } from "../../Modifying/Renewal/repo/Profile";
import emailLogin from "../../login/Renewal/repo/EmailLogin";
import {
  getEmailCheck,
  getSendAuthCode,
  getVerifyAuthCode,
} from "../Renewal/repo/EmailCheck";
import { postAuthSignup } from "../Renewal/repo/SingUpRepo";

export const handleEmailCheck = async (
  email,
  setOverlapUser,
  setNoEmail,
  setCheckEmail,
  setWhatError
) => {
  const resp = await getEmailCheck(email, true);

  if (resp.msg === "Success") {
    setOverlapUser(true);
    return;
  }

  if (resp.msg === "Fail") {
    if (resp.data.response.data.message === "잘못된 형식의 이메일") {
      setNoEmail(true);
      return;
    }

    if (
      resp.data.response.data.message === "이메일 주소와 일치하는 회원이 없음"
    ) {
      const sendCode = await getSendAuthCode(email, true);

      if (sendCode.msg === "Success") {
      }

      if (sendCode.msg === "Fail") {
        if (
          sendCode.data.response.data.message === "이메일 전송중에 문제생김"
        ) {
          setCheckEmail(false);

          setOverlapUser(false);

          setNoEmail(false);
          setWhatError(true);
          return;
        }
      }

      setCheckEmail(true);
      setOverlapUser(false);
      setNoEmail(false);

      return;
    }
  }
  return;
};

export const handleEmailCheckNumberRe = async (
  email,
  setCheckEmail,
  setOverlapUser,
  setNoEmail,
  setWhatError
) => {
  const sendCode = await getSendAuthCode(email, true);

  if (sendCode.msg === "Success") {
  }

  if (sendCode.msg === "Fail") {
    if (sendCode.data.response.data.message === "이메일 전송중에 문제생김") {
      setCheckEmail(false);

      setOverlapUser(false);

      setNoEmail(false);
      setWhatError(true);
      console.log("이메일 전송중에 문제가 생김");
      return;
    }
  }
};

export const handleCheckNumber = async (
  email,
  code,
  setInputCheckNumberBox,
  setEmailFilterText,
  setCheckNumber
) => {
  const resp = await getVerifyAuthCode(email, code);

  if (resp.msg === "Success") {
    setInputCheckNumberBox(true);
    setEmailFilterText(false);
    setCheckNumber(false);
  }

  if (resp.msg === "Fail") {
    if (resp.data.response.data.message === "잘못된 이메일 혹은 인증번호") {
      setEmailFilterText(false);

      setCheckNumber(true);
      console.log("인증번호 잘못됨");
      return;
    }

    return;
  }
};

export const handleSingUpButton = async (
  phoneNumber,
  setAllPass,
  setEmailFilterText,
  isInputCheckNumberBox,
  isCurrentName,
  isCurrentNickName,
  inputPassword,
  setPasswordValid,
  setNickNameValid,
  setIsNameValid,
  validatePassword,
  checkPassword,
  languageData,
  selectedCountry,
  setSelectedCountryMSG,
  isInputCheckPhoneNumber,
  setPhoneNumberValid,
  setPhoneNumberReCheck,
  enPhoneNumber,
  numericRegex,
  setEnPhonNumberState,
  termsChecked,
  privacyChecked,
  isCurrentEmail,
  dispatch,
  navigate,
  t
) => {
  let resp;

  /// 유효성 검사
  setAllPass(true);
  let pass = true;

  /// 1. 이메일 중복확인을 하지 않음
  if (isInputCheckNumberBox !== true) {
    setEmailFilterText(true);
    pass = false;
  }

  /// 2. 이름을 입력 안했을 경우
  if (isCurrentName.trim() === "") {
    setIsNameValid(false);
    pass = false;
  }

  /// 3. 닉네임을 입력안한경우
  if (isCurrentNickName.trim() === "") {
    setNickNameValid(false);
    pass = false;
  }

  if (/[^a-zA-Z0-9가-힣]/.test(isCurrentNickName)) {
    pass = false;
  }

  /// 4. 비밀번호를 입력하지 않은 경우
  if (inputPassword.trim() === "") {
    setPasswordValid(false);
    pass = false;
  }

  /// 5. 비밀번호가 조건에 맞지 않을 경우
  if (!validatePassword(inputPassword)) {
    pass = false;
  }

  /// 6. 비밀번호 확인이 빈값일경우
  if (checkPassword.trim() === "") {
    pass = false;
  }

  /// 7. 비밀번호와 확인이 값이 다를 경우
  if (inputPassword !== checkPassword) {
    pass = false;
  }

  /// 8. 비밀번호와 값이 같을 경우 텍스트 출력
  if (inputPassword === checkPassword) {
    // pass = false;
  }

  /// 국가정보가 들어오지 않았을 경우
  if (languageData !== "ko") {
    if (!selectedCountry.trim()) {
      setSelectedCountryMSG(true);
      pass = false;
    }
  } else {
    setSelectedCountryMSG(false);
  }

  /// 9. 휴대폰 번호가 빈값일 경우
  if (languageData === "ko") {
    if (isInputCheckPhoneNumber.trim() === "") {
      setPhoneNumberValid(false);
      pass = false;
      return;
    }

    if (!numericRegex.test(phoneNumber)) {
      setPhoneNumberReCheck(true);
      return;
    }

    if (phoneNumber.length !== 11) {
      setPhoneNumberReCheck(true);
      return;
    }

    if (!phoneNumber.startsWith("010")) {
      setPhoneNumberReCheck(true);
      return;
    }
  } else {
    const phoneNumber = enPhoneNumber.tel;
    const afterPlusAndNumber = phoneNumber.split(/\s+/)[1];
    if (!afterPlusAndNumber || afterPlusAndNumber.trim() === "") {
      setEnPhonNumberState(true);

      pass = false;
    } else {
      setEnPhonNumberState(false);
    }
  }

  /// 11. 이용약관이 동의가 되어있지 않은 경우
  if (termsChecked !== true || privacyChecked !== true) {
    pass = false;
  }

  /// 12. 검증로직 통과한게 있는지 확인
  if (!pass) {
    setAllPass(false);
    return;
  }

  /// 검증로직 통과 통신로직 실행
  if (languageData === "ko") {
    const phone = formatPhoneNumber(phoneNumber);

    resp = await postAuthSignup(
      isCurrentEmail,
      isCurrentNickName,
      isCurrentName,
      checkPassword,
      phone,
      "South Korea"
    );
  } else {
    resp = await postAuthSignup(
      isCurrentEmail,
      isCurrentNickName,
      isCurrentName,
      checkPassword,
      enPhoneNumber.tel,
      selectedCountry
    );
  }

  if (resp?.msg === "Success") {
    try {
      const currentDay = getCurrentDate();
      const lastDay = getLastMonthDate();
      const resp = await emailLogin(isCurrentEmail, checkPassword);

      if (resp === true) {
        const buttonURL = await getReUrlData();
        dispatch(setButtonUrl(buttonURL.data));
        dispatch(setLastMonth(lastDay.year, lastDay.month, lastDay.day));
        dispatch(setToday(currentDay.year, currentDay.month, currentDay.day));

        const profile = await getProfileData();

        if (profile === "Fail") {
          return;
        } else {
          dispatch(setProfileData(profile.data));
        }

        navigate("/ReMyPage");
      } else {
        if (resp.error.status_code === 500) {
          Swal.fire({
            title: `${t("signUpFailText")}`,
            icon: "error",
            confirmButtonText: `${t("checkText")}`,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        }
        return;
      }
    } catch (error) {
      console.log("로그인 오류", error);
      return;
    }
  }

  if (resp.msg === "Fail") {
    Swal.fire({
      title: `${t("signUpFailText")}`,
      icon: "error",
      confirmButtonText: `${t("checkText")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
      }
    });
    return;
  }
  return;
};
