import { setProfileData } from "../../../common/redux/action/ProfileAction";
import { getProfileData } from "../../Modifying/Renewal/repo/Profile";

export const handleProfileData = async (dispatch, navigate) => {
  const profile = await getProfileData();

  if (profile === "fail") {
    console.log("프로필 호출 에러", profile);
    return;
  } else {
    dispatch(setProfileData(profile.data));
    navigate("/ReMyPage");
  }
};
