import { SET_DASHBOARD_PARAMETERS } from "../action/DashBoardAction";
import { STORE_RESET } from "../action/SidbarIndexAction";

const initialState = {
  page: 1,
  search_range: null,
  search_type: null,
  search: null,
  start_date: null,
  end_date: null,
};

const dashBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_PARAMETERS:
      return {
        ...state,
        ...action.payload,
      };
    case STORE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default dashBoardReducer;
